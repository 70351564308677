import React from 'react'

const Referral = () => {
    return (
        <div>
            <h1>Referral</h1>
            <div className='table-header-wrapper'>
                <h5 className='text-white mb-0'>Referral</h5>
            </div>
        </div>
    )
}

export default Referral