import React, { useEffect, useState } from 'react'
import ResultOne from '../../../src/assets/result-img.png'
import AirCondition from '../../assets/ac-icon.svg'
import BanqueRimg from '../../assets/banquet-icon.svg'
import GardenHotel from '../../assets/garden-icon.svg'
import PoolHotel from '../../assets/pool-icon.svg'
import WifiHotel from '../../assets/wifi-icon.svg'
import GymHotel from '../../assets/gym-icon.svg'
import ParkingHotel from '../../assets/parking-icon.svg'
import BuffetHotel from '../../assets/buffet-icon.svg'
import BeachHotel from '../../assets/beach-icon.svg'
import BarsHotel from '../../assets/bar-icon.svg'
import BarberHotel from '../../assets/barber-icon.svg'
import AtmDetail from '../../assets/ATM-icon.svg'
import ReceptionsIMG from '../../assets/reception-icon.svg'
import CafeHotel from '../../assets/cafe-icon.svg'
import { connect, useDispatch } from 'react-redux'
import { get_search_hotels_lists } from '../../reduxStore/booking/bookingActions'
import SearchHotel from '../../shared/SearchHotel'
import { useNavigate } from 'react-router-dom'

const HotelsResult = ({ hotels }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [allHotels, setAllHotels] = useState([]);
    const searchData = JSON.parse(localStorage.getItem("searchedData"));

    useEffect(() => {
        if (searchData === null || searchData === undefined) {
            navigate("/");
        }
    }, []);

    // useEffect(() => {
    //     get_search_hotels_lists(dispatch);
    // }, []);

    const formatDate = () => {
        const date = new Date();

        // Get day, date, and month
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const day = days[date.getDay()];
        const dayOfMonth = date.getDate();
        const month = months[date.getMonth()];

        return `${day} ${dayOfMonth} ${month}`;
    };

    return (
        <div>
            <div className='srch-result-wrapper'>
                <div className='container'>
                    <div class="row align-items-start">
                        <div className='col-lg-3 col-md-12 col-sm-12'>
                            <div className='left-filter-wrapper'>
                                <div className=''>
                                    <div className='d-flex align-items-center justify-content-between bottom-border-filter'>
                                        <h3 className='mb-0'>Filter</h3>
                                        <p className='p-sm mb-0 text-purple font-weight-600'>Clear Filter</p>
                                    </div>
                                    <div className=''>
                                        <div class="accordion accordion-flush" id="accordionFlushExample">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="flush-headingOne">
                                                    <button class="accordion-button collapsed acoordian-mine-button p-0 align-items-center justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                        Rating
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                    <div class="accordion-body p-0">
                                                        <form className='filter-checkobx-form'>
                                                            <div class="form-check">
                                                                <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                                <label className="form-check-label lh-1" for="flexCheckDefault">
                                                                    <div class="reviews-box d-flex align-items-center mb-2 gap-2">
                                                                        <p className='d-inline-block p-md mb-0 lh-1'>5</p>
                                                                        <ul class="d-inline-block star-rating p-0 mb-0">
                                                                            <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                            <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                            <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                            <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                            <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                        </ul>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                                <label className="form-check-label lh-1" for="flexCheckDefault">
                                                                    <div class="reviews-box d-flex align-items-center mb-2 gap-2">
                                                                        <p className='d-inline-block p-md mb-0 lh-1'>4</p>
                                                                        <ul class="d-inline-block star-rating p-0 mb-0">
                                                                            <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                            <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                            <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                            <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                        </ul>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />

                                                                <label className="form-check-label lh-1" for="flexCheckDefault">
                                                                    <div class="reviews-box d-flex align-items-center mb-2 gap-2">
                                                                        <p className='d-inline-block p-md mb-0 lh-1'>3</p>
                                                                        <ul class="d-inline-block star-rating p-0 mb-0">
                                                                            <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                            <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                            <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                        </ul>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />

                                                                <label className="form-check-label lh-1" for="flexCheckDefault">
                                                                    <div class="reviews-box d-flex align-items-center mb-2 gap-2">
                                                                        <p className='d-inline-block p-md mb-0 lh-1'>2</p>
                                                                        <ul class="d-inline-block star-rating p-0 mb-0">
                                                                            <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                            <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                        </ul>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                                <label className="form-check-label lh-1" for="flexCheckDefault">
                                                                    <div class="reviews-box d-flex align-items-center mb-2 gap-2">
                                                                        <p className='d-inline-block p-md mb-0 lh-1'>1</p>
                                                                        <ul class="d-inline-block star-rating p-0 mb-0">
                                                                            <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                        </ul>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="flush-headingTwo">
                                                    <button class="accordion-button acoordian-mine-button p-0 collapsed align-items-center justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                        Hotel Amenity
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseTwo" class="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                    <div class="accordion-body p-0">
                                                        <div class="d-flex align-items-start">
                                                            <div class="nav flex-column nav-pills w-100" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                                                                <button class="nav-link btn btn-border-btn-filter text-start active d-flex align-items-center gap-2" id="v-pills-air-tab" data-bs-toggle="pill" data-bs-target="#v-pills-air" type="button" role="tab" aria-controls="v-pills-air" aria-selected="true">
                                                                    <img src={AirCondition} className='d-inline-block' />
                                                                    Air Condition
                                                                </button>

                                                                <button class="nav-link btn btn-border-btn-filter text-start d-flex align-items-center gap-2" id="v-pills-recep-tab" data-bs-toggle="pill" data-bs-target="#v-pills-recep" type="button" role="tab" aria-controls="v-pills-recep" aria-selected="false">
                                                                    <img src={ReceptionsIMG} className='d-inline-block' />
                                                                    24H. Reception
                                                                </button>

                                                                <button class="nav-link btn btn-border-btn-filter text-start d-flex align-items-center gap-2" id="v-pills-atm-tab" data-bs-toggle="pill" data-bs-target="#v-pills-atm" type="button" role="tab" aria-controls="v-pills-atm" aria-selected="false">
                                                                    <img src={AtmDetail} className='d-inline-block' />
                                                                    ATM
                                                                </button>

                                                                <button class="nav-link btn btn-border-btn-filter text-start d-flex align-items-center gap-2" id="v-pills-banquet-tab" data-bs-toggle="pill" data-bs-target="#v-pills-banquet" type="button" role="tab" aria-controls="v-pills-banquet" aria-selected="false">
                                                                    <img src={BanqueRimg} className='d-inline-block' />
                                                                    Banquet
                                                                </button>

                                                                <button class="nav-link btn btn-border-btn-filter text-start d-flex align-items-center gap-2" id="v-pills-salon-tab" data-bs-toggle="pill" data-bs-target="#v-pills-salon" type="button" role="tab" aria-controls="v-pills-salon" aria-selected="false">
                                                                    <img src={BarberHotel} className='d-inline-block' />
                                                                    Barber/Salon
                                                                </button>

                                                                <button class="nav-link btn btn-border-btn-filter text-start d-flex align-items-center gap-2" id="v-pills-bars-tab" data-bs-toggle="pill" data-bs-target="#v-pills-bars" type="button" role="tab" aria-controls="v-pills-bars" aria-selected="false">
                                                                    <img src={BarsHotel} className='d-inline-block' />
                                                                    Bars
                                                                </button>

                                                                <button class="nav-link btn btn-border-btn-filter text-start d-flex align-items-center gap-2" id="v-pills-beach-tab" data-bs-toggle="pill" data-bs-target="#v-pills-beach" type="button" role="tab" aria-controls="v-pills-beach" aria-selected="false">
                                                                    <img src={BeachHotel} className='d-inline-block' />
                                                                    Beach Nearby
                                                                </button>

                                                                <button class="nav-link btn btn-border-btn-filter text-start d-flex align-items-center gap-2" id="v-pills-buffet-tab" data-bs-toggle="pill" data-bs-target="#v-pills-buffet" type="button" role="tab" aria-controls="v-pills-buffet" aria-selected="false">
                                                                    <img src={BuffetHotel} className='d-inline-block' />
                                                                    Buffet
                                                                </button>

                                                                <button class="nav-link btn btn-border-btn-filter text-start d-flex align-items-center gap-2" id="v-pills-parking-tab" data-bs-toggle="pill" data-bs-target="#v-pills-parking" type="button" role="tab" aria-controls="v-pills-parking" aria-selected="false">
                                                                    <img src={ParkingHotel} className='d-inline-block' />
                                                                    Parking
                                                                </button>

                                                                <button class="nav-link btn btn-border-btn-filter text-start d-flex align-items-center gap-2" id="v-pills-cafe-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cafe" type="button" role="tab" aria-controls="v-pills-cafe" aria-selected="false">
                                                                    <img src={CafeHotel} className='d-inline-block' />
                                                                    Cafe
                                                                </button>

                                                                <button class="nav-link btn btn-border-btn-filter text-start d-flex align-items-center gap-2" id="v-pills-gym-tab" data-bs-toggle="pill" data-bs-target="#v-pills-gym" type="button" role="tab" aria-controls="v-pills-gym" aria-selected="false">
                                                                    <img src={GymHotel} className='d-inline-block' />
                                                                    Gym
                                                                </button>

                                                                <button class="nav-link btn btn-border-btn-filter text-start d-flex align-items-center gap-2" id="v-pills-wifi-tab" data-bs-toggle="pill" data-bs-target="#v-pills-wifi" type="button" role="tab" aria-controls="v-pills-wifi" aria-selected="false">
                                                                    <img src={WifiHotel} className='d-inline-block' />
                                                                    Free Wifi
                                                                </button>

                                                                <button class="nav-link btn btn-border-btn-filter text-start d-flex align-items-center gap-2" id="v-pills-garden-tab" data-bs-toggle="pill" data-bs-target="#v-pills-garden" type="button" role="tab" aria-controls="v-pills-garden" aria-selected="false">
                                                                    <img src={GardenHotel} className='d-inline-block' />
                                                                    Garden
                                                                </button>

                                                                <button class="nav-link btn btn-border-btn-filter text-start d-flex align-items-center gap-2" id="v-pills-pool-tab" data-bs-toggle="pill" data-bs-target="#v-pills-pool" type="button" role="tab" aria-controls="v-pills-pool" aria-selected="false">
                                                                    <img src={PoolHotel} className='d-inline-block' />
                                                                    Pool
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="flush-headingThree">
                                                    <button class="accordion-button collapsed acoordian-mine-button p-0 mb-1 justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                        Cancellation Policy
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseThree" class="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                    <div class="accordion-body p-0">
                                                        <form>
                                                            <div class="form-check">
                                                                <input class="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                                <label class="form-check-label p-md text-grey-light" for="flexCheckDefault">
                                                                    Free Cancellation
                                                                </label>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-12 col-sm-12">
                            <div class="tab-content" id="v-pills-tabContent">
                                <div class="tab-pane fade show active" id="v-pills-air" role="tabpanel" aria-labelledby="v-pills-air-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light spacing-result-txt mb-0'>
                                            Showing result for
                                            <span className='font-weight-600 text-color-theme'>
                                                {searchData && searchData?.CityName ? ` "${searchData?.CityName}" ` : '-'}
                                                {/* “New Delhi” */}
                                            </span>
                                            {/* to */}
                                            {/* <span className='font-weight-600 text-color-theme'>
                                                “Dubai”
                                            </span> */}
                                            {formatDate()}
                                        </h3>

                                        {hotels?.map((item, index) => (
                                            <SearchHotel item={item} index={index} />
                                        ))}

                                        {/* <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div> */}

                                        {/* <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div> */}

                                        {/* <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>


                                <div class="tab-pane fade" id="v-pills-recep" role="tabpanel" aria-labelledby="v-pills-recep-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-atm" role="tabpanel" aria-labelledby="v-pills-atm-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-banquet" role="tabpanel" aria-labelledby="v-pills-banquet-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-salon" role="tabpanel" aria-labelledby="v-pills-salon-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-bars" role="tabpanel" aria-labelledby="v-pills-bars-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-beach" role="tabpanel" aria-labelledby="v-pills-beach-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-buffet" role="tabpanel" aria-labelledby="v-pills-buffet-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-parking" role="tabpanel" aria-labelledby="v-pills-parking-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-cafe" role="tabpanel" aria-labelledby="v-pills-cafe-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-gym" role="tabpanel" aria-labelledby="v-pills-gym-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="tab-pane fade" id="v-pills-wifi" role="tabpanel" aria-labelledby="v-pills-wifi-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-garden" role="tabpanel" aria-labelledby="v-pills-garden-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-pool" role="tabpanel" aria-labelledby="v-pills-pool-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="v-pills-pool" role="tabpanel" aria-labelledby="v-pills-beach-tab">
                                    <div className='ryt-resultshow-wrapper'>
                                        <h3 className='text-grey-light mb-3'>Showing result for <span className='font-weight-600 text-color-theme'>“Saudi Arabia”</span></h3>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 d-grid grid-resultshow'>
                                            <div className='result-img-rounded'>
                                                <img src={ResultOne} alt='' />
                                            </div>

                                            <div className='srch-details-box'>
                                                <h3 className='mb-0'>Movenpick Hotel And Residences Riyadh</h3>
                                                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                                                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                                                    </ul>
                                                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                                                        <div className='room-feature'>
                                                            <ul className='p-0'>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                                                <li className='p-sm text-grey-light d-flex align-items-center gap-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                                    <p className='p-md'>1 Night(incl.VAT)</p>
                                                    <a href='' className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        hotels: state.bookings.searchedhotels
    }
};
export default connect(mapStateToProps)(HotelsResult);