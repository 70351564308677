import React from 'react'
import { Form } from 'react-bootstrap';

const Contact = ({ formdata, errors, handleChange, handleSubmit, handleUpload, fileRef }) => {
    return (
        <div className=''>
            <Form onSubmit={(e) => handleSubmit(e)} noValidate>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group mb-3'>
                            <label className='form-label'>Name </label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Enter Name'
                                value={formdata.name}
                                onChange={(e) => handleChange('name', e.target.value)}
                            />
                            {errors.name && <p className='text-danger'>{errors.name}</p>}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group mb-3'>
                            <label className='form-label'>Email </label>
                            <input
                                type='email'
                                className='form-control'
                                placeholder='Enter your email'
                                value={formdata.email}
                                onChange={(e) => handleChange('email', e.target.value)}
                            />
                            {errors.email && <p className='text-danger'>{errors.email}</p>}
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='form-group mb-3'>
                            <div className='d-flex gap-2 align-items-end'>
                                <div className='form-group w-75'>
                                    <label className='form-label'>Document/Image Upload</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='file/upload/image.jpg'
                                        value={formdata.attachment !== "" ? formdata?.attachment?.name : formdata?.attachment}
                                    />
                                    {/* {errors.name && <p className='text-danger'>{errors.name}</p>} */}
                                    <input
                                        type='file'
                                        className='form-control d-none'
                                        accept='.jpeg,.jpg,.png,.pdf'
                                        onChange={(e) => {
                                            const files = e.target.files[0];
                                            handleUpload(files);
                                            e.target.value = "";
                                        }}
                                        ref={fileRef}
                                    />
                                </div>
                                <button type='button' className='d-flex gap-2 disabled-btn w-auto' onClick={() => fileRef.current.click()}><i class="bi bi-upload"></i> Upload</button>
                            </div>
                            {errors.attachment && <p className='text-danger'>{errors.attachment}</p>}
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='form-group mb-3'>
                            <label className='form-label'>Description </label>
                            <textarea
                                minLength={12}
                                rows={6}
                                className='form-group form-control'
                                placeholder='Enter something...'
                                value={formdata.description}
                                onChange={(e) => handleChange('description', e.target.value)}
                            />
                            {errors.description && <p className='text-danger'>{errors.description}</p>}
                        </div>
                    </div>
                </div>
                    <div className='d-flex align-itmes-center justify-content-end'>
                        <button type='submit' className='btn-gradient-purple w-auto'>Submit</button>
                    </div>
            </Form >
        </div >
    )
}

export default Contact