import { createTheme, Pagination, ThemeProvider } from '@mui/material';
import React, { useState } from 'react'

const theme = createTheme();

const CustomPagination = ({total, onPageChange}) => {
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const pageCount = Math.ceil(total / rowsPerPage);

    const handlePageChange = (event, value) => {
        setPage(value);
        onPageChange(value, rowsPerPage);
    };

    return (
        <ThemeProvider theme={theme}>
            <div className='col-lg-12 d-flex align-items-center justify-content-center custom-pagination pt-3'>
                <Pagination
                    count={pageCount}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                    sx={{
                        "& .Mui-selected": {
                            backgroundColor: "#6D338A !important",
                            color: "#fff",
                        },
                    }}
                />
            </div>
        </ThemeProvider>
    )
}

export default CustomPagination