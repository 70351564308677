import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import { useLocation, useNavigate } from 'react-router-dom';
import SelectPopOver from '../../shared/SelectPopOver';
import { useToast } from '@chakra-ui/react';
import { connect, useDispatch } from 'react-redux';
import { get_search_hotel_graphs, get_search_hotel_results } from '../../reduxStore/booking/bookingActions';
import { GET_SEARCHED_DATA, GET_SEARCHED_GRAPH } from '../../reduxStore/booking/bookingTypes';
import SelectNation from '../../Modals/SelectNation';
import { start_loading, stop_loading } from '../../reduxStore/loader/loaderActions';

const HotelBar = ({ user, graphData, searchedData, modifyData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const toast = useToast();
    const [isShow, setIsShow] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [nation, setNation] = useState('');
    const [selectData, setSelectData] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [optionsData, setOptionsData] = useState({
        Room: 1,
        Adults: 1,
        Children: 0,
        // Pets: 1
    });

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleChange = async (value) => {
        setSearchVal(value);

        if (value.trim().length < 3) return;

        const data = {
            query: "query GetCities($name: String!) { getCities(name: $name) { CityName destinationCode { GDS code } } }",
            variables: {
                name: value
            }
        };
        await get_search_hotel_graphs(dispatch, data);
    };

    const handleSearch = async () => {
        if (searchVal === '') {
            toast({ title: 'Please enter a destination to start searching.', status: "error", duration: 2000, position: "top-right", isClosable: true, });
            return;
        }

        if (startDate === null || endDate === null) {
            toast({ title: 'Please Select Checkin - Checkout Date.', status: "error", duration: 2000, position: "top-right", isClosable: true, });
            return;
        }

        // if (nation === '') {
        //     setIsShow(true);
        //     return;
        // }

        if (modifyData !== null) {
            window.scrollTo({ top: 300, behavior: "smooth" });
            return;
        }

        const data = {
            ...selectData,
            CheckInDate: startDate?.toLocaleDateString('en-CA'),
            CheckOutDate: endDate?.toLocaleDateString('en-CA'),
            RoomCount: optionsData?.Room,
            AdultCount: optionsData?.Adults,
            ChildCount: optionsData?.Children,
            Nationality: 'ES'
        };
        console.log(data);
        localStorage.setItem("searchedData", JSON.stringify(data));

        let searched = await get_search_hotel_results(dispatch, data);
        if (searched) {
            navigate('/hotel-results');
        }
    };

    const handleSelect = (val) => {
        setSearchVal(val?.CityName);
        setSelectData(val);
        dispatch({ type: GET_SEARCHED_GRAPH, payload: [] });
    };

    // useEffect(() => {
    //     if (searchVal === '') {
    //         localStorage.removeItem("searchedData");
    //         localStorage.removeItem("BookingData");
    //         localStorage.removeItem("roomsData");
    //         localStorage.removeItem("holderDetail");
    //     }
    // }, [searchVal]);

    // useEffect(() => {

    //     if (searchedData !== null) return;

    //     const handleData = async () => {
    //         const data = {
    //             ...selectData,
    //             CheckInDate: new Date(startDate).toISOString().split('T')[0],
    //             CheckOutDate: new Date(endDate).toISOString().split('T')[0],
    //             RoomCount: optionsData?.Room,
    //             AdultCount: optionsData?.Adults,
    //             ChildCount: optionsData?.Children,
    //             Nationality: nation
    //         };
    //         // console.log(data);
    //         localStorage.setItem("searchedData", JSON.stringify(data));

    //         let searched = await get_search_hotel_results(dispatch, data);
    //         if (searched) {
    //             navigate('/hotel-results');
    //         }
    //     };
    //     handleData();
    // }, []);

    // console.log(searchedData);

    useEffect(() => {
        if (searchedData === null) return;

        // if (nation === '') return;

        console.log("searcheddddddd------------", searchedData);

        const autoSearch = async () => {
            dispatch(start_loading());
            try {

                const currentDate = new Date();
                currentDate.setDate(currentDate.getDate() + 5);
                const nextDate = new Date(currentDate);
                nextDate.setDate(currentDate.getDate() + 1);

                const data = {
                    destinationCode: [
                        { GDS: searchedData?.Gds, code: searchedData?.DestinationID }
                    ],
                    CheckInDate: currentDate.toLocaleDateString('en-CA'),
                    CheckOutDate: nextDate.toLocaleDateString('en-CA'),
                    RoomCount: optionsData?.Room,
                    AdultCount: optionsData?.Adults,
                    ChildCount: optionsData?.Children,
                    Nationality: 'ES'
                };
                console.log("Datatatatatataattttttttt", data);
                localStorage.setItem("searchedData", JSON.stringify(data));

                let searched = await get_search_hotel_results(dispatch, data);
                if (searched) {
                    navigate('/hotel-results');
                }
            } catch (error) {
                console.log("Errr", error);
            } finally {
                dispatch(stop_loading());
            }
        };
        autoSearch();

    }, [searchedData]);

    useEffect(() => {
        if (searchedData !== null) {
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + 5);
            const nextDate = new Date(currentDate);
            nextDate.setDate(currentDate.getDate() + 1);

            setSearchVal(searchedData?.DestinationName);
            const range = [currentDate.toLocaleDateString('en-CA'), nextDate.toLocaleDateString('en-CA')];
            setDateRange(range);
            setSelectData({
                CityName: searchedData?.DestinationName,
                destinationCode: [{ GDS: searchedData?.Gds, code: searchedData?.DestinationID }]
            });
            window.scrollTo({ top: 0, behavior: "smooth" });
            // setIsShow(true);
        }
    }, [searchedData]);

    useEffect(() => {
        if (location.pathname === '/') {
            setSearchVal('');
            setNation('');
            setSelectData(null);
            setDateRange([null, null]);
            setOptionsData({ Room: 1, Adults: 1, Children: 0 });
            dispatch({ type: GET_SEARCHED_GRAPH, payload: [] });
            dispatch({ type: GET_SEARCHED_DATA, payload: null });
            localStorage.removeItem("searchedData");
            localStorage.removeItem("BookingData");
            localStorage.removeItem("roomsData");
            localStorage.removeItem("holderDetail");
            localStorage.removeItem("selectedHotelGDS");
            localStorage.removeItem("hotelDetail");
        }
    }, [location.pathname]);

    useEffect(() => {
        if (modifyData === null) return;

        setSearchVal(modifyData?.CityName);
        const range = [new Date(modifyData?.CheckInDate).toLocaleDateString('en-CA'), new Date(modifyData?.CheckOutDate).toLocaleDateString('en-CA')];
        setDateRange(range);
    }, []);

    return (
        <div>
            <form>
                <div class="booking-form-grid">
                    <div className="destination-box first-box item-one">
                        <img src="./images/location-icon.svg" className='d-inline-block' alt='' />
                        <input
                            type="text"
                            className="form-control"
                            value={searchVal}
                            placeholder="Search Destination"
                            onChange={(e) => { handleChange(e.target.value); }}
                        />

                        {searchVal && graphData?.length > 0 && (
                            <ul
                                className="dropdown-options search-location-options"
                                style={{
                                    position: "absolute",
                                    top: "100%",
                                    left: 0,
                                    width: "100%",
                                    maxHeight: "250px",
                                    overflowY: "auto",
                                    backgroundColor: "#fff",
                                    border: "1px solid #ccc",
                                    listStyle: "none",
                                    margin: 0,
                                    padding: "5px 0",
                                    zIndex: 1,
                                    color: "white"
                                }}
                            >
                                {graphData?.map((option, index) => (
                                    <li
                                        key={index}
                                        onClick={() => handleSelect(option)}
                                        style={{
                                            padding: "10px",
                                            cursor: "pointer",
                                            backgroundColor: "#fff",
                                            color: "#333333",
                                        }}
                                        onMouseEnter={(e) => (e.target.style.backgroundColor = "#fff")}
                                        onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
                                    >
                                        <i class="bi bi-geo-alt-fill"></i>  {option?.CityName}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="destination-box second-box marrgin-left item-two">
                        {/* <input type="date" className="form-control"></input> */}
                        <img src="./images/calendar-event.svg" className='calendar-icon' alt='' />
                        <DatePicker
                            className='form-control'
                            showIcon
                            selected={startDate}
                            onChange={(update) => setDateRange(update)}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            minDate={new Date().setDate(new Date().getDate() + 5)}
                            placeholderText='mm/dd/yyyy - mm/dd/yyyy'
                            isClearable
                        />
                    </div>
                    <div className="destination-box  d-flex justify-content-center align-items-center first-box item-four">
                        <img src="./images/people-outline.svg" alt='' />
                        {/* <select class="form-select" aria-label="Default select example">
                                                    <option selected>Open this select menu</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select> */}
                        <input
                            type="text"
                            value={`${optionsData.Adults} Adult, ${optionsData.Children} Children, and ${optionsData.Room} Room`}
                            className="form-control"
                            placeholder="2 Adult, 1 Children"
                            onClick={handleClick}
                        />
                    </div>
                    {open && <SelectPopOver
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        setAnchorEl={() => setAnchorEl(null)}
                        optionsData={optionsData}
                        setOptionsData={setOptionsData}
                    />}
                    <div className="destination-box search-btn item-five">
                        <button type="button" className="btn btn-gradient-purple text-white d-flex align-items-center justify-content-center gap-2" onClick={handleSearch}><i class="fas fa-search me-2"></i>Search</button>
                    </div>
                </div>
            </form>
            <SelectNation show={isShow} handleClose={() => setIsShow(false)} setNation={setNation} />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        graphData: state.bookings.graphData,
        searchedData: state.bookings.searchedData,
        modifyData: state.bookings.modifyData
    }
};
export default connect(mapStateToProps)(HotelBar);