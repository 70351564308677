import React from 'react'
import { useNavigate } from 'react-router-dom';
import SelectRoom from '../../assets/ryt-selct-room.png';
import MapImg from '../../assets/map.png';
import UserImg from '../../assets/user.png';
import CalenderImg from '../../assets/calendar.png';
import UserSqImg from '../../assets/person-square .png'
import PeopleImg from '../../assets/people-sharp-icon.svg';
import GearImg from '../../assets/color-gear.svg';
import SuitcaseImg from '../../assets/suitcase.svg';

const ContactDetails = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div className='car-contact-details-wrapper py-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-7 col-sm-12'>
                            <div className='left-fill-details'>
                                <div className='py-4 travel-contact-detail'>
                                    <h2 className='mb-3'>Contact Details</h2>

                                    <div className='important-note-wrap'>
                                        <p>Important: Booking details will be sent to</p>
                                    </div>

                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='form-group form-spacing'>
                                                <label class="form-label p-md text-grey-light">First Name</label>
                                                <input type="text" className='form-control' placeholder='Enter your first name' />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group form-spacing'>
                                                <label class="form-label p-md text-grey-light">Last Name</label>
                                                <input type="text" className='form-control' placeholder='Enter your last name' />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group form-spacing'>
                                                <label class="form-label p-md text-grey-light">Email</label>
                                                <input type="email" className='form-control' placeholder='Enter your email' />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group form-spacing'>
                                                <label class="form-label p-md text-grey-light">Phone</label>
                                                <input type="tel" className='form-control' placeholder='Enter your phone' />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='car-pickup-details-wrap'>
                                    <h5>Pickup Details</h5>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='form-group form-spacing'>
                                                <label class="form-label p-md text-grey-light">Pickup Date</label>
                                                <input type="date" className='form-control' placeholder='Select' />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group form-spacing'>
                                                <label class="form-label p-md text-grey-light">Location</label>
                                                <input type="text" className='form-control' placeholder='Enter detail' />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='car-pickup-details-wrap'>
                                    <h5>Drop-off Details</h5>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='form-group form-spacing'>
                                                <label class="form-label p-md text-grey-light">Drop Date</label>
                                                <input type="date" className='form-control' placeholder='Select' />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group form-spacing'>
                                                <label class="form-label p-md text-grey-light">Drop Location</label>
                                                <input type="text" className='form-control' placeholder='Enter detail' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-5 col-sm-12'>
                            <div className='ryt-room-select-price ryt-tour-payment'>
                                <img src={SelectRoom} alt='' className='img-fluid w-100 top-selct-img' />
                                <h5 className='hd-spacing-room-selct'>White Mercedes</h5>
                                <span className='mb-2 d-inline-block text-grey-light'>Paris</span>
                                <div class="reviews-box d-flex align-items-baseline mb-2 gap-2">
                                    <p className='d-inline-block p-md mb-0'>4.3</p>
                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li><li class="d-inline-block"><i class="fas fa-star"></i></li>
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                    </ul>
                                    <p className='d-inline-block p-md mb-0'>(144 reviews)</p>
                                </div>

                                <div className='d-flex align-items-center gap-5 my-3'>
                                    <div className='d-inline-block'>
                                        <i class="bi bi-people-fill h4 mb-0 text-purple"></i>
                                        <p className='p-md text-center mb-0'>4</p>
                                    </div>
                                    <div className='text-center'>
                                        <img src={GearImg} className='d-inline-block' />
                                        <p className='p-md mb-0'>Automatic</p>
                                    </div>
                                    <div className='text-center'>
                                        <i class="bi bi-suitcase h3 mb-0 text-purple"></i>
                                        <p className='p-md mb-0'>3</p>
                                    </div>
                                </div>

                                <div className='price-box-room '>
                                    <h5 className='text-purple p-large mb-0'>Price</h5>
                                    <div className='price-line-dotted align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0'>Basic Cost</p>
                                            <p className='p-sm mb-0'>2 Adult</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'>₹1,11,500</p>
                                        </div>
                                    </div>

                                    <div className='price-line-dotted align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0'>Days</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'>5 Days</p>
                                        </div>
                                    </div>

                                    <div className='price-line-dotted align-items-center mb-3 border-0'>
                                        <div className=''>
                                            <p className='p-lg mb-0 font-weight-600 text-color-theme'>Total(incl.VAT)</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-large mb-0 font-weight-600 text-color-theme'>₹1,17,075</p>
                                        </div>
                                    </div>

                                    <button type="submit" className='btn-gradient-purple text-white' onClick={() => navigate('/tour/card')}>Book Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ContactDetails;