import React from 'react'

const CurrencyLogo = ({ currencyCode }) => {
    const currencyLogo = () => {
        switch (currencyCode) {
            case 'USD':
                return '$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            case 'AUD':
                return 'A$';
            case 'SAR':
                return '﷼';
            case 'BRL':
                return 'R$';
            case 'ARS':
                return '$';
            case 'CAD':
                return 'C$';
            case 'CHF':
                return 'CHF';
            case 'SEK':
                return 'kr';
            case 'DKK':
                return 'kr';
            case 'NOK':
                return 'kr';
            case 'PLN':
                return 'zł';
            case 'RON':
                return 'lei';
            case 'TRY':
                return '₺';
            case 'ZAR':
                return 'R';
            case 'EGP':
                return '£';
            case 'NGN':
                return '₦';
            case 'KES':
                return 'KSh';
            case 'AED':
                return 'د.إ';
            case 'ILS':
                return '₪';
            case 'INR':
                return '₹';
            case 'CNY':
                return '¥';
            case 'JPY':
                return '¥';
            case 'SGD':
                return 'S$';
            case 'HKD':
                return 'HK$';
            case 'KRW':
                return '₩';
            case 'THB':
                return '฿';
            case 'IDR':
                return 'Rp';
            case 'MYR':
                return 'RM';
            case 'NZD':
                return 'NZ$';
            case 'FJD':
                return '$';
            case 'CLP':
                return '$';
            case 'PKR':
                return '₨';
            case 'BHD':
                return 'د.ب';
            case 'QAR':
                return 'ر.ق';
            case 'KWD':
                return 'د.ك';
            case 'OMR':
                return 'ر.ع.';
            case 'TWD':
                return 'NT$';
            case 'VND':
                return '₫';
            case 'MXN':
                return 'MX$';
            case 'COP':
                return 'COL$';
            case 'GTQ':
                return 'Q';
            case 'BAM':
                return 'KM';
            case 'HRK':
                return 'kn';
            case 'LKR':
                return '₨';
            case 'MOP':
                return 'MOP';
            case 'KZT':
                return '₸';
            case 'UZS':
                return 'сум';
            case 'MDL':
                return 'lei';
            default:
                return '';
        }
    };
    return currencyLogo()
}

export default CurrencyLogo;