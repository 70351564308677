import React from 'react'
import ReceptionImg from '../assets/reception-icon.svg';
import AirconditionImg from '../assets/ac-icon.svg';
import BanquetImg from '../assets/banquet-icon.svg';
import ParkingbtmImg from '../assets/parking-icon.svg';
import AtmImg from '../assets/ATM-icon.svg';
import RoomOne from '../assets/room1.png';
import WifibtnImg from '../assets/wifi-icon.svg';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';



const roomslider = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 700,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const Products = ({ item }) => {
    return (
        <div className='realted_inner similar-products-wrapper'>
            {/* <Slider {...roomslider}> */}
            <img src={RoomOne} alt='' className='w-100' />
            {/* </Slider> */}
            <div className='inner-details-cnt'>
                <h5 className='mt-3 mb-2'>Regular Room {item} - Queen Bed</h5>
                <div class="reviews-box d-flex align-items-center mb-1">
                    <p className='d-inline-block mb-0 me-2 p-sm'>4.3</p>
                    <ul class="d-inline-block star-rating p-0 mb-0">
                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                        <li class="d-inline-block"><i class="fas fa-star"></i></li><li class="d-inline-block"><i class="fas fa-star"></i></li>
                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                    </ul>
                    <p className='d-inline-block mb-0 ms-2 p-sm'>(144 reviews)</p>
                </div>
                <div className='row'>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='room-feature'>
                            <ul className='p-0'>
                                <li className='p-md d-flex align-items-center gap-2'><img src={AirconditionImg} className='d-inline-block' alt='' />Air Condition </li>
                                <li className='p-md d-flex align-items-center gap-2'><img src={ReceptionImg} className='d-inline-block' alt='' />24H. Reception</li>
                                <li className='p-md d-flex align-items-center gap-2'><img src={AtmImg} className='d-inline-block' alt='' />ATM</li>
                                <li className='p-md d-flex align-items-center gap-2'><img src={BanquetImg} className='d-inline-block' alt='' />Banquet</li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='room-feature'>
                            <ul className='p-0'>
                                <li className='p-md d-flex align-items-center gap-2'><img src={ParkingbtmImg} className='d-inline-block' alt='' />Parking</li>
                                <li className='p-md d-flex align-items-center gap-2'><img src={WifibtnImg} className='d-inline-block' alt='' />Free Wifi</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='price-room d-flex align-items-center justify-content-between'>
                    <div className=''>
                        <p className='d-inline-block size-big me-3 tab-0-content-sp'><b>$35</b><strike>$41</strike></p>
                        <p className='d-inline-block p-md'>1 Night(incl.VAT)</p>
                    </div>
                    {/* <div className=''>
                        <p className='text-danger p-md'>Only 2 Left</p>
                    </div> */}
                </div>
                <Link to="/hotel-booking/payment" className='btn btn-gradient-purple'>Book Now</Link>
            </div>
        </div>
    )
}

export default Products