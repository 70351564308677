import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { connect, useDispatch } from 'react-redux';
import { get_vender_id, get_all_user_lists } from '../../reduxStore/User/userActions';
import { user_deleted_by_admin } from '../../reduxStore/vendors/vendorActions';
import CustomPagination from '../../shared/CustomPagination';
import countries from 'i18n-iso-countries';
import Cancel from '../../Modals/Cancel';
import CreateCustomer from '../../Modals/CreateCustomer';
import ViewDetails from '../../Modals/ViewDetails';
import { useToast } from '@chakra-ui/react';

const UsersList = ({ user, allusers, vendor_id, total_users }) => {
    const dispatch = useDispatch();
    const toast = useToast();
    const [isCancel, setIsCancel] = useState(false);
    const [isName, setIsName] = useState(false);
    const [isEmail, setIsEmail] = useState(false);
    const [data, setData] = useState(null);
    const [isAdd, setIsAdd] = useState(false);
    const [isView, setIsView] = useState(false);

    useEffect(() => {
        if (vendor_id) {
            get_all_user_lists(toast, user?.token, dispatch, vendor_id, '', 1, 10);
        }
    }, [vendor_id]);

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    const handleSearch = async (searchval) => {
        await get_all_user_lists(toast, user?.token, dispatch, vendor_id, searchval, 1, 10);
    };

    const handledelete = async () => {
        if (data === null) return;

        let isDelete = await user_deleted_by_admin(dispatch, vendor_id, user?.token, data?._id, "user");
        if (isDelete) {
            await get_all_user_lists(toast, user?.token, dispatch, vendor_id, '', 1, 10);
            setIsCancel(false);
        }
    };

    const getCountryName = (code) => {
        const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
        const countryName = regionNames.of(code);
        return countryName;
    };

    const handleSort = async (label) => {
        switch (label) {
            case 'name':
                const newIsName = !isName;
                setIsName(newIsName);
                await get_all_user_lists(toast, user?.token, dispatch, vendor_id, '', 1, 10, 'name', newIsName ? 'desc' : 'asce');
                break;
            case 'email':
                const newIsEmail = !isEmail;
                setIsEmail(newIsEmail);
                await get_all_user_lists(toast, user?.token, dispatch, vendor_id, '', 1, 10, 'email', newIsEmail ? 'desc' : 'asce');
                break;

            default:
                break;
        }
    };

    const formatDate = (date) => {
        const dated = new Date(date);

        const formattedDate = dated.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric"
        });
        return formattedDate;
    };

    return (
        <div>
            <div className='container-fluid mb-4 px-0'>
                <h1 className='text-color-theme mb-3 fw-bold'>Customers</h1>
                <div className='row align-items-start'>
                    <div className='col-md-8'>
                        <label className='form-label text-grey-light fw-medium'>Search</label>
                        <input
                            type='text'
                            className='form-control'
                            placeholder='Search by name/email/phone no.'
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </div>
                    {/* <div className='col-md-5'>
                        <button className='btn-gradientlg-purple w-auto' onClick={() => setIsAdd(true)}>
                            <i class="bi bi-plus"></i> Add Customer
                        </button>
                    </div> */}
                </div>
            </div>

            {/* =====table-heading-start===== */}
            <div className='table-header-wrapper'>
                <h5 className='text-white mb-0'>Customers Listing</h5>
                <button className='ylw-theme-button w-auto' onClick={() => setIsAdd(true)}>
                    <i class="bi bi-plus"></i> Add
                </button>
            </div>
            {/* =====End===== */}
            <div className='upcoming-list-table-wrapper'>
                <Table>
                    <thead>
                        <tr>
                            <th scope="col">Sr. No.</th>
                            <th scope="col">
                                Name
                                {isName ? <i class="bi bi-caret-up-fill cursor-pointer" onClick={() => { handleSort('name') }}></i> : <i class="bi bi-caret-down-fill cursor-pointer" onClick={() => { handleSort('name') }}></i>}
                            </th>
                            <th scope="col">
                                Email
                                {isEmail ? <i class="bi bi-caret-up-fill cursor-pointer" onClick={() => { handleSort('email') }}></i> : <i class="bi bi-caret-down-fill cursor-pointer" onClick={() => { handleSort('email') }}></i>}
                            </th>
                            <th scope="col">Address</th>
                            <th scope="col">Phone No.</th>
                            <th scope="col">Country</th>
                            <th scope="col">Status</th>
                            <th scope="col">Created At</th>
                            <th scope="col" className="sticky-action-td">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allusers?.map((item, index) => (
                            <tr key={index}>
                                <th>{index + 1}</th>
                                <td>{item?.name ? item?.name : 'N/A'}</td>
                                <td>{item?.email ? item?.email : 'N/A'}</td>
                                <td>{item?.address ? item?.address : 'N/A'}</td>
                                <td>{item?.country_code && `(${item?.country_code})`} {item?.phone_number ? item?.phone_number : 'N/A'}</td>
                                <td>{item?.country_code_name ? getCountryName(item?.country_code_name) : 'N/A'}</td>
                                <td>{item?.isDeleted ? 'In-Active' : 'Active'}</td>
                                <td>
                                    {item?.createdAt ? formatDate(item?.createdAt) : '-'}
                                </td>
                                <td className="text-danger sticky-action-td">
                                    <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                        <a href='#' className='eye-icon-wrap text-decoration-none' onClick={() => { setData(item); setIsView(true); }}>
                                            <i class="bi bi-eye h6 mb-0 cursor-pointer"></i>
                                        </a>
                                        <a href='#' className='download-icon-wrap text-decoration-none' onClick={() => { setData(item); setIsAdd(true); }}>
                                            <i class="bi bi-pencil-square cursor-pointer"></i>
                                        </a>
                                        <a href='#' className='cross-icon-wrap text-decoration-none' onClick={() => { setData(item); setIsCancel(true); }}>
                                            <i class="bi bi-x h4 mb-0 cursor-pointer"></i>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

            {total_users > 0 &&
                <CustomPagination
                    total={total_users}
                    onPageChange={(page, perpage) => {
                        get_all_user_lists(toast, user?.token, dispatch, vendor_id, '', page, perpage);
                    }}
                />}

            {/* ===cancel-popup-start==== */}
            {/* <div class="add-travel-modal modal fade" id="booking-cancel-popup" tabindex="-1" aria-labelledby="booking-cancel-popupLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header border-0 cross-icon p-0 align-items-center">
                            <div className='container-fluid travel-detail-header d-flex align-items-center'>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                        <div class="modal-body border-0 modal-slider-view p-0">
                            <div className='container-fluid'>
                                <h2>Are you sure want to cancel the booking?</h2>
                                <p className='p-md'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* ===End==== */}
            <Cancel
                show={isCancel}
                handleClose={() => { setData(null); setIsCancel(false); }}
                content1={`Are you sure want to ${data?.isDeleted ? 'Active' : 'In-Active'} this user?`}
                content2={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."}
                handleCancel={handledelete}
            />
            <CreateCustomer show={isAdd} handleClose={() => { setData(null); setIsAdd(false); }} vendor_id={vendor_id} user={user} data={data} />
            <ViewDetails show={isView} handleClose={() => { setData(null); setIsView(false); }} vendor_id={vendor_id} user={user} id={data?._id} type={"user"} />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        allusers: state.auth.allUsers,
        vendor_id: state.auth.vendor_id,
        total_users: state.auth.total_users,
    }
}
export default connect(mapStateToProps)(UsersList);