import axios from "axios";
import { GET_CITIES_LISTS, GET_HOTEL_DETAILS, GET_HOTELS_LISTS, GET_SEARCHED_GRAPH, GET_TOP_HOTELS } from "./bookingTypes";
import { start_loading, stop_loading } from "../loader/loaderActions";
import { catch_errors_handle } from "../User/userActions";


const { REACT_APP_OTA_URL, REACT_APP_OTA_HOTEL_URL, REACT_APP_OTA_DIDA_URL } = process.env;

export const get_top_hotels_lists = async (dispatch, toast) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_DIDA_URL}Hotel/hotelList`;
        const res = await axios.post(url);
        if (res.data && res.data.status) {
            dispatch({ type: GET_TOP_HOTELS, payload: res.data.data });
            dispatch({ type: GET_CITIES_LISTS, payload: res.data.cities });
        }
    } catch (error) {
        catch_errors_handle(error, dispatch, toast);
        // console.log("Error in Hotels Lists", error);
    } finally {
        dispatch(stop_loading());
    }
};

export const get_hotel_detail = async (dispatch, data) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}Hotel/hotel-details`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
            }
        };
        const res = await axios.post(url, JSON.stringify(data), HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_HOTEL_DETAILS, payload: res.data.result });
        }
    } catch (error) {
        console.log("Error in Hotels Detail", error);
    } finally {
        dispatch(stop_loading());
    }
};

export const get_search_hotel_graphs = async (dispatch, data) => {
    // dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}graphql`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
            }
        };
        const res = await axios.post(url, JSON.stringify(data), HEADERS);
        if (res?.data?.data) {
            // console.log("Graph Data ---->", res.data.data.getCities);
            dispatch({ type: GET_SEARCHED_GRAPH, payload: res?.data?.data?.getCities });
        }
    } catch (error) {
        console.log("Error in Hotels Detail", error);
    } finally {
        // dispatch(stop_loading());
    }
};

export const get_hotel_data_formatter = async (data) => {
    // console.log("Previous data ----->", data);
    return data.map((hotel) => {
        const commonFields = {
            HotelID: hotel.HotelID || null,
            Name: hotel.Name || null,
            HotelCategory: hotel.HotelCategory || null,
            Gds: hotel.Gds || null,
            Images: hotel.Images || null,
            facilities: hotel.facilities || [],
            HotelPrice: {
                Currency: hotel?.Gds === "Juniper" ? hotel.rooms.Prices?.Price?.$?.Currency : 'USD',
                Price: hotel?.Gds === "Juniper" ? Number(hotel.rooms.Prices?.Price?.TotalFixAmounts?.$?.Gross) : hotel?.price,
            },
        };

        if (hotel?.Gds === "Juniper" && hotel?.rooms && !Array.isArray(hotel?.rooms)) {
            // Format rooms when not in array format
            const roomData = hotel.rooms;
            const prices = roomData.Prices?.Price;

            return {
                ...commonFields,
                rooms: [
                    {
                        RatePlanCode: roomData.$?.RatePlanCode || null,
                        Status: roomData.$?.Status || null,
                        NonRefundable: roomData.$?.NonRefundable === "true",
                        Board: roomData.Board?._ || null,
                        BoardType: roomData.Board?.$?.Type || null,
                        Price: {
                            Type: prices?.$?.Type || null,
                            Currency: prices?.$?.Currency || null,
                            Gross: prices?.TotalFixAmounts?.$?.Gross || null,
                            Nett: prices?.TotalFixAmounts?.$?.Nett || null,
                        },
                        ServiceAmount: prices?.TotalFixAmounts?.Service?.$?.Amount || null,
                        HotelRoom: {
                            Units: roomData.HotelRooms?.HotelRoom?.$?.Units || null,
                            Source: roomData.HotelRooms?.HotelRoom?.$?.Source || null,
                            AvailRooms: roomData.HotelRooms?.HotelRoom?.$?.AvailRooms || null,
                            Name: roomData.HotelRooms?.HotelRoom?.Name || null,
                            RoomCategoryType: roomData.HotelRooms?.HotelRoom?.RoomCategory?.$?.Type || null,
                            Occupancy: roomData.HotelRooms?.HotelRoom?.RoomOccupancy?.$?.Occupancy || null,
                            MaxOccupancy: roomData.HotelRooms?.HotelRoom?.RoomOccupancy?.$?.MaxOccupancy || null,
                            Adults: roomData.HotelRooms?.HotelRoom?.RoomOccupancy?.$?.Adults || null,
                            Children: roomData.HotelRooms?.HotelRoom?.RoomOccupancy?.$?.Children || null,
                        },
                    },
                ],
            };
        } else if (hotel?.Gds === "DIDA" && hotel.rooms && Array.isArray(hotel.rooms)) {
            // Format rooms when in array format
            return {
                ...commonFields,
                rooms: hotel.rooms.map((room) => ({
                    TotalPrice: room.TotalPrice || null,
                    RoomStatus: room.RoomStatus || null,
                    BreakfastType: room.BreakfastType || null,
                    BedType: room.BedType || null,
                    RoomOccupancy: {
                        ChildCount: room.RoomOccupancy?.ChildCount || null,
                        AdultCount: room.RoomOccupancy?.AdultCount || null,
                        RoomNum: room.RoomOccupancy?.RoomNum || null,
                    },
                    PriceList: room.PriceList?.map((price) => ({
                        StayDate: price.StayDate || null,
                        Price: price.Price || null,
                        MealAmount: price.MealAmount || null,
                        MealType: price.MealType || null,
                    })),
                    RatePlanCancellationPolicyList: room.RatePlanCancellationPolicyList?.map((policy) => ({
                        Amount: policy.Amount || null,
                        FromDate: policy.FromDate || null,
                    })),
                    StandardOccupancy: room.StandardOccupancy || null,
                    InventoryCount: room.InventoryCount || null,
                    MaxOccupancy: room.MaxOccupancy || null,
                    RoomTypeID: room.RoomTypeID || null,
                    Currency: room.Currency || null,
                    RatePlanName: room.RatePlanName || null,
                    RatePlanID: room.RatePlanID || null,
                    RoomName: room.RoomName || null,
                })),
            };
        } else {
            // Handle case with no room data
            return {
                ...commonFields,
                rooms: [],
            };
        }
    });
};

export const get_search_hotel_results = async (dispatch, hoteldata) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_DIDA_URL}Hotel/hotels`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
            }
        };
        const res = await axios.post(url, JSON.stringify(hoteldata), HEADERS);
        if (res?.data && res?.data?.status) {
            // console.log("formdated data ====>", await get_hotel_data_formatter(res.data.result));
            dispatch({ type: GET_HOTELS_LISTS, payload: await get_hotel_data_formatter(res.data.result) });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log("Error in Hotels Searchess", error);
    } finally {
        dispatch(stop_loading());
    }
};

export const hotel_room_price_confirm = async (dispatch, roomdata, toast) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_DIDA_URL}Hotel/hotel-room-price-confirm`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
            }
        };
        const res = await axios.post(url, JSON.stringify(roomdata), HEADERS);
        if (res?.data && res?.data?.status) {
            localStorage.setItem("BookingData", JSON.stringify(res?.data?.result));
            return res.data.status;
        }
    } catch (error) {
        toast({ title: error.response.data.error ? error.response.data.error : error.response.data.error.Message, status: "error", duration: 2000, position: "top-right", isClosable: true, });
       // console.log("Error in Hotels Room Booking", error);
    } finally {
        dispatch(stop_loading());
    }
};

export const hotel_booking_confirm = async (dispatch, toast, vendor_id, data) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_URL}booking/book-hotel/${vendor_id}`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        const res = await axios.post(url, JSON.stringify(data), HEADERS);
        if (res.data && res.data.status) {
            console.log("response of booking =========>", res.data);
            return true;
        }
    } catch (error) {
        toast({ title: error.response.data.error, status: "error", duration: 2000, position: "top-right", isClosable: true, });
        // catch_errors_handle(error,dispatch,toast);
    } finally {
        dispatch(stop_loading());
    }
};

