import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { get_user_details } from '../reduxStore/vendors/vendorActions';

const ViewDetails = ({ show, handleClose, vendor_id, user, id, type }) => {
    const dispatch = useDispatch();
    const [details, setDetails] = useState(null);
    useEffect(() => {
        if (id === null || id === undefined) return;
        const fetchData = async () => {
            let data = await get_user_details(dispatch, vendor_id, user?.token, id, type);
            //console.log("detailsss------->", data);
            setDetails(data);
        };
        fetchData();
    }, [show, id]);

    const getCountryName = (code) => {
        const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
        const countryName = regionNames.of(code);
        return countryName;
    };

    return (
        <Modal className='customer-details-popup' show={show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className='p-4'>
                <div className='position-relative'>
                    <div className='d-flex align-items-center justify-content-between mb-3'>
                        <h2 className='mb-0'>{type === "agency" ? 'Agency' : 'Customer'} Details</h2>
                        <button type="button" class="btn-close fs-6 mb-0" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                    </div>
                    <div className='row pt-3'>
                        <div className='col-md-5 mb-3'>
                            <label className='form-label customer-details-label'>
                                Name{" "}: <span className='fw-bold' >
                                    {details?.name || 'N/A'}
                                </span>
                            </label>
                        </div>
                        <div className='col-md-7 mb-3'>
                            <label className='form-label customer-details-label'>
                                Email{" "}: <span className='fw-bold' >
                                    {details?.email || 'N/A'}
                                </span>
                            </label>
                        </div>
                        <div className='col-md-5 mb-3'>
                            <label className='form-label customer-details-label'>
                                Country{" "}: <span className='fw-bold' >
                                    {details?.country_code_name ? getCountryName(details?.country_code_name) : 'N/A'}
                                </span>
                            </label>
                        </div>
                        <div className='col-md-7 mb-3'>
                            <label className='form-label customer-details-label'>
                                Address{" "}: <span className='fw-bold' >
                                    {details?.address || 'N/A'}
                                </span>
                            </label>
                        </div>
                        <div className='col-md-5 mb-3'>
                            <label className='form-label customer-details-label'>
                                Contact No{" "}: <span className='fw-bold' >
                                    {details && details?.country_code ? `(${details?.country_code})` : ''} {details && details?.phone_number ? details?.phone_number : 'N/A'}
                                </span>
                            </label>
                        </div>
                        <div className='col-md-7 mb-3'>
                            <label className='form-label customer-details-label'>
                                Status{" "}: <span className='fw-bold' >
                                    {details && details?.isDeleted ? 'In-Active' : details && !details?.isDeleted ? 'Active' : 'N/A'}
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button className='btn-gradient-purple w-auto' onClick={handleClose}>Close</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ViewDetails