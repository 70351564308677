import React from 'react'
import HotelsImg from '../assets/hotel1.jpg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GET_SEARCHED_DATA } from '../reduxStore/booking/bookingTypes';

const LocationCard = ({ item, isHotel }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <div className="card card-hotel-wrapper border-0 position-relative"
            onClick={() => {
                dispatch({ type: GET_SEARCHED_DATA, payload: item });
                // navigate(`/detail/${item?.HotelID}`)
            }}
        >
            <img src={!item?.Images ? HotelsImg : item?.Images !== null ? (item?.Images?.FileName || item?.Images) : HotelsImg} class="card-img-hotel" alt="..." />
            {isHotel ?
                <div className="card-body card-content position-absolute bg-white text-center">
                    <h6 className='p-md mb-0'> {item?.HotelName}.</h6>
                    <div className='reviews-box d-flex justify-content-between align-items-center'>
                        <p className='rating-txt mb-0'>(4.3)</p>
                        <ul className='star-rating p-0 mb-0'>
                            <li className='d-inline-block'><i class="fas fa-star"></i></li>
                            <li className='d-inline-block'><i class="fas fa-star"></i></li>
                            <li className='d-inline-block'><i class="fas fa-star"></i></li>
                            <li className='d-inline-block'><i class="fas fa-star"></i></li>
                            <li className='d-inline-block'><i class="fas fa-star"></i></li>
                        </ul>
                        <p className='rating-txt mb-0'>144 reviews</p>
                    </div>
                </div>
                :
                <h6 className="card-text position-absolute text-center city-txt-wrap p-md font-weight-600">London</h6>
            }
        </div>
    )
}

export default LocationCard