import React, { useEffect, useState } from 'react'
import HotelImg from '../assets/result-img.png'
import AirCondition from '../assets/ac-icon.svg'
import BanqueRimg from '../assets/banquet-icon.svg'
import GardenHotel from '../assets/garden-icon.svg'
import PoolHotel from '../assets/pool-icon.svg'
import WifiHotel from '../assets/wifi-icon.svg'
import GymHotel from '../assets/gym-icon.svg'
import ParkingHotel from '../assets/parking-icon.svg'
import BuffetHotel from '../assets/buffet-icon.svg'
import BeachHotel from '../assets/beach-icon.svg'
import BarsHotel from '../assets/bar-icon.svg'
import BarberHotel from '../assets/barber-icon.svg'
import AtmDetail from '../assets/ATM-icon.svg'
import ReceptionsIMG from '../assets/reception-icon.svg'
import CafeHotel from '../assets/cafe-icon.svg'
import { connect } from 'react-redux'
import { currency_converter } from '../reduxStore/User/userActions'
import { Link, useNavigate } from 'react-router-dom'

const SearchHotel = ({ item, index, currency }) => {
    const [convertedPrice, setConvertedPrice] = useState(Number(item?.HotelPrice?.Price));
    const navigate = useNavigate();
    useEffect(() => {
        const fetchConvertedPrice = async () => {
            if (currency !== 'USD') {
                const data = {
                    amount: Number(item?.HotelPrice?.Price),
                    from: item?.HotelPrice?.Currency ? item?.HotelPrice?.Currency : 'USD',
                    to: currency
                };
                const conversion = await currency_converter(data);
                setConvertedPrice(Math.round(conversion?.data));
            } else {
                setConvertedPrice(Math.round(item?.HotelPrice?.Price));
            }
        };

        fetchConvertedPrice();
    }, [currency]);

    const currencyLogo = (currencyCode) => {
        switch (currencyCode) {
            case 'USD':
                return '$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            case 'AUD':
                return 'A$';
            case 'SAR':
                return '﷼';
            case 'BRL':
                return 'R$';
            case 'ARS':
                return '$';
            case 'CAD':
                return 'C$';
            case 'CHF':
                return 'CHF';
            case 'SEK':
                return 'kr';
            case 'DKK':
                return 'kr';
            case 'NOK':
                return 'kr';
            case 'PLN':
                return 'zł';
            case 'RON':
                return 'lei';
            case 'TRY':
                return '₺';
            case 'ZAR':
                return 'R';
            case 'EGP':
                return '£';
            case 'NGN':
                return '₦';
            case 'KES':
                return 'KSh';
            case 'AED':
                return 'د.إ';
            case 'ILS':
                return '₪';
            case 'INR':
                return '₹';
            case 'CNY':
                return '¥';
            case 'JPY':
                return '¥';
            case 'SGD':
                return 'S$';
            case 'HKD':
                return 'HK$';
            case 'KRW':
                return '₩';
            case 'THB':
                return '฿';
            case 'IDR':
                return 'Rp';
            case 'MYR':
                return 'RM';
            case 'NZD':
                return 'NZ$';
            case 'FJD':
                return '$';
            case 'CLP':
                return '$';
            case 'PKR':
                return '₨';
            case 'BHD':
                return 'د.ب';
            case 'QAR':
                return 'ر.ق';
            case 'KWD':
                return 'د.ك';
            case 'OMR':
                return 'ر.ع.';
            case 'TWD':
                return 'NT$';
            case 'VND':
                return '₫';
            case 'MXN':
                return 'MX$';
            case 'COP':
                return 'COL$';
            case 'GTQ':
                return 'Q';
            case 'BAM':
                return 'KM';
            case 'HRK':
                return 'kn';
            case 'LKR':
                return '₨';
            case 'MOP':
                return 'MOP';
            case 'KZT':
                return '₸';
            case 'UZS':
                return 'сум';
            case 'MDL':
                return 'lei';
            default:
                return '';
        }
    };

    const handleBooking = async (itemData) => {
        localStorage.setItem("selectedHotelGDS", JSON.stringify(itemData?.Gds));
        localStorage.setItem("roomsData", JSON.stringify(itemData?.rooms));
        navigate(`/detail/${itemData?.HotelID}`);
    };

    return (
        <div className='w-100 d-grid grid-resultshow' key={index}>
            <div className='result-img-rounded'>
                <img src={!item?.Images ? HotelImg : item?.Images !== null ? item?.Images : HotelImg} alt='' />
            </div>

            <div className='srch-details-box'>
                <h3 className='mb-0'>{item?.Name}</h3>
                <div class="reviews-box d-flex align-items-baseline mb-1 gap-2">
                    <p className='d-inline-block text-grey-light  p-all-small mb-0'>4.3</p>
                    <ul class="d-inline-block star-rating p-0 mb-0">
                        <li class="d-inline-block"><i class="fas fa-star"></i></li>

                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                        <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>

                    </ul>
                    <p className='d-inline-block p-all-small text-grey-light mb-0'>(144 reviews)</p>
                </div>
                <div className='row'>
                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                        <div className='room-feature'>
                            <ul className='p-0'>
                                <li className='p-sm text-grey-light d-flex align-items-center gap-2 mb-2'><img src={AirCondition} className='d-inline-block' alt='' />Air Condition </li>
                                <li className='p-sm text-grey-light d-flex align-items-center gap-2 mb-2'><img src={ReceptionsIMG} className='d-inline-block' alt='' />24H. Reception</li>
                                <li className='p-sm text-grey-light d-flex align-items-center gap-2 mb-2'><img src={AtmDetail} className='d-inline-block' alt='' />ATM</li>
                                <li className='p-sm text-grey-light d-flex align-items-center gap-2 mb-2'><img src={BanqueRimg} className='d-inline-block' alt='' />Gym</li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12 width-mobile-adjust'>
                        <div className='room-feature'>
                            <ul className='p-0'>
                                <li className='p-sm text-grey-light d-flex align-items-center gap-2 mb-2'><img src={ParkingHotel} className='d-inline-block' alt='' />Parking</li>
                                <li className='p-sm text-grey-light d-flex align-items-center gap-2 mb-2'><img src={WifiHotel} className='d-inline-block' alt='' />Free Wifi</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='srch-price-box'>
                <div className='inner-srch-spacing'>
                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>{currencyLogo(currency)}{convertedPrice}</span><strike>$66</strike></p>
                    <p className='p-md'>1 Night(incl.VAT)</p>
                    <span className='btn btn-gradient-purple text-white d-inline-block h-auto w-100' onClick={() => handleBooking(item)}>Book Now</span>
                    {/* <Link to={`/detail/${item?.HotelID}`} className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</Link> */}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        currency: state.auth.currency
    }
};

export default connect(mapStateToProps)(SearchHotel);