import React, { useEffect, useState } from 'react'
import HotelOne from '../../assets/hotel-left.png';
import HotelTwo from '../../assets/hotel-two.png';
import HotelThree from '../../assets/hotel-three.png';
import HotelFour from '../../assets/hotel-four.png';
import HotelFive from '../../assets/hotel-five.png';
import HotelSix from '../../assets/hotel-six.png';
import HotelsSeven from '../../assets/hotel-seven.png';
import HotelEight from '../../assets/hotel-eight.png';
import MapImg from '../../assets/map.svg';
import GreenCheck from '../../assets/green-check.png';
import RoomOne from '../../assets/room1.png';
import WiFi from '../../assets/wifi.png';
import RecepTion from '../../assets/reception.png';
import SpaImg from '../../assets/spa.png';
import ClubImg from '../../assets/club.png';
import AirImg from '../../assets/air-condition.png';
import SmokeImg from '../../assets/smoke.png';
import GymImg from '../../assets/gym.png';
import FoodImg from '../../assets/food.png';
import AtmImg from '../../assets/atmb.png';
import ParkImg from '../../assets/parking.png';
import OutdoorImg from '../../assets/pool.png';
import GardImg from '../../assets/garden.png';
import BreakfastImgg from '../../assets/breakfast.png';
import CafeImg from '../../assets/cafe.png';
import LaundryImg from '../../assets/laundry.png';
import LiftsImg from '../../assets/lifts.png';
import WifibtnImg from '../../assets/wifibtm.png';
import AtmbtmImg from '../../assets/atm_btm.png';
import ReceptionImg from '../../assets/24recep.png';
import AirconditionImg from '../../assets/air-condition-btm.png';
import BanquetImg from '../../assets/banquet.png';
import ParkingbtmImg from '../../assets/parkingbt.png';
import SelectOne from '../../assets/Rectangle-54.png'
import RoomsCard from '../../shared/RoomsCard';
import carImg from '../../assets/car-sport-outline .png';
import CheckinImg from '../../assets/restaurant-outline.png';
import DinnerImg from '../../assets/bed-outline.png';
import ClockImg from '../../assets/clock.png';
import NotFound from '../../assets/NotFound.png';
import Slider from 'react-slick';
import Products from '../../shared/Products';
import { Box, useToast } from '@chakra-ui/react';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { get_hotel_detail, get_search_hotel_results, hotel_room_price_confirm } from '../../reduxStore/booking/bookingActions';
import SelectPopOver from '../../shared/SelectPopOver';
import { GET_MODIFIED_DATA, GET_SEARCHED_DATA, GET_SEARCHED_GRAPH } from '../../reduxStore/booking/bookingTypes';
import DatePicker from 'react-datepicker';
import Amenities from '../../shared/Amenities';
import CurrencyLogo from '../../shared/CurrencyLogo';
import { currency_converter } from '../../reduxStore/User/userActions';


const HotelDetail = ({ hotelDetail, currency }) => {
    const roomsData = [1, 2, 3, 4, 5, 6];
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();

    const roomsList = JSON.parse(localStorage.getItem("roomsData"));
    const searchData = JSON.parse(localStorage.getItem("searchedData"));
    const hotelGDS = JSON.parse(localStorage.getItem("selectedHotelGDS"));
    const [selectedRooms, setSelectedRooms] = useState([]);
    const [convertedPrices, setConvertedPrices] = useState({});

    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },

            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const productssettings = {
        dots: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const popupssettings = {
        dots: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const MaxOccupancy = (hotelGDS === "Juniper" && selectedRooms?.length > 0) ? Number(selectedRooms[0]?.HotelRoom?.MaxOccupancy) : 5;
    // console.log(MaxOccupancy);

    const [roomCount, setRoomCount] = useState(1);

    useEffect(() => {
        const handleData = async () => {
            await get_hotel_detail(dispatch, { Gds: hotelGDS, HotelID: id });
        };
        handleData();
    }, [id]);

    const [searchDates, setSearchDates] = useState({
        startDate: '',
        endDate: ''
    });

    const [optionsData, setOptionsData] = useState({
        Room: 1,
        Adults: 1,
        Children: 0,
        // Pets: 1
    });
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const popid = open ? 'simple-popover' : undefined;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        dispatch({ type: GET_SEARCHED_DATA, payload: null });
    }, []);

    const fetchConvertedPrice = async (curr, price) => {
        const data = {
            amount: price,
            from: curr,
            to: currency
        };
        const conversion = await currency_converter(data);
        return Math.round(conversion?.data);
    };

    const fetchPrice = async (currency, totalPrice, roomId) => {
        const price = await fetchConvertedPrice(currency, totalPrice);
        setConvertedPrices((prev) => ({ ...prev, [roomId]: price }));
    };

    useEffect(() => {
        selectedRooms?.forEach((item) => {

            if (hotelGDS === "Juniper") {
                if (item?.Price?.Currency && item?.Price?.Gross) {
                    fetchPrice(item?.Price?.Currency, item?.Price?.Gross, item?.RatePlanCode);
                }
            }

            if (hotelGDS !== "Juniper") {
                if (item?.Currency && item?.TotalPrice) {
                    fetchPrice(item?.Currency, item?.TotalPrice, item?.RatePlanID);
                }
            }
        });
    }, [selectedRooms]);

    const occupancyData = (maxcount, childcount, adultcount) => {
        let data = [];
        for (let i = 1; i <= maxcount; i++) {
            data.push({
                ChildCount: childcount !== null ? childcount : 0,
                AdultCount: adultcount !== null ? adultcount : 0,
                RoomNum: i
            });
        }
        return data;
    };

    const handleRoom = async () => {
        const data = {
            RatePlanID: hotelGDS === "Juniper" ? selectedRooms[0]?.RatePlanCode : selectedRooms[0]?.RatePlanID,
            Gds: hotelGDS ? hotelGDS : 'DIDA',
            HotelID: id,
            CheckInDate: searchData?.CheckInDate,
            CheckOutDate: searchData?.CheckOutDate,
            RoomCount: roomCount,
            Nationality: searchData?.Nationality,
            Currency: hotelGDS === "Juniper" ? selectedRooms[0]?.Price?.Currency : selectedRooms[0]?.Currency
        };

        if (hotelGDS === "JUNIPER") {
            data.AdultCount = searchData?.AdultCount;
            data.ChildCount = searchData?.ChildCount;
        }

        if (hotelGDS === 'DIDA') {
            data.OccupancyDetails = occupancyData(roomCount, selectedRooms[0]?.RoomOccupancy?.ChildCount, selectedRooms[0]?.RoomOccupancy?.AdultCount);
        }

       // console.log("room data confirm ----->", data);
        let confirmdata = await hotel_room_price_confirm(dispatch, data, toast);
        if (confirmdata) {
            localStorage.setItem("hotelDetail", JSON.stringify(hotelDetail));
            localStorage.setItem("totalrooms", JSON.stringify(roomCount));
            navigate('/hotel-booking/payment');
        }
    };

    const handleSearch = async (e) => {
        e.preventDefault();

        if (startDate === null || endDate === null) {
            toast({ title: 'Please Select Checkin - Checkout Date.', status: "error", duration: 2000, position: "top-right", isClosable: true, });
            return;
        }

        const data = {
            CityName: searchData?.CityName,
            CheckInDate: startDate.toLocaleDateString('en-CA'),
            CheckOutDate: endDate.toLocaleDateString('en-CA'),
            RoomCount: optionsData?.Room,
            AdultCount: optionsData?.Adults,
            ChildCount: optionsData?.Children,
            Nationality: 'ES',
            destinationCode: searchData?.destinationCode
        };
        //console.log(data, selectData);
        dispatch({ type: GET_MODIFIED_DATA, payload: data });
        localStorage.setItem("searchedData", JSON.stringify(data));

        let searched = await get_search_hotel_results(dispatch, data);
        if (searched) {
            navigate('/hotel-results');
        }
    };

    //console.log(selectedRooms);

    return (
        <div className=''>
            <div className='hotel-details-banner'>
                <div className='container'>
                    <div className='top-home-detail-wrapper tab-description'>
                        <h2>
                            {hotelDetail && hotelDetail[0]?.Name ? hotelDetail[0]?.Name : 'Movenpick Hotel And Residences Riyadh'}
                            {/* Movenpick Hotel And Residences Riyadh */}
                        </h2>

                        <div class="reviews-box d-flex align-items-end gap-2 mb-1">
                            <p className='d-inline-block font-size-15 mb-0 font-weight-500'>
                                {hotelDetail && hotelDetail[0]?.StarRating ? hotelDetail[0]?.StarRating : '4.3'}
                                {/* 4.3 */}
                            </p>
                            <ul class="d-inline-block star-rating p-0 mb-0">
                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                            </ul>
                            <p className='d-inline-block font-size-15 mb-0 font-weight-500'>(144 reviews)</p>
                        </div>
                        <p className='font-size-15 font-weight-500 mb-0 d-flex align-items-center gap-2'><img src={MapImg} className='d-inline-block' alt='' />Diplomatic Enclave Chanakyapuri</p>
                    </div>
                </div>

                <div className='container hotel-details-banner-inner-bg' id="photos-tab">
                    <div className='row w-100 mx-auto'>
                        <div className='col-lg-7 col-md-7 col-sm-12 p-0'>
                            <div className='left-hotel-banner spacing-btm detail-first-img-wrap'>
                                <img src={hotelDetail && hotelDetail[0]?.images[2]?.ImageUrl ? hotelDetail[0]?.images[2]?.ImageUrl : HotelOne} alt='' className='img-fluid w-100' />
                            </div>
                            <div className='row bottom_banner_img w-100 mx-auto'>
                                <div className='col-lg-4 col-md-4 col-sm-12 p-0'>
                                    <div className='detail-left-bottom-img-wrap'>
                                        <img src={hotelDetail && hotelDetail[0]?.images[0]?.ImageUrl ? hotelDetail[0]?.images[0]?.ImageUrl : HotelSix} className='img-fluid w-100' alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 p-0'>
                                    <div className='spacing-ryt detail-left-bottom-img-wrap'>
                                        <img src={hotelDetail && hotelDetail[0]?.images[1]?.ImageUrl ? hotelDetail[0]?.images[1]?.ImageUrl : HotelsSeven} className='img-fluid w-100' alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 p-0'>
                                    <div className='spacing-ryt-lg detail-left-bottom-img-wrap'>
                                        <img src={hotelDetail && hotelDetail[0]?.images[3]?.ImageUrl ? hotelDetail[0]?.images[3]?.ImageUrl : HotelEight} className='img-fluid w-100' alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-5 col-sm-12 ryt-col-spacing-tab p-0'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                                    <div className='detail-right-side-col-wrap'>
                                        <div className='spacing-equal'>
                                            <img src={hotelDetail && hotelDetail[0]?.images[4]?.ImageUrl ? hotelDetail[0]?.images[4]?.ImageUrl : HotelTwo} className='img-fluid w-100' alt='' />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                                    <div className='detail-right-side-col-wrap'>
                                        <div className='spacing-equal'>
                                            <img src={hotelDetail && hotelDetail[0]?.images[5]?.ImageUrl ? hotelDetail[0]?.images[5]?.ImageUrl : HotelThree} className='img-fluid w-100' alt='' />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                                    <div className='detail-right-side-col-wrap second-last-img-wrap'>
                                        <div className='spacing-equal'>
                                            <img src={hotelDetail && hotelDetail[0]?.images[6]?.ImageUrl ? hotelDetail[0]?.images[6]?.ImageUrl : HotelFour} className='img-fluid w-100' alt='' />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                                    <div className='detail-right-side-col-wrap last-img-wrap'>
                                        <div className='spacing-equal blur-img'>
                                            <div className='slider-image-popup'>
                                                <img src={hotelDetail && hotelDetail[0]?.images[7]?.ImageUrl ? hotelDetail[0]?.images[7]?.ImageUrl : HotelFive} className='img-fluid w-100' alt='' />
                                                <div className='modal-click-btn'>
                                                    <button type="button" class="bbtn btn-gradient-purple text-white " data-bs-toggle="modal" data-bs-target="#slidermodal">
                                                        View all
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mobile-hotel-details-banner'>
                    <div className='container'>
                        <Slider {...settings}>
                            {hotelDetail && hotelDetail[0]?.images?.length > 0 ? hotelDetail[0]?.images?.map((item, index) => (
                                <div className='col-12' key={index}>
                                    <img src={item?.ImageUrl} className='img-fluid w-100' alt={`Hotel Image ${index + 1}`} />
                                </div>
                            )) : roomsData?.map((item, index) => (
                                <div className='col-12' key={index}>
                                    <img src={HotelOne} className='img-fluid w-100' alt='img not found' />
                                </div>
                            ))}
                            {/* {roomsData?.map((item, index) => (
                                <div className='col-12' key={index}>
                                    <img src={hotelDetail && hotelDetail[0]?.images[2]?.ImageUrl ? hotelDetail[0]?.images[2]?.ImageUrl : HotelOne} className='img-fluid w-100' />
                                </div>
                            ))} */}
                        </Slider>
                    </div>
                </div>

                <div className='hotel-details-wrapper'>
                    <div className='container'>
                        <ul className='p-0 border-bottom'>
                            <li className='d-inline-block'>
                                <a class="nav-link page-id-tabs active" href='#photos-tab'>Photos</a>
                            </li>
                            <li className='d-inline-block'>
                                <a class="nav-link page-id-tabs" href='#amenities-tab'>Amenities</a>
                            </li>
                            <li className='d-inline-block'>
                                <a class="nav-link page-id-tabs" href='#rooms-tab'>Rooms</a>
                            </li>
                            <li className='d-inline-block'>
                                <a class="nav-link page-id-tabs" href='#policies-tab'>Policies </a>
                            </li>
                            <li className='d-inline-block'>
                                <a class="nav-link page-id-tabs" href='#reviews-tab'>Reviews</a>
                            </li>
                            <li className='d-inline-block'>
                                <a class="nav-link page-id-tabs" href='#similarpr-tab'>Similar Product</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='hotel_bottm_detail'>
                    <div className='container'>
                        <p className='font-size-15 font-weight-500'>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p>
                        <p className='font-size-15 font-weight-500 mb-0'>
                            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                    </div>
                </div>

                <div className='amnenities-wrapper' id="amenities-tab">
                    <div className='container'>
                        <h2>Amenities</h2>
                        <ul className='p-0 list-box-amenities'>
                            {hotelDetail && hotelDetail[0]?.room_facilities?.map((item, index) => (
                                <li className='p-md d-inline-block' key={index}>
                                    <p className='d-flex align-items-center gap-2'>
                                        <img
                                            src={
                                                Amenities?.find((k) => k?.Description?.toLowerCase().includes(item?.Description?.toLowerCase()))?.ImagePath ?
                                                    Amenities?.find((k) => k?.Description?.toLowerCase().includes(item?.Description?.toLowerCase()))?.ImagePath :
                                                    NotFound
                                            }
                                            className='d-inline-block'
                                            alt=''
                                        />
                                        {item?.Description}
                                    </p>
                                </li>
                            ))}

                            {/* <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={WiFi} className='d-inline-block' alt='' />
                                    Free Wi-fi
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={RecepTion} className='d-inline-block' alt='' />
                                    24H. Reception
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={SpaImg} className='d-inline-block' alt='' />
                                    Spa
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={ClubImg} className='d-inline-block' alt='' />Club
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={AirImg} className='d-inline-block' alt='' />Air Condition
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={SmokeImg} className='d-inline-block' alt='' />Smoking Area
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={GymImg} className='d-inline-block' alt='' />Gym
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={FoodImg} className='d-inline-block' alt='' />Food & Drink
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={AtmImg} className='d-inline-block' alt='' />ATM
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={ParkImg} className='d-inline-block' alt='' />Parking</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={OutdoorImg} className='d-inline-block' alt='' />
                                    Outdoor Pool
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={GardImg} className='d-inline-block' alt='' />Garden
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={BreakfastImgg} className='d-inline-block' alt='' />Breakfast</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={CafeImg} className='d-inline-block' alt='' />Café</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={LaundryImg} className='d-inline-block' alt='' />Laundry </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={LiftsImg} className='d-inline-block' alt='' />Lifts</p>
                            </li> */}
                        </ul>
                    </div>
                </div>

                {location.pathname === `/tour-detail/${id}` && <div className='daily-details-wrapper'>
                    <div className='container'>
                        <h2 className='mb-4'>Daily Details</h2>
                        <div className='row'>
                            <div className='col-lg-7 col-md-12 col-sm-12'>
                                <div className='daily-update-box'>
                                    <h4><span className='text-purple'>Day 1</span> - Arrival in Kolkata</h4>
                                    <ul className='box-list-details-wrap'>
                                        <li className='text-grey-light p-large'><img src={carImg} className='d-inline-block me-2' />Pickup from Airport </li>

                                        <li className='text-grey-light p-large'><img src={CheckinImg} className='d-inline-block me-2' />Check-in to Hotel </li>

                                        <li className='text-grey-light p-large'><img src={DinnerImg} className='d-inline-block me-2' />Dinner </li>
                                    </ul>
                                </div>

                                <div className='daily-update-box'>
                                    <h4><span className='text-purple'>Day 2</span> - Visit Temples in Kolkata</h4>
                                    <ul className='box-list-details-wrap'>
                                        <li className='text-grey-light p-large'><img src={carImg} className='d-inline-block me-2' />Breakfast </li>

                                        <li className='text-grey-light p-large'><img src={CheckinImg} className='d-inline-block me-2' />Temple in Kolkata </li>

                                        <li className='text-grey-light p-large'><img src={DinnerImg} className='d-inline-block me-2' />2nd Temple in Kolkata </li>

                                        <li className='text-grey-light p-large'><img src={DinnerImg} className='d-inline-block me-2' />Dinner </li>
                                    </ul>
                                </div>

                                <div className='daily-update-box'>
                                    <h4><span className='text-purple'>Day 2</span> - Free</h4>
                                    <ul className='box-list-details-wrap'>
                                        <li className='text-grey-light p-large'><img src={carImg} className='d-inline-block me-2' />Breakfast  </li>

                                        <li className='text-grey-light p-large'><img src={CheckinImg} className='d-inline-block me-2' />Free </li>

                                        <li className='text-grey-light p-large'><img src={DinnerImg} className='d-inline-block me-2' />Candle light Dinner </li>
                                    </ul>
                                </div>

                                <div className='daily-update-box'>
                                    <h4><span className='text-purple'>Day 3</span> - Departure</h4>
                                    <ul className='box-list-details-wrap'>
                                        <li className='text-grey-light p-large'><img src={carImg} className='d-inline-block me-2' />Breakfast  </li>

                                        <li className='text-grey-light p-large'><img src={CheckinImg} className='d-inline-block me-2' />Drop to Airport  </li>
                                    </ul>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-12 col-sm-12'>
                                <div className='tour-details-timming'>
                                    <div className='timming-txt-wrap d-flex justify-content-between'>
                                        <div className=''>
                                            <h4 className='mb-1'>Timing</h4>
                                            <p className='p-md mb-0'><img src={ClockImg} className='d-inline-block me-1' />3 Days, 4 Nights</p>
                                        </div>
                                        <div className='orange-box-price'>
                                            <p className='mb-0 p-sm'><strike>59,999</strike></p>
                                            <p className='mb-0 p-large text-color-theme font-weight-600'>₹55,750 <small className='p-sm text-grey-light fst-normal'>/Adult</small></p>
                                        </div>
                                    </div>
                                    <div className='flight-features-txt'>
                                        <ul className='p-0 flight-features-list m-0'>
                                            <li className='d-inline-block text-grey-light p-md lh-1'>1 Flight</li>
                                            <li className='d-inline-block text-grey-light p-md lh-1'>1 Hotel</li>
                                            <li className='d-inline-block text-grey-light p-md lh-1'>1 Transfer</li>
                                        </ul>
                                    </div>

                                    <div className='border-divider'></div>

                                    <div className='ryt-purple-box'>
                                        <h4>Travellers</h4>
                                        <div class="quantity-increase input-group d-flex align-items-start">
                                            <input type="button" value="-" class="button-minus rounded-circle-quantity" data-field="quantity" />
                                            <input type="number" step="1" max="" value="1" name="quantity" class="quantity-field d-inline-block mx-0 text-center font-weight-500" />
                                            <input type="button" value="+" class="button-plus rounded-circle-quantity" data-field="quantity" />
                                        </div>
                                    </div>

                                    <div className='border-divider'></div>

                                    <div className='mb-3'>
                                        <h4>Total</h4>
                                        <h3 className='font-25-hd font-family-poppins'>₹1,11,500</h3>
                                    </div>

                                    <div className='text-center'>
                                        <p className='p-md mb-1'>*Adult - Should be above 18 years</p>
                                    </div>

                                    <a onClick={() => navigate('/tour-payment')} className='bbtn btn-gradient-purple text-white w-100 d-block text-center'>Book Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                {location.pathname === `/detail/${id}` && <div className='details-room-srch booking-tab-form'>
                    <div className='container'>
                        <div className='details_bg_lyt'>
                            <form className='w-100' onSubmit={(e) => handleSearch(e)}>
                                <div className='d-grid grid-wrapper-form'>
                                    <div className='destination-box first-box'>
                                        <img src="../images/calendar-event.svg" className='calendar-icon' alt='' />
                                        <DatePicker
                                            className='form-control'
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            minDate={new Date().setDate(new Date().getDate() + 5)}
                                            placeholderText='mm/dd/yyyy'
                                        />
                                    </div>
                                    <div className='destination-box first-box'>
                                        <img src="../images/calendar-event.svg" className='calendar-icon' alt='' />
                                        <DatePicker
                                            className='form-control'
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            minDate={new Date().setDate(new Date().getDate() + 6)}
                                            placeholderText='mm/dd/yyyy'
                                        />
                                    </div>
                                    <div className='destination-box first-box'>
                                        <img src="./images/people-outline.png" className='img-fluid' alt='' />
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={`${optionsData.Adults} Adult, ${optionsData.Children} Children, and ${optionsData.Room} Room`}
                                            placeholder="2 Adult, 1 Children"
                                            onClick={handleClick}
                                        />
                                    </div>
                                    {open && <SelectPopOver
                                        id={popid}
                                        open={open}
                                        anchorEl={anchorEl}
                                        setAnchorEl={() => setAnchorEl(null)}
                                        optionsData={optionsData}
                                        setOptionsData={setOptionsData}
                                    />}

                                    <div className='destination-box first-box button-last-box'>
                                        <button type="submit" className='btn btn-gradient-purple text-white d-flex align-items-center justify-content-center gap-2'><i class="fas fa-search me-2"></i> Modify Search</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>}

                {location.pathname === `/detail/${id}` && <div id='rooms-tab' className='related_rooms_wrapper top-related_rooms_wrapper'>
                    <div className='container'>
                        <h2 className='mb-2'>Rooms</h2>
                        <div className='row'>
                            <Slider {...settings}>
                                {(Array.isArray(roomsList) ? roomsList : roomsData)?.map((item, index) => (
                                    <div className='col-lg-4 col-md-4 col-sm-12' key={index}>
                                        <RoomsCard
                                            item={item}
                                            hotelDetail={hotelDetail}
                                            roomslength={selectedRooms?.length}
                                            setSelectedRooms={setSelectedRooms}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>}

                {location.pathname === `/detail/${id}` && <div className='Selected_rooms'>
                    <div className='container'>
                        <div className='d-flex align-items-center justify-content-between mb-3'>
                            <h2 className='mb-0'>Selected Rooms({selectedRooms?.length})</h2>
                            {selectedRooms?.length > 0 && <p className='mb-0 cursor-pointer' onClick={() => setSelectedRooms([])}>Remove all</p>}
                        </div>
                        {/* <form> */}
                        <div className='select-room_grid_row row'>
                            {selectedRooms?.length > 0 && selectedRooms?.map((item) => (<div className='col-lg-3 col-md-6 col-sm-12 select-col-spacing'>
                                <div className='select-room_inner d-flex align-items-center justify-content-between gap-2'>
                                    <div className=''>
                                        <img src={SelectOne} className='d-inline-block' />
                                    </div>
                                    <div className=''>
                                        <p className='mb-0 dark-txt'>
                                            {hotelGDS === "Juniper" ? item?.HotelRoom?.Name : item?.RoomName}
                                        </p>
                                        <div className='d-grid align-items-center price-quantity-btn'>
                                            <p className='mb-0 d-inline-block'>
                                                <span className='yellow-txt-link me-2 font-weight-600'>
                                                    {currency && <CurrencyLogo currencyCode={currency} />}
                                                    {hotelGDS === "Juniper" ? convertedPrices[item?.RatePlanCode] : convertedPrices[item?.RatePlanID]}
                                                </span>
                                                {/* x */}
                                            </p>
                                            <div class="quantity-increase input-group d-flex align-items-start">
                                                <input type="button" value="-" class="button-minus rounded-circle-quantity" data-field="quantity"
                                                    onClick={() => {
                                                        let value = Math.max(roomCount - 1, 1);
                                                        setRoomCount(value);
                                                    }}
                                                />
                                                <input type="number" step="1" max="" value={roomCount} name="quantity" class="quantity-field d-inline-block mx-0 text-center font-weight-500" />
                                                <input type="button" value="+" class="button-plus rounded-circle-quantity" data-field="quantity"
                                                    onClick={() => {
                                                        let value = Math.min(roomCount + 1, 5);
                                                        setRoomCount(value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='cursor-pointer' onClick={() => setSelectedRooms([])}>
                                        <i class="fas fa-times"></i>
                                    </div>
                                </div>
                            </div>))}

                            {/* <div className='col-lg-3 col-md-6 col-sm-12 select-col-spacing'>
                                <div className='select-room_inner d-flex align-items-center justify-content-between gap-2'>
                                    <div className=''>
                                        <img src={SelectOne} className='d-inline-block' />
                                    </div>
                                    <div className=''>
                                        <p className='mb-0 dark-txt'>Regular Room - Queen Bed</p>
                                        <div className='d-grid align-items-center price-quantity-btn'>
                                            <p className='mb-0 d-inline-block'><span className='yellow-txt-link me-2 font-weight-600'>$35</span> x</p>
                                            <div class="quantity-increase input-group d-flex align-items-start">
                                                <input type="button" value="-" class="button-minus rounded-circle-quantity" data-field="quantity" />
                                                <input type="number" step="1" max="" value="1" name="quantity" class="quantity-field d-inline-block mx-0 text-center font-weight-500" />
                                                <input type="button" value="+" class="button-plus rounded-circle-quantity" data-field="quantity" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <i class="fas fa-times"></i>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-12 select-col-spacing'>
                                <div className='select-room_inner d-flex align-items-center justify-content-between gap-2'>
                                    <div className=''>
                                        <img src={SelectOne} alt='' className='d-inline-block' />
                                    </div>
                                    <div className=''>
                                        <p className='mb-0 dark-txt'>Regular Room - Queen Bed</p>
                                        <div className='d-grid align-items-center price-quantity-btn'>
                                            <p className='mb-0 d-inline-block'><span className='yellow-txt-link me-2 font-weight-600'>$35</span> x</p>
                                            <div class="quantity-increase input-group d-flex align-items-start">
                                                <input type="button" value="-" class="button-minus rounded-circle-quantity" data-field="quantity" />
                                                <input type="number" step="1" max="" value="1" name="quantity" class="quantity-field d-inline-block mx-0 text-center font-weight-500" />
                                                <input type="button" value="+" class="button-plus rounded-circle-quantity" data-field="quantity" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <i class="fas fa-times"></i>
                                    </div>
                                </div>
                            </div> */}
                            {selectedRooms?.length > 0 && <div className='col-lg-3 col-md-6 col-sm-12 select-col-spacing'>
                                <button type="button" className='btn btn-gradient-purple' onClick={handleRoom}>Book Now</button>
                            </div>}
                        </div>
                        {/* </form> */}
                    </div>
                </div>}

                <div className='policies-wrapper' id='policies-tab'>
                    <div className='container'>
                        <h2 className='mb-3'>Policies</h2>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6 col-sm-12 policy-mb-space'>
                                <div className='policies_box_inner'>
                                    <h3>Property policies</h3>
                                    <p className='font-size-15 font-weight-500 mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <ul className='list-style-dots'>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 policy-mb-space'>
                                <div className='policies_box_inner'>
                                    <h3>Property description</h3>
                                    <p className='font-size-15 font-weight-500 mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <ul className='list-style-dots'>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 policy-mb-space'>
                                <div className='policies_box_inner'>
                                    <h3>Important information</h3>
                                    <p className='font-size-15 font-weight-500 mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <ul className='list-style-dots'>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 policy-mb-space'>
                                <div className='policies_box_inner'>
                                    <h3>Fees and extras</h3>
                                    <p className='font-size-15 font-weight-500 mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <ul className='list-style-dots'>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 policy-mb-space'>
                                <div className='policies_box_inner'>
                                    <h3>Cancellation Charges </h3>
                                    <p className='font-size-15 font-weight-500 mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <ul className='list-style-dots'>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='related_rooms_wrapper similar_rooms_wrapper pt-0' id='similarpr-tab'>
                    <div className='container'>
                        <h2 className='mb-4'>Similar Products</h2>
                        <div className='row'>
                            <Slider {...productssettings}>
                                {roomsData?.map((item, index) => (
                                    <div className='col-lg-4 col-md-4 col-sm-12' key={index}>
                                        <Products item={item} />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>



                <div class="hotel-details-modal modal fade" id="slidermodal" tabindex="-1" aria-labelledby="slidermodalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header border-0 position-absolute cross-icon">
                                {/* <h5 class="modal-title" id="slidermodalLabel">Modal title</h5> */}
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body border-0 desktop-slider-view p-0">
                                <Slider {...popupssettings}>
                                    {hotelDetail && hotelDetail[0]?.images?.length > 0 ? hotelDetail[0]?.images?.map((item, index) => (
                                        <div className="slider-image-container col-12" key={index}>
                                            <img src={item?.ImageUrl} className='img-fluid w-100' alt='img not found' />
                                        </div>
                                    )) : roomsData?.map((item, index) => (
                                        <div className='col-12' key={index}>
                                            <img src={HotelOne} className='img-fluid w-100' alt='img not found' />
                                        </div>
                                    ))}
                                    {/* {roomsData?.map((item, index) => (
                                        <div className='col-12' key={index}>
                                            <img src={HotelOne} alt='' className='img-fluid w-100' />
                                        </div>
                                    ))} */}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

const mapStateToProps = (state) => {
    return {
        hotelDetail: state.bookings.hotelDetail,
        currency: state.auth.currency
    }
};
export default connect(mapStateToProps)(HotelDetail);