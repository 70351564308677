import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { start_loading, stop_loading } from '../reduxStore/loader/loaderActions';
import axios from 'axios';
import PhoneNumber from '../shared/PhoneNumber';

const GuestsInfo = ({ show, handleClose, currIndex, setUsers }) => {
    const guestLists = JSON.parse(localStorage.getItem("guestlists"));
    const [holderData, setHolderData] = useState({
        Name: '',
        Surname: '',
        Gender: '',
        Age: '',
        Email: '',
        Nationality: ''
    });
    const [errors, setErrors] = useState({
        Name: '',
        Surname: '',
        Gender: '',
        Age: '',
        Email: '',
        Nationality: ''
    });
    const exptest = /^(?!.*\.\.)(?!.*-@)(?!.*#)([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})$/i;
    const dispatch = useDispatch();
    const [lists, setLists] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            dispatch(start_loading());
            try {
                const data = await axios.get('https://restcountries.com/v3.1/all');
                if (!data) return;

                const formatedData = data?.data?.map((item) => ({
                    nationality: item?.cca2,
                    flag: item?.flag,
                    countryname: item?.name?.common
                }));
                setLists(formatedData);
            } catch (error) {
                console.log("Error in Nation Lists", error);
            } finally {
                dispatch(stop_loading());
            }
        };
        fetchData();
    }, [show]);

    const handleChange = (value, label) => {
        const validations = {
            Name: value === "" ? "First name is required" : null,
            Surname: value === "" ? "Last name is required" : null,
            Gender: value === "" ? "Gender is required" : null,
            Age: value === "" ? "Age is required" : null,
            Email: value === "" ? "Email is required" : !exptest.test(value) ? "Invalid email" : null,
            Nationality: value === "" ? "Nationality is required" : null,
        };

        setHolderData((prev) => ({ ...prev, [label]: value }));
        setErrors((prev) => ({ ...prev, [label]: validations[label] || null }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const validations = {
            Name: holderData?.Name === "" ? "First name is required" : null,
            Surname: holderData?.Surname === "" ? "Last name is required" : null,
            Gender: holderData?.Gender === "" ? "Gender is required" : null,
            Age: holderData?.Age === "" ? "Age is required" : null,
            Email: holderData?.Email === "" ? "Email is required" : !exptest.test(holderData?.Email) ? "Invalid email" : null,
            Nationality: holderData?.Nationality === "" ? "Nationality is required" : null,
        };
        setErrors(validations);

        const filterErr = Object.values(validations).filter((item) => item !== null);
        if (filterErr?.length !== 0) return;

        const data = guestLists !== null ? [...guestLists, { guestindex: currIndex, ...holderData, }] : [{ guestindex: currIndex, ...holderData, }];

        localStorage.setItem("guestlists", JSON.stringify(data));
        console.log(data);
        setUsers(data);
        handleClose();
    };

    // console.log(guestLists);

    useEffect(() => {
        if (guestLists === null) return;

        const data = guestLists?.find((item) => item?.guestindex == currIndex);
        // console.log(data);
        setHolderData((prev) => ({
            ...prev,
            Name: '',
            Surname: '',
            Gender: '',
            Age: '',
            Email: '',
            Nationality: ''
        }));
    }, [show, currIndex]);

    // console.log(currIndex);

    return (
        <Modal show={show}>
            {/* <Modal.Header>
                <Modal.Title>
                    <div class="modal-header border-0 cross-icon p-0 align-items-center">
                        <div className='container-fluid travel-detail-header d-flex align-items-center'>
                            <h4 class="modal-title mb-0 lh-1" id="slidermodalLabel">Add Person 1 Details</h4>
                            <button type="button" class="btn-close" onClick={handleClose}></button>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                <div class="modal-content">
                    <div class="modal-header border-0 cross-icon p-0 align-items-center">
                        <h2>Add Person {currIndex} Details</h2>
                        <button type="button" class="btn-close" onClick={handleClose}></button>
                    </div>
                    <div class="modal-body border-0 modal-slider-view p-0">
                        <form className='left-fill-details' onSubmit={(e) => handleSubmit(e)}>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'>First Name</label>
                                            <input type="text" className='form-control' placeholder='Enter your first name' value={holderData?.Name} onChange={(e) => handleChange(e.target.value, 'Name')} />
                                            {errors.Name && <p className='text-danger'>{errors.Name}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'>Last Name</label>
                                            <input type="text" className='form-control' placeholder='Enter your first name' value={holderData?.Surname} onChange={(e) => handleChange(e.target.value, 'Surname')} />
                                            {errors.Surname && <p className='text-danger'>{errors.Surname}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'>Gender</label>
                                            <select className='text-grey-light w-100 form-select form-control' value={holderData?.Gender} onChange={(e) => handleChange(e.target.value, 'Gender')}>
                                                <option value="" disabled>Select Gender</option>
                                                <option value="Female">Female</option>
                                                <option value="Male">Male</option>
                                            </select>
                                            {errors.Gender && <p className='text-danger'>{errors.Gender}</p>}
                                        </div>
                                    </div>

                                    <div className='col-md-12'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'>Age</label>
                                            <input type="text" className='form-control' placeholder='Age' value={holderData?.Age} onChange={(e) => handleChange(e.target.value, 'Age')} />
                                            {errors.Age && <p className='text-danger'>{errors.Age}</p>}
                                            {/* <select className='text-grey-light w-100 form-select form-control' value={holderData?.Age} onChange={(e) => handleChange(e.target.value, 'Age')}>
                                        <option selected>Select age</option>
                                        <option>Female</option>
                                        <option>Male</option>
                                    </select> */}
                                        </div>
                                    </div>


                                    {/* <div className='col-md-4'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>Phone Number</label>
                                    <select className='text-grey-light w-100 form-select form-control'>
                                        <option selected>+91</option>
                                        <option>Female</option>
                                        <option>Male</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'></label>
                                    <input type="number" className='form-control' placeholder='Enter your phone number' />
                                </div>
                            </div> */}

                                    <div className='col-md-12'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'>Email</label>
                                            <input type="email" className='form-control' placeholder='Enter your email' value={holderData?.Email} onChange={(e) => handleChange(e.target.value, 'Email')} />
                                            {errors?.Email && <p className='text-danger'>{errors?.Email}</p>}
                                        </div>
                                    </div>

                                    <div className='col-md-12'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'>Nationality</label>
                                            <select className='text-grey-light w-100 form-select form-control' value={holderData?.Nationality} onChange={(e) => handleChange(e.target.value, 'Nationality')}>
                                                <option value="" disabled>Select Nationality</option>
                                                {lists.map((item, index) => (
                                                    <option key={index} value={item?.nationality}>
                                                        {item?.countryname} ({item?.nationality})
                                                    </option>
                                                ))}
                                            </select>
                                            {errors?.Nationality && <p className='text-danger'>{errors?.Nationality}</p>}
                                            {/* <input type="text" className='form-control' placeholder='Enter your nationality' /> */}
                                        </div>
                                    </div>


                                    {/* <div className='col-md-12'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>Date of  Birth</label>
                                    <div className='d-grid d-grid-calender w-100'>
                                        <select className='text-grey-light w-100 form-select form-control'>
                                            <option selected>DD</option>
                                            <option>DD</option>
                                            <option>DD</option>
                                        </select>
                                        <select className='text-grey-light w-100 form-select form-control'>
                                            <option selected>MM</option>
                                            <option>MM</option>
                                            <option>MM</option>
                                        </select>
                                        <select className='text-grey-light w-100 form-select form-control'>
                                            <option selected>YYYY</option>
                                            <option>YYYY</option>
                                            <option>YYYY</option>
                                        </select>
                                    </div>
                                </div>
                            </div> */}
                                </div>
                                <button className='btn-gradient-purple' type='submit'>Add Guest</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default GuestsInfo