import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { get_vender_id } from '../../reduxStore/User/userActions';
import { get_all_staff_members } from '../../reduxStore/vendors/vendorActions';

const VendorDashboard = ({ user, vendor_id, staff_members }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (vendor_id) {
            get_all_staff_members(user?.token, dispatch, vendor_id);
        }
    }, [vendor_id]);

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    return (
        <div className='mt-0'>
            <div className='admin-booking-detail-containers'>
                <div className='total-booking-container-wrap'>
                    <p className='p-md text-white'>Total Staff Members</p>
                    <h1 className='text-white'>{staff_members?.length}</h1>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id,
        staff_members: state.vendor.staff_members,
    }
}
export default connect(mapStateToProps)(VendorDashboard);