import React from 'react'
import ResultOne from '../../../src/assets/result-img.png'
import AirCondition from '../../assets/l-ac.png'
import BanqueRimg from '../../assets/banquet-1.png'
import GardenHotel from '../../assets/gardenS.png'
import PoolHotel from '../../assets/poolL.png'
import WifiHotel from '../../assets/wifi-net.png'
import GymHotel from '../../assets/gymM.png'
import ParkingHotel from '../../assets/parking.png'
import BuffetHotel from '../../assets/buffet.png'
import BeachHotel from '../../assets/beach-near.png'
import BarsHotel from '../../assets/barss.png'
import BarberHotel from '../../assets/barber-s.png'
import AtmDetail from '../../assets/atm-l.png'
import ReceptionsIMG from '../../assets/receptions.png'
import CafeHotel from '../../assets/cafe.png'
import FlyImg from '../../assets/flynas-logo.png'
import ClockImg from '../../assets/clock.png';
import LugageImg from '../../assets/luggage.png';
import { useNavigate } from 'react-router-dom'

const RoundFlight = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div className='srch-result-wrapper'>
                <div className='container'>
                    <div class="row align-items-start">
                        <div className='col-lg-3 col-md-12 col-sm-12'>
                            <div className='left-filter-wrapper'>
                                <div className='d-flex align-items-center justify-content-between bottom-border-filter'>
                                    <h3 className='mb-0'>Filter</h3>
                                    <p className='p-sm mb-0 text-purple font-weight-600'>Clear Filter</p>
                                </div>
                                <div className=''>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingstops">
                                                <button class="accordion-button collapsed acoordian-mine-button p-0 justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsestops" aria-expanded="false" aria-controls="flush-collapsestops">
                                                    Stops
                                                </button>
                                            </h2>
                                            <div id="flush-collapsestops" class="accordion-collapse collapse show" aria-labelledby="flush-headingstops" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body p-0">
                                                    <form className='filter-checkobx-form'>
                                                        <div class="form-check">
                                                            <input className="form-check-input" type="radio" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1 Time p-md" for="flexCheckDefault">
                                                                Any
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input" type="radio" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                Direct only
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input" type="radio" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                1 stop max
                                                            </label>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingOne">
                                                <button class="accordion-button collapsed acoordian-mine-button p-0 justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    Airlines
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body p-0">
                                                    <form className='filter-checkobx-form'>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1 Time p-md" for="flexCheckDefault">
                                                                IndiGo
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                Air India
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                Vistara
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                Air India Express
                                                            </label>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingOne">
                                                <button class="accordion-button collapsed acoordian-mine-button p-0 justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTime" aria-expanded="false" aria-controls="flush-collapseTime">
                                                    Timings
                                                </button>
                                            </h2>
                                            <div id="flush-collapseTime" class="accordion-collapse collapse show" aria-labelledby="flush-headingTime" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body p-0">
                                                    <h6 className='p-md text-purple font-weight-700 mb-1'>Fly Time</h6>
                                                    <div class="form-check form-switch custom-switchers">
                                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckchecked" checked />
                                                        <label class="form-check-label text-start p-md text-grey-light" for="flexSwitchCheckDefault">
                                                            Any Time
                                                        </label>
                                                    </div>
                                                    <form>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                Before 06:00 AM
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                06:00 AM - 11:59 AM
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                12:00 PM - 06:00 PM
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                After 06:00 PM
                                                            </label>
                                                        </div>
                                                    </form>
                                                    <h6 className='p-md text-purple font-weight-700 mb-1'>Arrive Time</h6>
                                                    <div class="form-check form-switch custom-switchers">
                                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckdefault" />
                                                        <label class="form-check-label text-start p-md text-grey-light" for="flexSwitchCheckDefault">
                                                            Any Time
                                                        </label>
                                                    </div>

                                                    <form>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" checked />
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                Before 06:00 AM
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                06:00 AM - 11:59 AM
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                12:00 PM - 06:00 PM
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                After 06:00 PM
                                                            </label>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingOne">
                                                <button class="accordion-button collapsed acoordian-mine-button p-0 justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapserange" aria-expanded="false" aria-controls="flush-collapserange">
                                                    Duration
                                                </button>
                                            </h2>
                                            <div id="flush-collapseTime" class="accordion-collapse collapse show" aria-labelledby="flush-headingrange" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body p-0">
                                                    <label for="customRange" class="form-label">Custom range</label>
                                                    <input type="range" class="form-range" id="customRange" />
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-12 col-sm-12">
                            <div className='ryt-filter roundedFlights-filters'>
                                <div className='fight-sort-by-wrapper mb-3'>
                                    <select class="form-select" aria-label="Default select example">
                                        <option selected>Economy</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>


                                <div className='w-100 d-grid grid-resultshow grid-resultshow-Flight selected-round-trip'>
                                    <div className=''>
                                        <div className='result-img-rounded flight-inner-wrapper'>
                                            <div className='departure-heading-wrap'>
                                                <h5>Departure</h5>
                                            </div>
                                            <div className=''>
                                                <img src={FlyImg} alt='' className='flight-img mb-2' />
                                                <h3 className=''>flynas</h3>
                                            </div>

                                            <div className='flight-book-time-wrapper'>
                                                <div className='flight-time-box'>
                                                    <div className=''>
                                                        <h5 className='font-family-poppins'>03:50 AM</h5>
                                                        <p className='text-uppercase p-md mb-0'>DEL</p>
                                                    </div>
                                                    <div className='dotted-flight-box'>
                                                        <div className='innerdotted-flight position-relative'>
                                                            <i class="fas fa-plane"></i>
                                                            <div className='small-box-divider green-bg-divider p-all-small d-inline-block'>Direct</div>
                                                        </div>
                                                        <div className='px-3 flight-bottom-txt d-flex align-items-center justify-content-between'>
                                                            <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={ClockImg} className='d-inline-block w-auto' />07h 50m</p>
                                                            <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={LugageImg} className='d-inline-block w-auto' />Baggage</p>
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        <h5 className='font-family-poppins text-end'>09:30 PM</h5>
                                                        <p className='text-uppercase p-md text-end mb-0'>DXB</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='dashed-border-outline'></div>

                                        <div className='result-img-rounded flight-inner-wrapper'>
                                            <div className='return-heading-wrap'>
                                                <h5>Return</h5>
                                            </div>
                                            <div className=''>
                                                <img src={FlyImg} alt='' className='flight-img mb-2' />
                                                <h3 className='mb-0'>flynas</h3>
                                            </div>

                                            <div className='flight-book-time-wrapper'>
                                                <div className='flight-time-box'>
                                                    <div className=''>
                                                        <h5 className='font-family-poppins'>--:-- --</h5>
                                                        <p className='text-uppercase p-md mb-0'>DXB</p>
                                                    </div>
                                                    <div className='dotted-flight-box'>
                                                        <div className='innerdotted-flight position-relative'>
                                                            <i class="fas fa-plane"></i>
                                                            <div className='small-box-divider not-selected-return-text p-all-small d-inline-block'>Not Selected</div>
                                                        </div>
                                                        <div className='px-3 flight-bottom-txt d-flex align-items-center justify-content-between'>
                                                            <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={ClockImg} className='d-inline-block w-auto' />--h --m</p>
                                                            <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={LugageImg} className='d-inline-block w-auto' />Baggage</p>
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        <h5 className='font-family-poppins text-end'>--:-- --</h5>
                                                        <p className='text-uppercase p-md text-end mb-0'>DEL</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='srch-price-box d-flex align-items-center'>
                                        <div className='inner-srch-spacing'>

                                            <div className='price-with-vat-wrap'>
                                                <p className='d-inline-block size-big mb-0 tab-0-content-sp'>
                                                    <span className='yellow-big-txt'>$55</span>
                                                </p>
                                                <p className='p-md'>(incl.VAT)</p>
                                            </div>

                                            <div className='departure-price-wrap'>
                                                <h6>Departure</h6>
                                                <p className='d-inline-block size-big mb-0 tab-0-content-sp'>
                                                    <span className='d-flex gap-2'>₹14,015<strike>₹15,999</strike></span>
                                                </p>
                                            </div>

                                            <div className='return-price-not-showing-wrap'>
                                                <h6>Return</h6>
                                                <p className='d-inline-block size-big mb-0 tab-0-content-sp'>
                                                    <span className='d-flex gap-2'>₹----</span>
                                                </p>
                                            </div>

                                            <a className='btn btn-gradient-purple text-white d-inline-block h-auto w-100 book-now-disabled'>Book Now</a>
                                        </div>
                                    </div>
                                </div>

                                <ul class="nav nav-tabs mb-4 disparture-return-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="departure-tab" data-bs-toggle="tab" data-bs-target="#departure" type="button" role="tab" aria-controls="departure" aria-selected="true">Departure</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="return-tab" data-bs-toggle="tab" data-bs-target="#return" type="button" role="tab" aria-controls="return" aria-selected="false">Return</button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="departure" role="tabpanel" aria-labelledby="departure-tab">
                                        <div className='w-100 d-grid grid-resultshow grid-resultshow-Flight selected-container-with-radio'>
                                            <div className='custom-radio-btn-wrap'>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                    {/* <label class="form-check-label" for="flexRadioDefault1">
                                                        Default radio
                                                    </label> */}
                                                </div>
                                            </div>
                                            <div className='result-img-rounded flight-inner-wrapper'>
                                                <div className=''>
                                                    <img src={FlyImg} alt='' className='flight-img mb-2' />
                                                    <h3 className='mb-0'>flynas</h3>
                                                </div>

                                                <div className='flight-book-time-wrapper'>
                                                    <div className='flight-time-box'>
                                                        <div className=''>
                                                            <h5 className='font-family-poppins'>03:50 AM</h5>
                                                            <p className='text-uppercase p-md mb-0'>DEL</p>
                                                        </div>
                                                        <div className='dotted-flight-box position-relative'>
                                                            <div className='innerdotted-flight position-relative'>
                                                                <i class="fas fa-plane"></i>
                                                                <div className='small-box-divider pink-bg-divider p-all-small d-inline-block'>1 Stop</div>
                                                            </div>
                                                            <div className='px-3 flight-bottom-txt d-flex align-items-center justify-content-between'>
                                                                <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={ClockImg} className='d-inline-block w-auto' />07h 50m</p>
                                                                <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={LugageImg} className='d-inline-block w-auto' />Baggage</p>
                                                            </div>
                                                        </div>
                                                        <div className=''>
                                                            <h5 className='font-family-poppins text-end'>09:30 PM</h5>
                                                            <p className='text-uppercase p-md text-end mb-0'>DXB</p>
                                                        </div>
                                                    </div>
                                                    <div className='economy-label-wrapper mt-2'>
                                                        <span>Economy</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>

                                                    <p className='p-md'>(incl.VAT)</p>

                                                    <a className='btn btn-gradient-purple text-white d-inline-block h-auto w-100' onClick={() => navigate('/flight-payment')}>Book Now</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='w-100 d-grid grid-resultshow grid-resultshow-Flight'>
                                            <div className='custom-radio-btn-wrap'>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                                    {/* <label class="form-check-label" for="flexRadioDefault1">
                                                        Default radio
                                                    </label> */}
                                                </div>
                                            </div>
                                            <div className='result-img-rounded flight-inner-wrapper'>
                                                <div className=''>
                                                    <img src={FlyImg} alt='' className='flight-img mb-2' />
                                                    <h3 className=''>flynas</h3>
                                                </div>

                                                <div className='flight-book-time-wrapper'>
                                                    <div className='flight-time-box'>
                                                        <div className=''>
                                                            <h5 className='font-family-poppins'>03:50 AM</h5>
                                                            <p className='text-uppercase p-md mb-0'>DEL</p>
                                                        </div>
                                                        <div className='dotted-flight-box'>
                                                            <div className='innerdotted-flight position-relative'>
                                                                <i class="fas fa-plane"></i>
                                                                <div className='small-box-divider pink-bg-divider p-all-small d-inline-block'>1 Stop</div>
                                                            </div>
                                                            <div className='px-3 flight-bottom-txt d-flex align-items-center justify-content-between'>
                                                                <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={ClockImg} className='d-inline-block w-auto' />07h 50m</p>
                                                                <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={LugageImg} className='d-inline-block w-auto' />Baggage</p>
                                                            </div>
                                                        </div>
                                                        <div className=''>
                                                            <h5 className='font-family-poppins text-end'>09:30 PM</h5>
                                                            <p className='text-uppercase p-md text-end mb-0'>DXB</p>
                                                        </div>
                                                    </div>
                                                    <div className='economy-label-wrapper mt-2'>
                                                        <span>Economy</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>

                                                    <p className='p-md'>(incl.VAT)</p>

                                                    <a className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='w-100 d-grid grid-resultshow grid-resultshow-Flight'>
                                            <div className='custom-radio-btn-wrap'>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                                    {/* <label class="form-check-label" for="flexRadioDefault1">
                                                        Default radio
                                                    </label> */}
                                                </div>
                                            </div>
                                            <div className='result-img-rounded flight-inner-wrapper'>
                                                <div className=''>
                                                    <img src={FlyImg} alt='' className='flight-img mb-2' />
                                                    <h3 className=''>flynas</h3>
                                                </div>

                                                <div className='flight-book-time-wrapper'>
                                                    <div className='flight-time-box'>
                                                        <div className=''>
                                                            <h5 className='font-family-poppins'>03:50 AM</h5>
                                                            <p className='text-uppercase p-md mb-0'>DEL</p>
                                                        </div>
                                                        <div className='dotted-flight-box'>
                                                            <div className='innerdotted-flight position-relative'>
                                                                <i class="fas fa-plane"></i>
                                                                <div className='small-box-divider green-bg-divider p-all-small d-inline-block'>Direct</div>
                                                            </div>
                                                            <div className='px-3 flight-bottom-txt d-flex align-items-center justify-content-between'>
                                                                <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={ClockImg} className='d-inline-block w-auto' />07h 50m</p>
                                                                <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={LugageImg} className='d-inline-block w-auto' />Baggage</p>
                                                            </div>
                                                        </div>
                                                        <div className=''>
                                                            <h5 className='font-family-poppins text-end'>09:30 PM</h5>
                                                            <p className='text-uppercase p-md text-end mb-0'>DXB</p>
                                                        </div>
                                                    </div>
                                                    <div className='economy-label-wrapper mt-2'>
                                                        <span>Economy</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>

                                                    <p className='p-md'>(incl.VAT)</p>

                                                    <a className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='w-100 d-grid grid-resultshow grid-resultshow-Flight'>
                                            <div className='custom-radio-btn-wrap'>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                                    {/* <label class="form-check-label" for="flexRadioDefault1">
                                                        Default radio
                                                    </label> */}
                                                </div>
                                            </div>
                                            <div className='result-img-rounded flight-inner-wrapper'>
                                                <div className=''>
                                                    <img src={FlyImg} alt='' className='flight-img mb-2' />
                                                    <h3 className=''>flynas</h3>
                                                </div>

                                                <div className='flight-book-time-wrapper'>
                                                    <div className='flight-time-box'>
                                                        <div className=''>
                                                            <h5 className='font-family-poppins'>03:50 AM</h5>
                                                            <p className='text-uppercase p-md mb-0'>DEL</p>
                                                        </div>
                                                        <div className='dotted-flight-box'>
                                                            <div className='innerdotted-flight position-relative'>
                                                                <i class="fas fa-plane"></i>
                                                                <div className='small-box-divider green-bg-divider p-all-small d-inline-block'>Direct</div>
                                                            </div>
                                                            <div className='px-3 flight-bottom-txt d-flex align-items-center justify-content-between'>
                                                                <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={ClockImg} className='d-inline-block w-auto' />07h 50m</p>
                                                                <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={LugageImg} className='d-inline-block w-auto' />Baggage</p>
                                                            </div>
                                                        </div>
                                                        <div className=''>
                                                            <h5 className='font-family-poppins text-end'>09:30 PM</h5>
                                                            <p className='text-uppercase p-md text-end mb-0'>DXB</p>
                                                        </div>
                                                    </div>
                                                    <div className='economy-label-wrapper mt-2'>
                                                        <span>Economy</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>

                                                    <p className='p-md'>(incl.VAT)</p>

                                                    <a className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="return" role="tabpanel" aria-labelledby="return-tab">
                                        <div className='w-100 d-grid grid-resultshow grid-resultshow-Flight'>
                                            <div className='custom-radio-btn-wrap'>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
                                                    {/* <label class="form-check-label" for="flexRadioDefault1">
                                                        Default radio
                                                    </label> */}
                                                </div>
                                            </div>
                                            <div className='result-img-rounded flight-inner-wrapper'>
                                                <div className=''>
                                                    <img src={FlyImg} alt='' className='flight-img mb-2' />
                                                    <h3 className=''>flynas</h3>
                                                </div>

                                                <div className='flight-book-time-wrapper'>
                                                    <div className='flight-time-box'>
                                                        <div className=''>
                                                            <h5 className='font-family-poppins'>03:50 AM</h5>
                                                            <p className='text-uppercase p-md mb-0'>DEL</p>
                                                        </div>
                                                        <div className='dotted-flight-box'>
                                                            <div className='innerdotted-flight position-relative'>
                                                                <i class="fas fa-plane"></i>
                                                                <div className='small-box-divider pink-bg-divider p-all-small d-inline-block'>1 Stop</div>
                                                            </div>
                                                            <div className='px-3 flight-bottom-txt d-flex align-items-center justify-content-between'>
                                                                <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={ClockImg} className='d-inline-block w-auto' />07h 50m</p>
                                                                <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={LugageImg} className='d-inline-block w-auto' />Baggage</p>
                                                            </div>
                                                        </div>
                                                        <div className=''>
                                                            <h5 className='font-family-poppins text-end'>09:30 PM</h5>
                                                            <p className='text-uppercase p-md text-end mb-0'>DXB</p>
                                                        </div>
                                                    </div>
                                                    <div className='economy-label-wrapper mt-2'>
                                                        <span>Economy</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>

                                                    <p className='p-md'>(incl.VAT)</p>

                                                    <a className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='w-100 d-grid grid-resultshow grid-resultshow-Flight'>
                                            <div className='custom-radio-btn-wrap'>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" />
                                                    {/* <label class="form-check-label" for="flexRadioDefault1">
                                                        Default radio
                                                    </label> */}
                                                </div>
                                            </div>
                                            <div className='result-img-rounded flight-inner-wrapper'>
                                                <div className=''>
                                                    <img src={FlyImg} alt='' className='flight-img mb-2' />
                                                    <h3 className=''>flynas</h3>
                                                </div>

                                                <div className='flight-book-time-wrapper'>
                                                    <div className='flight-time-box'>
                                                        <div className=''>
                                                            <h5 className='font-family-poppins'>03:50 AM</h5>
                                                            <p className='text-uppercase p-md mb-0'>DEL</p>
                                                        </div>
                                                        <div className='dotted-flight-box'>
                                                            <div className='innerdotted-flight position-relative'>
                                                                <i class="fas fa-plane"></i>
                                                                <div className='small-box-divider green-bg-divider p-all-small d-inline-block'>Direct</div>
                                                            </div>
                                                            <div className='px-3 flight-bottom-txt d-flex align-items-center justify-content-between'>
                                                                <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={ClockImg} className='d-inline-block w-auto' />07h 50m</p>
                                                                <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={LugageImg} className='d-inline-block w-auto' />Baggage</p>
                                                            </div>
                                                        </div>
                                                        <div className=''>
                                                            <h5 className='font-family-poppins text-end'>09:30 PM</h5>
                                                            <p className='text-uppercase p-md text-end mb-0'>DXB</p>
                                                        </div>
                                                    </div>
                                                    <div className='economy-label-wrapper mt-2'>
                                                        <span>Economy</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>

                                                    <p className='p-md'>(incl.VAT)</p>

                                                    <a className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='w-100 d-grid grid-resultshow grid-resultshow-Flight'>
                                            <div className='custom-radio-btn-wrap'>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault7" />
                                                    {/* <label class="form-check-label" for="flexRadioDefault1">
                                                        Default radio
                                                    </label> */}
                                                </div>
                                            </div>
                                            <div className='result-img-rounded flight-inner-wrapper'>
                                                <div className=''>
                                                    <img src={FlyImg} alt='' className='flight-img mb-2' />
                                                    <h3 className=''>flynas</h3>
                                                </div>

                                                <div className='flight-book-time-wrapper'>
                                                    <div className='flight-time-box'>
                                                        <div className=''>
                                                            <h5 className='font-family-poppins'>03:50 AM</h5>
                                                            <p className='text-uppercase p-md mb-0'>DEL</p>
                                                        </div>
                                                        <div className='dotted-flight-box'>
                                                            <div className='innerdotted-flight position-relative'>
                                                                <i class="fas fa-plane"></i>
                                                                <div className='small-box-divider green-bg-divider p-all-small d-inline-block'>Direct</div>
                                                            </div>
                                                            <div className='px-3 flight-bottom-txt d-flex align-items-center justify-content-between'>
                                                                <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={ClockImg} className='d-inline-block w-auto' />07h 50m</p>
                                                                <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={LugageImg} className='d-inline-block w-auto' />Baggage</p>
                                                            </div>
                                                        </div>
                                                        <div className=''>
                                                            <h5 className='font-family-poppins text-end'>09:30 PM</h5>
                                                            <p className='text-uppercase p-md text-end mb-0'>DXB</p>
                                                        </div>
                                                    </div>
                                                    <div className='economy-label-wrapper mt-2'>
                                                        <span>Economy</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='srch-price-box'>
                                                <div className='inner-srch-spacing'>
                                                    <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>

                                                    <p className='p-md'>(incl.VAT)</p>

                                                    <a className='btn btn-gradient-purple text-white d-inline-block h-auto w-100'>Book Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoundFlight