import { useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import PhoneNumber from '../shared/PhoneNumber';
import { Form } from 'react-bootstrap';
import { login_user, user_send_otp } from '../reduxStore/User/userActions';

const OTPLogin = ({ user, vendor_id }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const toast = useToast();
    const [formdata, setFormdata] = useState({
        phone_number: ''
    });
    const [country, setCountry] = useState('US');
    const [errors, setErrors] = useState({
        phone_number: '',
    });
    const [isSend, setIsSend] = useState(false);

    const dispatch = useDispatch();
    const [verifyData, setVerifyData] = useState('');
    let countrycode = getCountryCallingCode(country);
    const checkSend = JSON.parse(localStorage.getItem("isSend"));
    const userData = JSON.parse(localStorage.getItem("verifyData"));

    const [userOTP, setUserOTP] = useState('');
    const handleOTPChange = (otp) => {
        setUserOTP(otp);
    };

    const handleVerify = async (e) => {
        e.preventDefault();
        if (userOTP !== '') {
            const user_data = {
                username: userData,
                otp: Number(userOTP),
                login_with_otp: true,
                type: "user"
            };
            await login_user(user_data, dispatch, toast, navigate, vendor_id);
            localStorage.removeItem("isSend");
            localStorage.removeItem("verifyData");
        }
    };

    const handleChange = (label, value) => {
        switch (label) {
            case "phone_number":
                let checkval = `+${countrycode}` + value;
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: !isValidPhoneNumber(checkval) ? "Invalid Phone number" : null }));
                setVerifyData(value);
                break;

            default:
                setFormdata((prev) => ({ ...prev, [label]: value }));
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let checkval = `+${countrycode}` + formdata.phone_number;

        setErrors((prev) => ({
            ...prev,
            phone_number: formdata.phone_number === "" ? "Phone number is required" : !isValidPhoneNumber(checkval) ? "Invalid Phone number" : null
        }));

        if ((formdata.phone_number === "" || !isValidPhoneNumber(checkval))) return;

        const userdata = {
            phoneNo: formdata.phone_number,
            countryCode: `+${countrycode}`,
            type: 'user'
        };

        // if (location.pathname === "/admin-verify") {
        //     userdata.type = "admin";
        // } else {
        //     userdata.type = "user";
        // }

        let otpsend = await user_send_otp(userdata, dispatch, toast, vendor_id);
        if (otpsend) {
            localStorage.setItem("verifyData", JSON.stringify(verifyData));
            localStorage.setItem("isSend", JSON.stringify(true));
            setIsSend(true);
        }

        // if (otpsend) {
        //     localStorage.setItem("verifyData", JSON.stringify(verifyData));

        //     if (location.pathname === "/admin-verify") {
        //         navigate("/admin-otp-verify");
        //     } else {
        //         navigate("/otp-verify");
        //     }
        // }
    };

    useEffect(() => {
        if (checkSend) {
            setIsSend(true);
        } else {
            setIsSend(false);
        }
    }, []);

    const handleReset = async () => {
        const userdata = {
            phoneNo: userData,
            countryCode: `+${countrycode}`,
            type: 'user'
        };
        await user_send_otp(userdata, dispatch, toast, vendor_id);
    };

    useEffect(() => {
        if (formdata.phone_number === '') return;
        handleChange('phone_number', formdata?.phone_number);
    }, [country]);

    return (
        <div class="d-flex flex-column justify-content-start">
            <label className='form-label text-white fw-medium'> {checkSend ? 'OTP' : 'Login with OTP'} </label>
            {isSend
                ?
                <Form className="auth-form" onSubmit={handleVerify}>
                    <div className=''>
                        <div className="position-relative login-enter-otp-input">
                            <input
                                type='number'
                                value={userOTP}
                                onChange={(e) => handleOTPChange(e.target.value)}
                                onInput={(e) => {
                                    if (e.target.value.length > 6) {
                                        e.target.value = e.target.value.slice(0, 6);
                                    }
                                }}
                                placeholder='Enter OTP'
                                maxLength={6}
                                className='form-control'
                            />

                            <button type="submit" className="pwd-eye-wrap text-white">
                                <i class="bi bi-arrow-right"></i>
                            </button>
                        </div>
                        <div className='d-flex gap-5'>
                            <span className='cursor-pointer d-inline-block mt-2 text-white' onClick={handleReset}>Resend OTP?</span>

                        </div>
                        <div className='d-flex align-items-center justify-content-center'>
                            <p className='cursor-pointer text-white fw-medium mt-3 mb-0 text-center d-inline-block' onClick={() => {
                                localStorage.removeItem("isSend");
                                localStorage.removeItem("verifyData");
                                setIsSend(false);
                            }}>Go Back</p>
                        </div>
                    </div>
                </Form>
                :
                <Form onSubmit={(e) => handleSubmit(e)}>
                    <div className='login-with-otp-wrapper'>
                        <div className='position-relative'>
                            <PhoneNumber
                                country={country}
                                setCountry={(val) => setCountry(val)}
                                phoneNumber={formdata.phone_number}
                                handleChange={(val) => handleChange('phone_number', val)}
                            />
                            <button type='submit' className='pwd-eye-wrap text-white'><i class="bi bi-arrow-right"></i></button>
                        </div>
                        {errors.phone_number && <p className='text-danger'>{errors.phone_number}</p>}
                    </div>
                </Form>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id
    }
}
export default connect(mapStateToProps)(OTPLogin);