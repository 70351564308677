import React, { useRef, useState } from 'react'
import { Form } from 'react-bootstrap';
import { user_contact_form } from '../../reduxStore/User/userActions';
import { useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import Contact from '../../shared/Contact';

const Support = () => {
  const [formdata, setFormdata] = useState({
    name: '',
    email: '',
    description: '',
    attachment: ''
  });
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    description: '',
    attachment: ''
  });
  const exptest = /^(?!.*\.\.)(?!.*-@)(?!.*#)([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})$/i;
  const fileRef = useRef();
  const dispatch = useDispatch();
  const toast = useToast();

  const handleChange = (label, value) => {

    switch (label) {
      case "name":
        setFormdata((prev) => ({ ...prev, [label]: value }));
        setErrors((prev) => ({ ...prev, [label]: value === "" ? "Name is required" : null }));
        break;
      case "email":
        setFormdata((prev) => ({ ...prev, [label]: value }));
        setErrors((prev) => ({ ...prev, [label]: value === "" ? "Email is required" : !exptest.test(value) ? "Invalid email" : null }));
        break;
      case "description":
        setFormdata((prev) => ({ ...prev, [label]: value }));
        setErrors((prev) => ({ ...prev, [label]: value === "" ? "Description is required" : null }));
        break;

      default:
        setFormdata((prev) => ({ ...prev, [label]: value }));
        break;
    }
  };

  const handleUpload = (val) => {
    setFormdata((prev) => ({ ...prev, attachment: val }));
    setErrors((prev) => ({ ...prev, attachment: null }));
  };

  const handleValid = () => {
    let err = false;

    const Errors = {
      description: formdata.description === "" ? "Description is required" : null,
      name: formdata.name === "" ? "Name is required" : null,
      email: formdata.email === "" ? "Email is required" : !exptest.test(formdata.email) ? "Invalid email" : null,
      attachment: formdata.attachment === "" ? "Document is required" : null
    };

    let newErrors = Object.entries(Errors);
    newErrors.forEach(([key, value]) => {
      if (value) {
        err = true;
        setErrors((prev) => ({ ...prev, [key]: value }));
      }
    });

    return err;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (handleValid()) return;

    let isSend = await user_contact_form(dispatch, formdata, toast);
    if (isSend !== true) return;
    setFormdata({ name: '', email: '', description: '', attachment: '' });
  };

  return (
    <div>
      <h1 className='text-color-theme mb-3'>Support</h1>
      <div className='admin-support-page box-with-shadow-bg-wrap'>
        <Contact
          formdata={formdata}
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleUpload={handleUpload}
          fileRef={fileRef}
        />
      </div>
    </div>
  )
}

export default Support;