import React from 'react'
import ResultOne from '../../../src/assets/result-img.png'
import AirCondition from '../../assets/l-ac.png'
import BanqueRimg from '../../assets/banquet-1.png'
import GardenHotel from '../../assets/gardenS.png'
import PoolHotel from '../../assets/poolL.png'
import WifiHotel from '../../assets/wifi-net.png'
import GymHotel from '../../assets/gymM.png'
import ParkingHotel from '../../assets/parking.png'
import BuffetHotel from '../../assets/buffet.png'
import BeachHotel from '../../assets/beach-near.png'
import BarsHotel from '../../assets/barss.png'
import BarberHotel from '../../assets/barber-s.png'
import AtmDetail from '../../assets/atm-l.png'
import ReceptionsIMG from '../../assets/receptions.png'
import CafeHotel from '../../assets/cafe.png'
import FlyImg from '../../assets/flynas-logo.png'
import CarImg from '../../assets/white-car.png'
import PeopleImg from '../../assets/people-sharp-icon.svg';
import GearImg from '../../assets/gear-icon.svg';
import SuitcaseImg from '../../assets/suitcase.svg';
import SmCar from '../.././assets/sm-car.png'
import LgCar from '../.././assets/lg-car.png';
import SuvCar from '../.././assets/suv.png';
import PeopleCar from '../.././assets/us-car.png';
import PrCar from '../.././assets/pre-car.png';
import MdCar from '../.././assets/MD-CAR.png';
import { useNavigate } from 'react-router-dom'



const CarResult = () => {
    const navigate = useNavigate();

    return (
        <div>
            <div className='srch-result-wrapper'>
                <div className='container'>
                    <div class="row align-items-start">
                        <div className='col-lg-3 col-md-12 col-sm-12'>
                            <div className='left-filter-wrapper'>
                                <div className='d-flex align-items-center justify-content-between bottom-border-filter'>
                                    <h3 className='mb-0'>Filter</h3>
                                    <p className='p-sm mb-0 text-purple font-weight-600'>Clear Filter</p>
                                </div>
                                <div className=''>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingstops">
                                                <button class="accordion-button collapsed acoordian-mine-button p-0 justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsestops" aria-expanded="false" aria-controls="flush-collapsestops">
                                                    Rating
                                                </button>
                                            </h2>
                                            <div id="flush-collapsestops" class="accordion-collapse collapse show" aria-labelledby="flush-headingstops" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body p-0">
                                                    <form className='filter-checkobx-form'>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1" for="flexCheckDefault">
                                                                <div class="reviews-box d-flex align-items-center mb-2">
                                                                    <p className='d-inline-block p-md mb-0 me-1 lh-1'>5</p>
                                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1" for="flexCheckDefault">
                                                                <div class="reviews-box d-flex align-items-center mb-2">
                                                                    <p className='d-inline-block p-md mb-0 me-1 lh-1'>4</p>
                                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />

                                                            <label className="form-check-label lh-1" for="flexCheckDefault">
                                                                <div class="reviews-box d-flex align-items-center mb-2">
                                                                    <p className='d-inline-block p-md mb-0 me-1 lh-1'>3</p>
                                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />

                                                            <label className="form-check-label lh-1" for="flexCheckDefault">
                                                                <div class="reviews-box d-flex align-items-center mb-2">
                                                                    <p className='d-inline-block p-md mb-0 me-1 lh-1'>2</p>
                                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1" for="flexCheckDefault">
                                                                <div class="reviews-box d-flex align-items-center mb-2">
                                                                    <p className='d-inline-block p-md mb-0 me-1 lh-1'>1</p>
                                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingOne">
                                                <button class="accordion-button collapsed acoordian-mine-button p-0 justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    Car Features
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body p-0">
                                                    <form className='filter-checkobx-form'>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label text-grey-light lh-1 Time p-md" for="flexCheckDefault">
                                                                Air Conditioning
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                4+ Doors
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                Automatic
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                4x4
                                                            </label>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-12 col-sm-12">
                            <div className='ryt-resultshow-wrapper'>
                                <h3 className='text-grey-light spacing-result-txt mb-0'>Showing result for <span className='font-weight-600 text-color-theme'>“New Delhi”</span> Sun 10 Nov - Wed 13 Nov</h3>

                                <div className='car-tabs-features'>
                                    <div className='inner-car-tabs active-tab'>
                                        <img src={MdCar} className='d-block mx-auto' />
                                        <p className='p-md mb-0'>Medium Car</p>
                                    </div>
                                    <div className='inner-car-tabs'>
                                        <img src={SmCar} className='d-block mx-auto' />
                                        <p className='p-md mb-0'>Small Car</p>
                                    </div>
                                    <div className='inner-car-tabs'>
                                        <img src={LgCar} className='d-block mx-auto' />
                                        <p className='p-md mb-0'>Large Car</p>
                                    </div>
                                    <div className='inner-car-tabs'>
                                        <img src={SuvCar} className='d-block mx-auto' />
                                        <p className='p-md mb-0'>SUVs</p>
                                    </div>
                                    <div className='inner-car-tabs'>
                                        <img src={PeopleCar} className='d-block mx-auto' />
                                        <p className='p-md mb-0'>People Carrier</p>
                                    </div>
                                    <div className='inner-car-tabs'>
                                        <img src={PrCar} className='d-block mx-auto' />
                                        <p className='p-md mb-0'>Premium Car</p>
                                    </div>
                                </div>

                                <div className='w-100 d-grid grid-resultshow grid-resultshow-car'>
                                    <div className='result-img-rounded'>
                                        <div className=''>
                                            <img src={CarImg} alt='' className='w-100' />
                                        </div>
                                    </div>

                                    <div className='car-content-wrapper'>
                                        <h5 className='mb-0'>White Mercedes</h5>
                                        <p className='p-md mb-0'>Paris</p>
                                        <div class="reviews-box d-flex align-items-center mb-2">
                                            <p class="d-inline-block p-all-small mb-0 me-2">4.3</p>
                                            <ul class="d-inline-block star-rating p-0 mb-0">
                                                <li class="d-inline-block"><i class="fas fa-star p-all-small"></i></li>
                                                <li class="d-inline-block"><i class="fas fa-star p-all-small"></i></li>
                                                <li class="d-inline-block"><i class="fas fa-star p-all-small"></i></li>
                                                <li class="d-inline-block"><i class="fas fa-star p-all-small"></i></li>
                                                <li class="d-inline-block"><i class="fas fa-star p-all-small"></i></li>
                                            </ul>
                                            <p class="d-inline-block p-all-small mb-0 ms-2">(144 reviews)</p>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-7 col-md-7 col-sm-12'>
                                                <div className='car-inner-grid'>
                                                    <div className='d-inline-block text-center'>
                                                        <img src={PeopleImg} class="" alt="..." />
                                                        <p className='p-md text-center'>4</p>
                                                    </div>
                                                    <div className='text-center'>
                                                        <img src={GearImg} className='d-inline-block' />
                                                        <p className='p-md'>Automatic</p>
                                                    </div>
                                                    <div className='text-center'>
                                                        <img src={SuitcaseImg} />
                                                        <p className='p-md'>3</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='srch-price-box'>
                                        <div className='inner-srch-spacing'>
                                            <p className='d-inline-block p-lg mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$400</span>/Day</p>

                                            <p className='p-md mb-0 month-text'>$1800/month</p>

                                            <p className='p-md'>(incl.VAT)</p>

                                            <a className='btn btn-gradient-purple text-white d-inline-block h-auto w-100' onClick={() => navigate('/contact-details')}>Book Now</a>
                                        </div>
                                    </div>
                                </div>

                                <div className='w-100 d-grid grid-resultshow grid-resultshow-car'>
                                    <div className='result-img-rounded'>
                                        <div className=''>
                                            <img src={CarImg} alt='' className='w-100' />
                                        </div>
                                    </div>

                                    <div className='car-content-wrapper'>
                                        <h5 className='mb-0'>White Mercedes</h5>
                                        <p className='p-md mb-0'>Paris</p>
                                        <div class="reviews-box d-flex align-items-center mb-2">
                                            <p class="d-inline-block p-all-small mb-0 me-2">4.3</p>
                                            <ul class="d-inline-block star-rating p-0 mb-0">
                                                <li class="d-inline-block"><i class="fas fa-star p-all-small"></i></li>
                                                <li class="d-inline-block"><i class="fas fa-star p-all-small"></i></li>
                                                <li class="d-inline-block"><i class="fas fa-star p-all-small"></i></li>
                                                <li class="d-inline-block"><i class="fas fa-star p-all-small"></i></li>
                                                <li class="d-inline-block"><i class="fas fa-star p-all-small"></i></li>
                                            </ul>
                                            <p class="d-inline-block p-all-small mb-0 ms-2">(144 reviews)</p>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-7 col-md-7 col-sm-12'>
                                                <div className='car-inner-grid'>
                                                    <div className='d-inline-block text-center'>
                                                        <img src={PeopleImg} class="" alt="..." />
                                                        <p className='p-md text-center'>4</p>
                                                    </div>
                                                    <div className='text-center'>
                                                        <img src={GearImg} className='d-inline-block' />
                                                        <p className='p-md'>Automatic</p>
                                                    </div>
                                                    <div className='text-center'>
                                                        <img src={SuitcaseImg} />
                                                        <p className='p-md'>3</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='srch-price-box'>
                                        <div className='inner-srch-spacing'>
                                            <p className='d-inline-block p-lg mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$400</span>/Day</p>

                                            <p className='p-md mb-0 month-text'>$1800/month</p>

                                            <p className='p-md'>(incl.VAT)</p>

                                            <a className='btn btn-gradient-purple text-white d-inline-block h-auto w-100' onClick={() => navigate('/contact-details')}>Book Now</a>
                                        </div>
                                    </div>
                                </div>

                                <div className='w-100 d-grid grid-resultshow grid-resultshow-car'>
                                    <div className='result-img-rounded'>
                                        <div className=''>
                                            <img src={CarImg} alt='' className='w-100' />
                                        </div>
                                    </div>

                                    <div className='car-content-wrapper'>
                                        <h5 className='mb-0'>White Mercedes</h5>
                                        <p className='p-md mb-0'>Paris</p>
                                        <div class="reviews-box d-flex align-items-center mb-2">
                                            <p class="d-inline-block p-all-small mb-0 me-2">4.3</p>
                                            <ul class="d-inline-block star-rating p-0 mb-0">
                                                <li class="d-inline-block"><i class="fas fa-star p-all-small"></i></li>
                                                <li class="d-inline-block"><i class="fas fa-star p-all-small"></i></li>
                                                <li class="d-inline-block"><i class="fas fa-star p-all-small"></i></li>
                                                <li class="d-inline-block"><i class="fas fa-star p-all-small"></i></li>
                                                <li class="d-inline-block"><i class="fas fa-star p-all-small"></i></li>
                                            </ul>
                                            <p class="d-inline-block p-all-small mb-0 ms-2">(144 reviews)</p>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-7 col-md-7 col-sm-12'>
                                                <div className='car-inner-grid'>
                                                    <div className='d-inline-block text-center'>
                                                        <img src={PeopleImg} class="" alt="..." />
                                                        <p className='p-md text-center'>4</p>
                                                    </div>
                                                    <div className='text-center'>
                                                        <img src={GearImg} className='d-inline-block' />
                                                        <p className='p-md'>Automatic</p>
                                                    </div>
                                                    <div className='text-center'>
                                                        <img src={SuitcaseImg} />
                                                        <p className='p-md'>3</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='srch-price-box'>
                                        <div className='inner-srch-spacing'>
                                            <p className='d-inline-block p-lg mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$400</span>/Day</p>

                                            <p className='p-md mb-0 month-text'>$1800/month</p>

                                            <p className='p-md'>(incl.VAT)</p>

                                            <a className='btn btn-gradient-purple text-white d-inline-block h-auto w-100' onClick={() => navigate('/contact-details')}>Book Now</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CarResult