import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import SelectRoom from '../../assets/ryt-selct-room.png';
import MapImg from '../../assets/map.png';
import UserImg from '../../assets/user.png';
import CalenderImg from '../../assets/calendar.png';
import { loadStripe } from '@stripe/stripe-js';
import { CardNumberElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import PaymentCard from '../../shared/PaymentCard';
import { add_user_card, get_vender_id } from '../../reduxStore/User/userActions';
import { connect, useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { hotel_booking_confirm } from '../../reduxStore/booking/bookingActions';

const stripePromise = loadStripe("pk_test_51Q26ObEZOuFNrMpGVNLaMcgGcLmftyqoqUB2fnf9ZgOfKGdOH3aJrVxuvrczoeByTHc4cqgJgIw4tYQixDywZib000xSxPCeWf");

const CardDetails = ({ user, vendor_id }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const holderDetail = JSON.parse(localStorage.getItem("holderDetail"));
    const guestLists = JSON.parse(localStorage.getItem("guestlists"));
    const bookingData = JSON.parse(localStorage.getItem("BookingData"));
    const searchedData = JSON.parse(localStorage.getItem("searchedData"));
    const gdsname = JSON.parse(localStorage.getItem("selectedHotelGDS"));
    const [guests, setGuests] = useState([]);
    const [stripeData, setStripeData] = useState(null);
    const [stripeElements, setStripeElements] = useState(null);
    const [cardFeilds, setCardFeilds] = useState({
        cardNumber: true,
        cardExpiry: true,
        cardCvc: true,
        userName: true,
    });
    const [errors, setErrors] = useState({
        cardNumber: "",
        cardExpiry: "",
        cardCvc: "",
        userName: "",
    });
    const [formData, setFormData] = useState({ userName: '' });

    const handleCardElementChange = (event, label) => {
        switch (label) {
            case "cardNumber":
                if (event.empty) {
                    setErrors({ ...errors, cardNumber: "Card Number is required" });
                    setCardFeilds({ ...cardFeilds, cardNumber: true });
                } else if (event.error) {
                    setErrors({ ...errors, cardNumber: event.error.message });
                    setCardFeilds({ ...cardFeilds, cardNumber: true });
                } else {
                    setErrors({ ...errors, cardNumber: "" });
                    setCardFeilds({ ...cardFeilds, cardNumber: false });
                }
                break;
            case "cardExpiry":
                if (event.empty) {
                    setErrors({ ...errors, cardExpiry: "Card Expiry is required" });
                    setCardFeilds({ ...cardFeilds, cardExpiry: true });
                } else if (event.error) {
                    setErrors({ ...errors, cardExpiry: event.error.message });
                    setCardFeilds({ ...cardFeilds, cardExpiry: true });
                } else {
                    setErrors({ ...errors, cardExpiry: "" });
                    setCardFeilds({ ...cardFeilds, cardExpiry: false });
                }
                break;
            case "cardCvc":
                if (event.empty) {
                    setErrors({ ...errors, cardCvc: "Card CVC is required" });
                    setCardFeilds({ ...cardFeilds, cardCvc: true });
                } else if (event.error) {
                    setErrors({ ...errors, cardCvc: event.error.message });
                    setCardFeilds({ ...cardFeilds, cardCvc: true });
                } else {
                    setErrors({ ...errors, cardCvc: "" });
                    setCardFeilds({ ...cardFeilds, cardCvc: false });
                }
                break;
            case "userName":
                if (!event.target.value.trim()) {
                    setErrors({ ...errors, userName: "User name is required" });
                    setCardFeilds({ ...cardFeilds, userName: true });
                } else {
                    setErrors({ ...errors, userName: "" });
                    setCardFeilds({ ...cardFeilds, userName: false });
                    setFormData({ ...formData, userName: event.target.value });
                }
                break;
            default:
                break;
        }
    };

    const checkAllErrors = () => {
        let err = false;
        let output = Object.entries(cardFeilds);
        // console.log("ErrorsOutputs....",output);
        output.forEach(([key, value]) => {
            if (value) {
                err = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [key]:
                        key === "userName"
                            ? prevErrors.userName
                                ? prevErrors.userName
                                : "User name is required"
                            : key === "cardNumber"
                                ? prevErrors.cardNumber
                                    ? prevErrors.cardNumber
                                    : "Card Number is required"
                                : key === "cardExpiry"
                                    ? prevErrors.cardExpiry
                                        ? prevErrors.cardExpiry
                                        : "Card Expiry is required"
                                    : prevErrors.cardCvc
                                        ? prevErrors.cardCvc
                                        : "Card CVC is required",
                }));
                // console.log("errrr",err,key,value);
            } else {
                setCardFeilds((prevErrors) => ({ ...prevErrors, [key]: false }));
            }
        });
        return err;
    };

    // console.log(searchedData);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripeData || !stripeElements || checkAllErrors()) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }

        const cardElement = stripeElements.getElement(CardNumberElement);
        // const { error, token } = await stripeData.createToken(cardElement);
        const { paymentMethod } = await stripeData.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
                name: formData?.userName,
            },
        });
        // console.log("payment method ====>", paymentMethod);

        const bookingdetail = {
            ReferenceNo: bookingData ? bookingData?.ReferenceNo : "",
            Holder_details: holderDetail ? holderDetail : {},
            NumOfRooms: 1,
            CheckInDate: searchedData?.CheckInDate,
            CheckOutDate: searchedData?.CheckOutDate,
            GuestList: guests,
            card_details: {
                payment_method: paymentMethod ? paymentMethod?.id : "",
                amount: bookingData ? bookingData?.HotelList[0]?.TotalPrice : ""
            },
            RatePlanID: bookingData ? bookingData?.HotelList[0]?.RatePlanList[0]?.RatePlanID : "",
            Gds: gdsname ? gdsname : "DIDA",
            HotelID: bookingData ? bookingData?.HotelList[0]?.HotelID : "",
            Currency: bookingData ? bookingData?.HotelList[0]?.RatePlanList[0]?.Currency : "USD"
        };

        console.log("bookingdetail =====>", bookingdetail);
        let isBooked = await hotel_booking_confirm(dispatch, toast, vendor_id, bookingdetail);
        if (isBooked) {
            navigate('/hotel-booking/confirm');
        }


        // console.log("Error", error);
        // console.log("CArd token", token);
        // if (!token) return;

        // const stripedata = {
        //     cardToken: token?.id,
        // };
        // let isAdd = await add_user_card(vendor_id, user?.token, stripedata);
        // if (isAdd) navigate('/hotel-booking/confirm');
    };

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    // [
    //     {
    //         Name: {
    //             Last: "gehlot",
    //             First: "dheeraj"
    //         },
    //         IsAdult: true,
    //         Age: 24
    //     },
    //     {
    //         Name: {
    //             Last: "bansal",
    //             First: "anmol"
    //         },
    //         IsAdult: true,
    //         Age: 24
    //     }
    // ]

    useEffect(() => {
        if (holderDetail !== null && holderDetail !== undefined && guestLists?.length === 0) {
            setGuests([
                {
                    RoomNum: 1,
                    GuestInfo: [
                        {
                            Name: {
                                First: holderDetail?.Name,
                                Last: holderDetail?.Surname
                            },
                            IsAdult: Number(holderDetail?.Age) < 18 ? false : true,
                            Age: Number(holderDetail?.Age)
                        },
                    ]
                }
            ]);
        } else if (holderDetail !== null && holderDetail !== undefined && guestLists?.length !== 0) {
            setGuests([
                {
                    RoomNum: 1,
                    GuestInfo: [
                        {
                            Name: {
                                First: holderDetail?.Name,
                                Last: holderDetail?.Surname
                            },
                            IsAdult: Number(holderDetail?.Age) < 18 ? false : true,
                            Age: Number(holderDetail?.Age)
                        },
                    ]
                },
                ...guestLists?.map((item,index) => ({
                    RoomNum: index+2,
                    GuestInfo: [
                        {
                            Name: {
                                First: item?.Name,
                                Last: item?.Surname
                            },
                            IsAdult: Number(item?.Age) < 18 ? false : true,
                            Age: Number(item?.Age)
                        },
                    ]
                }))
            ]);
        }
    }, []);

   // console.log("Guests Dataaaaa=======>", guests);
    

    //for guets and only holder format
    // [
    //     {
    //         RoomNum: 1,
    //         GuestInfo: (holderDetail && guestLists !== null) ? [
    //             {
    //                 Name: {
    //                     Last: holderDetail?.Surname,
    //                     First: holderDetail?.Name
    //                 },
    //                 IsAdult: Number(holderDetail?.Age) < 18 ? false : true,
    //                 Age: Number(holderDetail?.Age)
    //             }
    //             , ...guestLists?.map((item) => ({
    //                 Name: {
    //                     Last: item?.Surname,
    //                     First: item?.Name
    //                 },
    //                 IsAdult: Number(item?.Age) < 18 ? false : true,
    //                 Age: Number(item?.Age)
    //             }))
    //         ] : (holderDetail && guestLists === null) ? [
    //             {
    //                 Name: {
    //                     Last: holderDetail?.Surname,
    //                     First: holderDetail?.Name
    //                 },
    //                 IsAdult: Number(holderDetail?.Age) < 18 ? false : true,
    //                 Age: Number(holderDetail?.Age)
    //             }
    //         ] : []
    //     }
    // ],

    return (
        <div>
            <div className='card-details-wrapper'>
                <div className='container'>
                    <Elements stripe={stripePromise}>
                        <ElementsConsumer>
                            {({ stripe, elements }) => {
                                setStripeElements(elements);
                                setStripeData(stripe);
                                return (
                                    <>
                                        <form onSubmit={handleSubmit}>
                                            <div className='row'>
                                                <div className='col-lg-8 col-md-7 col-sm-12'>
                                                    <div className='left-fill-details'>
                                                        <h2 className='mb-3'>Card Details</h2>

                                                        <PaymentCard
                                                            stripe={stripe}
                                                            elements={elements}
                                                            errors={errors}
                                                            handleCardElementChange={(e, label) => handleCardElementChange(e, label)}
                                                            formData={formData}
                                                        />

                                                        <div className='policies-wrapper pt-3'>
                                                            <h2 className='mb-3'>Policies</h2>
                                                            <div className='row'>
                                                                <div className='col-lg-6 col-md-6 col-sm-6'>
                                                                    <div className='policies_box_inner'>
                                                                        <h3>Fees and extras</h3>
                                                                        <ul className='list-style-dots'>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-6 col-md-6 col-sm-6'>
                                                                    <div className='policies_box_inner'>
                                                                        <h3>Cancellation Charges </h3>
                                                                        <ul className='list-style-dots'>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                            <li className='font-weight-500 font-size-15'>Lorem Ipsum is simply dummy text</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-5 col-sm-12'>
                                                    <div className='ryt-room-select-price'>
                                                        <img src={SelectRoom} alt='' className='img-fluid w-100 top-selct-img' />
                                                        <h5 className='hd-spacing-room-selct'>Movenpick Hotel And Residences Riyadh</h5>
                                                        <div class="reviews-box d-flex align-items-center mb-2 gap-2">
                                                            <p className='d-inline-block p-lg mb-0'>4.3</p>
                                                            <ul class="d-inline-block star-rating p-0 mb-0">
                                                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                                                <li class="d-inline-block"><i class="fas fa-star"></i></li><li class="d-inline-block"><i class="fas fa-star"></i></li>
                                                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                                            </ul>
                                                            <p className='d-inline-block p-lg mb-0'>(144 reviews)</p>
                                                        </div>
                                                        <p className='font-weight-500 font-size-15 mb-2 d-flex align-items-center gap-2'>
                                                            <img src={MapImg} className='d-inline-block' alt='' />
                                                            Diplomatic Enclave Chanakyapuri
                                                        </p>

                                                        <p className='font-weight-500 font-size-15 mb-2 d-flex align-items-center gap-2'>
                                                            <img src={UserImg} className='d-inline-block' alt='' />
                                                            2 Persons
                                                        </p>

                                                        <p className='font-weight-500 font-size-15 mb-4 d-flex align-items-center gap-2'><img src={CalenderImg} className='d-inline-block' alt='' />
                                                            23 Oct 2024
                                                            <i className="fas fa-arrow-right mx-2"></i>
                                                            24 Oct 2024
                                                        </p>

                                                        <div className='price-box-room '>
                                                            <h5 className='text-purple p-large mb-0'>Price</h5>
                                                            <div className='price-line-dotted align-items-end'>
                                                                <div className=''>
                                                                    <p className='p-md mb-0'>Regular Room - Queen Bed</p>
                                                                    <p className='p-sm mb-0'>1 Room</p>
                                                                </div>
                                                                <div className='text-end'>
                                                                    <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                                                </div>
                                                            </div>

                                                            <div className='price-line-dotted align-items-end'>
                                                                <div className=''>
                                                                    <p className='p-md mb-0'>Regular Room - Queen Bed</p>
                                                                    <p className='p-sm mb-0'>1 Room</p>
                                                                </div>
                                                                <div className='text-end'>
                                                                    <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                                                </div>
                                                            </div>

                                                            <div className='price-line-dotted price-line-plane align-items-end'>
                                                                <div className=''>
                                                                    <p className='p-md mb-0'>Regular Room - Queen Bed</p>
                                                                    <p className='p-sm mb-0'>1 Room</p>
                                                                </div>
                                                                <div className='text-end'>
                                                                    <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                                                </div>
                                                            </div>

                                                            <div className='price-line-dotted align-items-center mb-3 border-0'>
                                                                <div className=''>
                                                                    <p className='p-lg mb-0 font-weight-600 text-color-theme'>Total(incl.VAT)</p>
                                                                </div>
                                                                <div className='text-end'>
                                                                    <p className='p-large mb-0 font-weight-600 text-color-theme'>$137</p>
                                                                </div>
                                                            </div>

                                                            <button type="submit" className='btn-gradient-purple text-white'>Book Now</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </>
                                )
                            }}
                        </ElementsConsumer>
                    </Elements>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id
    }
}
export default connect(mapStateToProps)(CardDetails);