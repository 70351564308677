import React from "react";
import SelectRoom from '../../assets/ryt-selct-room.png';
import MapImg from '../../assets/map.png';
import UserImg from '../../assets/user.png';
import CalenderImg from '../../assets/calendar.png';
import FlyImg from '../../assets/flynas-logo.png'
import ClockImg from '../../assets/clock.png';
import LugageImg from '../../assets/luggage.png';
import UserSqImg from '../../assets/person-square .png'
import { Navigate } from "react-router-dom";

const FlightPayment = () => {
    return (
        <div>
            <div className='payment-details-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-7 col-sm-12'>
                            <h2 className="mb-4">Booking Details</h2>
                            <div className='flight-booking-details-wrapper'>
                                <div className='grid-resultshow'>
                                    <div className='departure-flight-detail-wrapper'>
                                        <div className='departure-flight-detail-heading d-flex align-items-center gap-3'>
                                            <h2>Departure</h2>
                                            <span className="text-grey-light">Sun, 10 Nov</span>
                                        </div>
                                        <div className="flight-class-select-wrap">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Economy</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='w-100'>
                                        <div className='result-img-rounded flight-inner-wrapper'>
                                            <div className=''>
                                                <img src={FlyImg} alt='' className='flight-img mb-2' />
                                                <h3 className=''>flynas</h3>
                                            </div>

                                            <div className='flight-book-time-wrapper'>
                                                <div className='flight-time-box'>
                                                    <div className=''>
                                                        <h5 className='font-family-poppins'>03:50 AM</h5>
                                                        <p className='text-uppercase p-md mb-0'>DEL</p>
                                                    </div>
                                                    <div className='dotted-flight-box'>
                                                        <div className='innerdotted-flight position-relative'>
                                                            <i class="fas fa-plane"></i>
                                                            <div className='small-box-divider green-bg-divider p-all-small d-inline-block'>Direct</div>
                                                        </div>
                                                        <div className='px-3 flight-bottom-txt d-flex align-items-center justify-content-between'>
                                                            <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={ClockImg} className='d-inline-block w-auto' />07h 50m</p>
                                                            <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={LugageImg} className='d-inline-block w-auto' />Baggage</p>
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        <h5 className='font-family-poppins text-end'>09:30 PM</h5>
                                                        <p className='text-uppercase p-md text-end mb-0'>DXB</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='baggage-details-container-wrapper'>
                                        <div className="baggage-details-container-grid">
                                            <div className="cabin-baggage-col-wrap d-flex align-items-center gap-2">
                                                <i class="bi bi-suitcase-lg h4 text-purple mb-0"></i>
                                                <div className="">
                                                    <h5 className="mb-1">
                                                        Cabin Baggage
                                                    </h5>
                                                    <span className="text-grey-light p-md">7 Kgs (1 piece only) / Adult</span>
                                                </div>
                                            </div>

                                            <div className="cabin-baggage-col-wrap d-flex align-items-center gap-2">
                                                <i class="bi bi-suitcase h4 text-purple mb-0"></i>
                                                <div className="">
                                                    <h5 className="mb-1">
                                                        Check-In Baggage
                                                    </h5>
                                                    <span className="text-grey-light p-md">15 Kgs (1 piece only) / Adult</span>
                                                </div>
                                            </div>

                                            <div className="add-baggage-btn-wrap d-flex justify-content-end">
                                                <a href="" className='btn-gradient-purple w-auto p-md d-flex align-items-center gap-1' data-bs-toggle="modal" data-bs-target="#add-baggage-popup"><i class="bi bi-plus"></i> Add</a>
                                            </div>
                                        </div>

                                        <div className="add-cancellation-policy-wrap  d-flex align-items-start gap-2">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                {/* <label class="form-check-label" for="flexCheckChecked">
                                                        Checked checkbox
                                                    </label> */}
                                            </div>
                                            <div className="">
                                                <h5 className="mb-1">
                                                    Add Cancellation Policy
                                                </h5>
                                                <span className="text-grey-light p-md">+ ₹999</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flight-booking-details-wrapper'>
                                <div className='grid-resultshow'>
                                    <div className='departure-flight-detail-wrapper'>
                                        <div className='departure-flight-detail-heading d-flex align-items-center gap-3'>
                                            <h2>Return</h2>
                                            <span className="text-grey-light">Sun, 10 Nov</span>
                                        </div>
                                        <div className="flight-class-select-wrap">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Economy</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='w-100'>
                                        <div className='result-img-rounded flight-inner-wrapper'>
                                            <div className=''>
                                                <img src={FlyImg} alt='' className='flight-img mb-2' />
                                                <h3 className=''>flynas</h3>
                                            </div>

                                            <div className='flight-book-time-wrapper'>
                                                <div className='flight-time-box'>
                                                    <div className=''>
                                                        <h5 className='font-family-poppins'>03:50 AM</h5>
                                                        <p className='text-uppercase p-md mb-0'>DEL</p>
                                                    </div>
                                                    <div className='dotted-flight-box'>
                                                        <div className='innerdotted-flight position-relative'>
                                                            <i class="fas fa-plane"></i>
                                                            <div className='small-box-divider green-bg-divider p-all-small d-inline-block'>Direct</div>
                                                        </div>
                                                        <div className='px-3 flight-bottom-txt d-flex align-items-center justify-content-between'>
                                                            <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={ClockImg} className='d-inline-block w-auto' />07h 50m</p>
                                                            <p className='p-sm mb-0 d-flex align-items-center gap-1'><img src={LugageImg} className='d-inline-block w-auto' />Baggage</p>
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        <h5 className='font-family-poppins text-end'>09:30 PM</h5>
                                                        <p className='text-uppercase p-md text-end mb-0'>DXB</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='baggage-details-container-wrapper'>
                                        <div className="baggage-details-container-grid">
                                            <div className="cabin-baggage-col-wrap d-flex align-items-center gap-2">
                                                <i class="bi bi-suitcase-lg h4 text-purple mb-0"></i>
                                                <div className="">
                                                    <h5 className="mb-1">
                                                        Cabin Baggage
                                                    </h5>
                                                    <span className="text-grey-light p-md">7 Kgs (1 piece only) / Adult</span>
                                                </div>
                                            </div>

                                            <div className="cabin-baggage-col-wrap d-flex align-items-center gap-2">
                                                <i class="bi bi-suitcase h4 text-purple mb-0"></i>
                                                <div className="">
                                                    <h5 className="mb-1">
                                                        Check-In Baggage
                                                    </h5>
                                                    <span className="text-grey-light p-md">15 Kgs (1 piece only) / Adult</span>
                                                </div>
                                            </div>

                                            <div className="add-baggage-btn-wrap d-flex justify-content-end">
                                                <a href="" className='btn-gradient-purple w-auto p-md d-flex align-items-center gap-1' data-bs-toggle="modal" data-bs-target="#add-baggage-popup"><i class="bi bi-plus"></i> Add</a>
                                            </div>
                                        </div>

                                        <div className="add-cancellation-policy-wrap  d-flex align-items-start gap-2">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                {/* <label class="form-check-label" for="flexCheckChecked">
                                                        Checked checkbox
                                                    </label> */}
                                            </div>
                                            <div className="">
                                                <h5 className="mb-1">
                                                    Add Cancellation Policy
                                                </h5>
                                                <span className="text-grey-light p-md">+ ₹999</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='left-fill-details'>
                                <div className='travel-payment-wrap border-solid-d'>
                                    <h2 className='mb-3'>Travellers </h2>
                                    <div className='d-flex align-items-center justify-content-between gap-travel-between'>
                                        <div className='d-flex align-items-center align-start-top gap-3'>
                                            <div className='travel-top-img'>
                                                <img src={UserSqImg} className='' />
                                            </div>
                                            <div className=''>
                                                <h3 className='font-family-poppins mb-0'>Traveller 1</h3>
                                                <p className='p-md mb-0'>*Adult - Should be above 18 years</p>
                                            </div>
                                        </div>
                                        <a href="" className='btn-gradient-purple w-auto p-md' data-bs-toggle="modal" data-bs-target="#travel-popup">Add</a>
                                    </div>

                                    <hr className='border-dotted' />

                                    <div className='d-flex align-items-center justify-content-between gap-travel-between'>
                                        <div className='d-flex align-items-center align-start-top gap-3'>
                                            <div className='travel-top-img'>
                                                <img src={UserSqImg} className='' />
                                            </div>
                                            <div className=''>
                                                <h3 className='font-family-poppins mb-0'>Traveller 1</h3>
                                                <p className='p-md mb-0'>*Adult - Should be above 18 years</p>
                                            </div>
                                        </div>
                                        <a href="" className='btn-gradient-purple w-auto p-md' data-bs-toggle="modal" data-bs-target="#travel-popup">Add</a>
                                    </div>
                                </div>

                                <div className='py-4 travel-contact-detail'>
                                    <h2 className='mb-3'>Contact Details</h2>

                                    <div className='important-note-wrap'>
                                        <p>Important: Booking details will be sent to</p>
                                    </div>

                                    <div className='form-group form-spacing'>
                                        <label class="form-label p-md text-grey-light">Phone</label>
                                        <input type="tel" className='form-control' placeholder='Enter your phone' />
                                    </div>
                                    <div className='form-group form-spacing'>
                                        <label class="form-label p-md text-grey-light">Location</label>
                                        <input type="text" className='form-control' placeholder='Enter detail' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-5 col-sm-12'>
                            <div className='ryt-room-select-price ryt-tour-payment'>
                                <h2 className="mb-3">Summary</h2>
                                <div className='price-box-room '>
                                    <h5 className='text-purple p-large mb-0'>Price</h5>
                                    <div className='price-line-dotted align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0'>Basic Cost</p>
                                            <p className='p-sm mb-0'>2 Adult</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'>₹1,11,500</p>
                                        </div>
                                    </div>

                                    <div className='price-line-dotted align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0'>Fees & Taxes</p>
                                            <p className='p-sm mb-0'>GST 5%</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'>+ ₹5,575</p>
                                        </div>
                                    </div>

                                    <div className='price-line-dotted align-items-center mb-3 border-0'>
                                        <div className=''>
                                            <p className='p-lg mb-0 font-weight-600 text-color-theme'>Total(incl.VAT)</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-large mb-0 font-weight-600 text-color-theme'>₹1,17,075</p>
                                        </div>
                                    </div>

                                    <button type="submit" className='btn-gradient-purple text-white' onClick={() => Navigate('/tour/card')}>Book Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="add-travel-modal modal fade" id="add-baggage-popup" tabindex="-1" aria-labelledby="travel-popupLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div className="modal-header d-block border-0 cross-icon p-0">
                            <div className='travel-detail-header d-flex align-items-center'>
                                <h2 class="modal-title mb-0 lh-1" id="slidermodalLabel">Add Extra Baggage</h2>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="baggage-note-wrap">
                                <p className="p-sm text-grey-light">Included Check-in baggage per person - 15 KGS</p>
                            </div>
                        </div>
                        <div class="modal-body border-0 modal-slider-view p-0">
                            <div className='add-baggage-list-wrap'>
                                <div className="additional-weight-wrap d-flex align-items-center gap-2">
                                    <i class="bi bi-suitcase-lg text-color-theme h5 mb-0"></i>
                                    <p className="mb-0">Additional 3 KG</p>
                                </div>
                                <div className="additional-weight-price">
                                    <h5 className="text-color-theme mb-0">₹1,515</h5>
                                </div>
                                <div className="d-flex align-items-center justify-content-center gap-3 additional-baggage-qty">
                                    <button className='rooms-qty-btn'>-</button>
                                    <span className='rooms-qty-text'>1</span>
                                    <button className='rooms-qty-btn'>+</button>
                                </div>
                            </div>

                            <div className='add-baggage-list-wrap'>
                                <div className="additional-weight-wrap d-flex align-items-center gap-2">
                                    <i class="bi bi-suitcase-lg text-color-theme h5 mb-0"></i>
                                    <p className="mb-0">Additional 5 KG</p>
                                </div>
                                <div className="additional-weight-price">
                                    <h5 className="text-color-theme mb-0">₹2,525</h5>
                                </div>
                                <div className="additional-baggage-qty">
                                    <a href="#" className="btn-gradient-purple d-flex align-items-center gap-1"><i class="bi bi-plus"></i> Add</a>
                                </div>
                            </div>

                            <div className='add-baggage-list-wrap'>
                                <div className="additional-weight-wrap d-flex align-items-center gap-2">
                                    <i class="bi bi-suitcase text-color-theme h5 mb-0"></i>
                                    <p className="mb-0">Additional 10 KG</p>
                                </div>
                                <div className="additional-weight-price">
                                    <h5 className="text-color-theme mb-0">₹5,052</h5>
                                </div>
                                <div className="additional-baggage-qty">
                                    <a href="#" className="btn-gradient-purple d-flex align-items-center gap-1"><i class="bi bi-plus"></i> Add</a>
                                </div>
                            </div>

                            <button className='btn-gradient-purple' type='submit'>Done</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="add-travel-modal modal fade" id="travel-popup" tabindex="-1" aria-labelledby="travel-popupLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header border-0 cross-icon p-0 align-items-center">
                            <div className='container-fluid travel-detail-header d-flex align-items-center'>
                                <h2 class="modal-title mb-0 lh-1" id="slidermodalLabel">Add Traveller Details</h2>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                        <div class="modal-body border-0 modal-slider-view p-0">
                            <div className='container-fluid'>
                                <form className='left-fill-details'>
                                    <div className='form-group form-spacing'>
                                        <label className='form-label p-lg text-grey-light'>First Name</label>
                                        <input type="text" className='form-control' placeholder='Enter your first name' />
                                    </div>
                                    <div className='form-group form-spacing'>
                                        <label className='form-label p-lg text-grey-light'>Last Name</label>
                                        <input type="text" className='form-control' placeholder='Enter your first name' />
                                    </div>
                                    <div className='form-group form-spacing'>
                                        <label className='form-label p-lg text-grey-light'>Gender</label>
                                        <select className='text-grey-light w-100 form-select'>
                                            <option selected>Selct Gender</option>
                                            <option>Female</option>
                                            <option>Male</option>
                                        </select>
                                    </div>

                                    <div className='form-group form-spacing'>
                                        <label className='form-label p-lg text-grey-light'>Date of  Birth</label>
                                        <div className='d-grid d-grid-calender w-100'>
                                            <select className='text-grey-light w-100 form-select'>
                                                <option selected>DD</option>
                                                <option>DD</option>
                                                <option>DD</option>
                                            </select>
                                            <select className='text-grey-light w-100 form-select'>
                                                <option selected>MM</option>
                                                <option>MM</option>
                                                <option>MM</option>
                                            </select>
                                            <select className='text-grey-light w-100 form-select'>
                                                <option selected>YYYY</option>
                                                <option>YYYY</option>
                                                <option>YYYY</option>
                                            </select>
                                        </div>
                                    </div>

                                    <button className='btn-gradient-purple' type='submit'>Add Traveller</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default FlightPayment;