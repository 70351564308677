import axios from "axios";
import { GET_ALL_ADMINS_LISTS, GET_ALL_PLANS, GET_ALL_USERS_LISTS, GET_DEFAULT_DATA, GET_USER_DETAILS, GET_USERS_TOTAL, GET_VENDOR_ID, IS_EDIT, LOGOUT, USER_UPDATE } from "./userTypes";
import { position } from "@chakra-ui/react";
import { start_loading, stop_loading } from "../loader/loaderActions";
// import { toast } from "react-toastify";

const { REACT_APP_OTA_URL, REACT_APP_OTA_VERIFY_URL } = process.env;

const headers = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const catch_errors_handle = (error, dispatch, toast) => {
  // console.log("errorororw2o32o34213421", error);
  if (error.response) {
    toast({
      title: error.response.data.message,
      status: "error",
      duration: 2000,
      position: "top-right",
      isClosable: true,
    });

    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      dispatch(set_update_user(""));
    }
  } else {
    // toast.error(error.message, {
    //   toastId: "errors1",
    // });
  }
};

export const set_update_user = (user) => {
  localStorage.setItem('userData', JSON.stringify(user))
  localStorage.setItem('token', user?.token)
  return {
    type: USER_UPDATE,
    payload: user,
  };
};

// USER API'S ====>

export const login_user = async (user, dispatch, toast, navigate, vendor_id) => {
  dispatch(start_loading());
  try {
    const res = await axios.post(`${REACT_APP_OTA_URL}auth/login/${vendor_id}`, JSON.stringify(user), headers);
    if (res?.data?.status) {
      dispatch(set_update_user(res?.data?.data));
      navigate('/');
      toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
      return true;
    } else {
      toast({ title: res.data.message === "Invalid Credentials" ? "Invalid OTP" : res.data.message, status: "error", duration: 2000, position: "top-right", isClosable: true, });
      return false;
    }
  } catch (error) {
    console.log(error);
    toast({
      title: error.response.data.message ? error.response.data.message : error.response.data.error ? error.response.data.error : error.response.data.errors,
      status: "error",
      duration: 2000,
      position: "top-right",
      isClosable: true,
    });
  } finally {
    dispatch(stop_loading());
  }
};

export const handle_user_social_login = async (vendor_id, userdata, toast, navigate, dispatch) => {
  dispatch(start_loading());
  try {
    const url = `${REACT_APP_OTA_URL}auth/social-login/${vendor_id}`;
    const HEADERS = {
      headers: {
        "Content-Type": "application/json",
      }
    };
    const res = await axios.post(url, JSON.stringify(userdata), HEADERS);
    if (res?.data?.status) {
      dispatch(set_update_user(res?.data?.data));
      navigate('/');
      toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
      return true;
    } else {
      toast({ title: res.data.message, status: "error", duration: 2000, position: "top-right", isClosable: true, });
      return false;
    }
  } catch (error) {
    console.log("social login error", error);
  } finally {
    dispatch(stop_loading());
  }
};

export const signup_user = async (userdata, dispatch, toast, vendor_id) => {
  dispatch(start_loading());
  try {
    const res = await axios.post(`${REACT_APP_OTA_URL}auth/signup/${vendor_id}`, JSON.stringify(userdata), headers);
    if (res.data && res.data.status) {
      toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
      return true;
    } else {
      toast({ title: res.data.message, status: "error", duration: 2000, position: "top-right", isClosable: true, });
      return false;
    }
  } catch (error) {
    console.log(error);
    catch_errors_handle(error, dispatch, toast);
  } finally {
    dispatch(stop_loading());
  }
};

export const get_vender_id = async (role, dispatch) => {
  try {
    const res = await axios.get(`${REACT_APP_OTA_URL}auth/configure/vendor/${role}`, headers);
    dispatch({ type: GET_VENDOR_ID, payload: res.data.vendor_config });
  } catch (error) {
    console.log(error);
  }
};

export const forgot_password = async (user, dispatch, vendor_id, toast) => {
  dispatch(start_loading());
  try {
    const res = await axios.post(`${REACT_APP_OTA_URL}auth/forgot-password/${vendor_id}`, JSON.stringify(user), headers);

    if (res) {
      toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
      return true;
    } else {
      //toast.error(res?.data?.message);
    }
  } catch (error) {
    catch_errors_handle(error, dispatch, toast);
  } finally {
    dispatch(stop_loading());
  }
};

export const reset_password = async (user, email, rToken, vendor_id, dispatch, navigate, toast) => {
  dispatch(start_loading());
  try {
    const url = `${REACT_APP_OTA_URL}auth/reset-password?email=${email}&token=${rToken}`;
    const res = await axios.put(url, JSON.stringify(user), headers);

    if (res?.data?.status) {
      toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
      navigate('/login');
      return true;
    } else {
      toast({ title: res.data.message, status: "error", duration: 2000, position: "top-right", isClosable: true, });
      return false;
    }
  } catch (error) {
    console.log(error);
    // catch_errors_handle(error, dispatch)
  }
  finally {
    dispatch(stop_loading());
  }
};

export const edit_profile = async (userdata, token, dispatch, user, vendor_id, toast) => {
  const HEADERS = {
    headers: {
      "x-access-token": token,
      "Content-Type": "multipart/form-data"
    }
  };
  const form_data = new FormData();
  Object.entries(userdata).forEach(([key, value]) => {
    form_data.append(key, value);
  });

  // form_data.append("email", userdata?.email);
  // form_data.append("first_name", userdata?.first_name);
  // form_data.append("last_name", userdata?.last_name);
  // form_data.append("dob", userdata?.dob);
  // form_data.append("address", userdata?.address);
  // form_data.append("profile_pic", userdata?.profile_pic);
  // form_data.append("phone_number", userdata?.phone_number);
  // form_data.append("country_code", userdata?.country_code);
  try {
    const res = await axios.put(`${REACT_APP_OTA_URL}profile/edit/${vendor_id}`, form_data, HEADERS);
    if (res.data && res.data.status) {
      if (user) {
        user.name = res.data.data.name;
        user.username = res.data.data.username;
        user.email = res.data.data.email;
        user.dob = res.data.data.dob;
        user.address = res.data.data.address;
        user.country_code = res.data.data.country_code;
        user.phone_number = res.data.data.phone_number;
        user.profile_pic = res.data.data.profile_pic;
        localStorage.setItem("userData", JSON.stringify(user));
        dispatch(set_update_user(user));
        dispatch({ type: IS_EDIT, payload: true });
      }
      toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
      return true;
    }
  } catch (error) {
    catch_errors_handle(error, dispatch, toast);
  }
};

export const user_send_otp = async (user_data, dispatch, toast, vendor_id) => {
  dispatch(start_loading());
  try {
    const res = await axios.post(`${REACT_APP_OTA_URL}auth/send-otp/${vendor_id}`, JSON.stringify(user_data), headers);

    if (res.data) {
      toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    toast({ title: error.response.data.errors, status: "error", duration: 2000, position: "top-right", isClosable: true, });
    // catch_errors_handle(error, dispatch);
  } finally {
    dispatch(stop_loading());
  }
};

export const change_password = async (form_data, token, dispatch, vendor_id, toast) => {
  const HEADERS = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    }
  };
  try {
    const res = await axios.put(`${REACT_APP_OTA_URL}profile/change-password/${vendor_id}`, form_data, HEADERS);
    if (res.data && res.data.status) {
      toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
      return true;
    }
  } catch (error) {
    toast({ title: error.response.data.message, status: "error", duration: 2000, position: "top-right", isClosable: true, });
    // catch_errors_handle(error, dispatch);
  }
};

export const get_profile_details = async (token, vendor_id, dispatch, toast) => {
  try {
    const url = `${REACT_APP_OTA_URL}profile/${vendor_id}`;
    const HEADERS = {
      headers: {
        "x-access-token": token,
      }
    };
    const res = await axios.get(url, HEADERS);
    if (res.data && res.data.status) {
      dispatch({ type: GET_USER_DETAILS, payload: res.data.data });
    }
  } catch (error) {
    // console.log("Err in Profile Details", error);
    catch_errors_handle(error, dispatch, toast);
  }
};

export const get_all_user_lists = async (toast, token, dispatch, vendor_id, searchval, page, limit, sortField, sortOrder) => {
  try {
    const url = `${REACT_APP_OTA_URL}agency/user-list/${vendor_id}?name=${searchval ? searchval : ''}&page=${page}&limit=${limit}${sortField ? `&sortField=${sortField}` : ''}${sortOrder ? `&sortOrder=${sortOrder}` : ''}`;
    const HEADERS = {
      headers: {
        "x-access-token": token,
      }
    };
    const res = await axios.get(url, HEADERS);
    if (res.data && res.data.status) {
      dispatch({ type: GET_ALL_USERS_LISTS, payload: res.data.data });
      dispatch({ type: GET_USERS_TOTAL, payload: res.data.length });
    }
  } catch (error) {
    catch_errors_handle(error, dispatch, toast);
    //console.log("Err in Users Lists", error);
  }
};

export const user_token_security_logout = async (vendor_id, token, toast, dispatch) => {
  try {
    const url = `${REACT_APP_OTA_URL}profile/logout/${vendor_id}`;
    const HEADERS = {
      headers: {
        "x-access-token": token,
      }
    };
    const res = await axios.post(url, {}, HEADERS);
    if (res.data.status) {
      toast({ title: "Logout Successfully", status: "success", duration: 2000, position: "top-right", isClosable: true, });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    catch_errors_handle(error, dispatch, toast);
    toast({ title: error.response.data.message, status: "error", duration: 2000, position: "top-right", isClosable: true, });
  }
};

// export const usd_to_other_currency_conversion = async (amount, from, to) => {
//   try {
//     const url = `https://api.frankfurter.app/latest?amount=${amount}&from=${from.toUpperCase()}&to=${to.toUpperCase()}`;
//     //const url = `https://api.apilayer.com/exchangerates_data/convert?to=${to.toUpperCase()}&from=${from.toUpperCase()}&amount=${amount}`;
//     // const HEADERS = {
//     //   headers: {
//     //     "apikey": `0lfJ2TkDOMDOiiqDGCfISByG60SNLC6V`
//     //   }
//     // };
//     const res = await axios.get(url);
//     console.log(res.data);
//     return res.data;
//   } catch (error) {
//     console.log("Error in Currency Conversion ---->", error);
//   }
// };

export const currency_converter = async (data) => {
  try {
    const url = `${REACT_APP_OTA_URL}agency/convert-currency`;
    const HEADERS = {
      headers: {
        "Content-Type": "application/json",
      }
    };
    const res = await axios.post(url, JSON.stringify(data), HEADERS);
    if (res.data && res.data.status) {
      return res.data;
    }
  } catch (error) {
    console.log("Error in Currency Conversion ---->", error);
  }
};

export const add_user_card = async (vendor_id, token, stripedata) => {
  try {
    const url = `${REACT_APP_OTA_URL}payment/addCard/${vendor_id}`;
    const HEADERS = {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      }
    };
    const res = await axios.post(url, JSON.stringify(stripedata), HEADERS);
    if (res.data && res.data.status) {
      console.log("card response==========>", res.data);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error in Add User ---->", error);
  }
};

export const get_default_language_currency = async (token, dispatch) => {
  try {
    const url = `${REACT_APP_OTA_URL}agency/default`;
    const HEADERS = {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      }
    };
    const res = await axios.get(url, HEADERS);
    if (res.data && res.data.status) {
      dispatch({ type: GET_DEFAULT_DATA, payload: res.data.data });
      return res.data.data;
    }
  } catch (error) {
    console.log("Error in Set Default Language ---->", error);
  }
};

export const check_user_verification = async (dispatch, vendor_id, email, resetToken, toast) => {
  dispatch(start_loading());
  try {
    const url = `${REACT_APP_OTA_URL}auth/email-verify/${vendor_id}?email=${email}&token=${resetToken}`;
    const res = await axios.get(url);
    if (res.data && res.data.status) {
      localStorage.setItem("emailVerified", JSON.stringify(true));
      return true;
    } else {
      localStorage.removeItem("emailVerified");
      // toast({ title: res.data.error, status: "error", duration: 2000, position: "top-right", isClosable: true, });
      return false;
    }
  } catch (error) {
    console.log("Error in User Verification ---->", error);
  } finally {
    dispatch(stop_loading());
  }
};

export const resend_email_verification_link = async (dispatch, vendor_id, email, token, toast) => {
  dispatch(start_loading());
  try {
    const url = `${REACT_APP_OTA_URL}auth/send-verify-mail/${vendor_id}?email=${email}`;
    const HEADERS = {
      headers: {
        "x-access-token": token,
      }
    };
    const res = await axios.get(url, HEADERS);
    if (res.data && res.data.status) {
      toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
    }
  } catch (error) {
    console.log("Error in resend link", error);
  } finally {
    dispatch(stop_loading());
  }
};

export const user_contact_form = async (dispatch, data, toast) => {
  dispatch(start_loading());
  try {
    const url = `${REACT_APP_OTA_URL}profile/cotsct-us`;
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const HEADERS = {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    };
    const res = await axios.post(url, formData, HEADERS);
    if (res.data && res.data.status) {
      toast({ title: res.data.meesage, status: "success", duration: 2000, position: "top-right", isClosable: true, });
      return true;
    }
  } catch (error) {
    console.log("Error in contact form", error);
  } finally {
    dispatch(stop_loading());
  }
};


