import React, { useEffect, useRef, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import PhoneNumber from '../shared/PhoneNumber';
import { edit_profile } from '../reduxStore/User/userActions';
import { useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import adminProfile from '../assets/admin-profile-img.svg';

const { REACT_APP_OTA_URL } = process.env;

const EditProfile = ({ show, handleClose, user, vendor_id, userDetails }) => {
    const dispatch = useDispatch();
    const toast = useToast();
    const [formdata, setFormdata] = useState({
        // username: '',
        email: '',
        name: '',
        // dob: '',
        // password: '',
        phone_number: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        picture: ''
    });
    const [country, setCountry] = useState('US');

    const [errors, setErrors] = useState({
        // username: '',
        email: '',
        name: '',
        // dob: '',
        // password: '',
        phone_number: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        picture: ''
    });
    const exptest = /^(?!.*\.\.)(?!.*-@)(?!.*#)([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})$/i;
    let countrycode = getCountryCallingCode(country);
    let checkval = `+${countrycode}` + formdata.phone_number;
    const imageRef = useRef();
    const [imagePreview, setImagePreview] = useState('');

    const handleChange = (label, value) => {

        switch (label) {
            case "username":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Username is required" : null }));
                break;
            case "email":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Email is required" : !exptest.test(value) ? "Invalid email" : null }));
                break;
            case "name":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Name is required" : null }));
                break;
            case "dob":
                setErrors({ ...errors, dob: value === "" ? "DOB is required" : null });
                setFormdata({ ...formdata, [label]: value });
                break;
            // case "password":
            //     setFormdata((prev) => ({ ...prev, [label]: value }));
            //     setErrors((prev) => ({ ...prev, [label]: value === "" ? "Password is required" : value.length <= 5 ? "Password should contain more than 5 characters" : null }));
            //     break;
            case "phone_number":
                let checkingval = `+${countrycode}` + value;
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: !isValidPhoneNumber(checkingval) ? "Invalid Phone number" : null }));
                break;
            case "address":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Address is required" : null }));
                break;
            case "city":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "City is required" : null }));
                break;
            case "state":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "State is required" : null }));
                break;
            case "zip_code":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Zip code is required" : value?.length < 5 ? "Invalid Zip code" : null }));
                break;

            default:
                setFormdata((prev) => ({ ...prev, [label]: value }));
                break;
        }
    };

    const handleValid = () => {
        let err = false;

        const Errors = {
            // username: formdata.username === "" ? "Username is required" : null,
            address: formdata.address === "" ? "Address is required" : null,
            email: formdata.email === "" ? "Email is required" : !exptest.test(formdata.email) ? "Invalid email" : null,
            name: formdata.name === "" ? "Name is required" : null,
            // dob: formdata?.dob === "" ? "DOB is required" : null,
            // password: formdata.password === "" ? "Password is required" : formdata.password.length <= 5 ? "Password should contain more than 5 characters" : null,
            phone_number: formdata.phone_number === "" ? "Phone number is required" : !isValidPhoneNumber(checkval) ? "Invalid Phone number" : null,
            city: formdata?.city === "" ? "City is required" : null,
            state: formdata?.state === "" ? "State is required" : null,
            zip_code: formdata?.zip_code === "" ? "Zip code is required" : formdata?.zip_code?.length < 5 ? "Invalid Zip code" : null,
            picture: formdata?.picture === "" ? "Upload your Profile Pic" : null
        };

        let newErrors = Object.entries(Errors);
        //console.log(newErrors);
        newErrors.forEach(([key, value]) => {
            if (value) {
                err = true;
                setErrors((prev) => ({ ...prev, [key]: value }));
            }
        });

        return err;
    };

    const handleUpload = (val) => {
        setFormdata((prev) => ({ ...prev, picture: val }));
        setImagePreview(URL.createObjectURL(val));
        setErrors((prev) => ({ ...prev, picture: null }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (handleValid()) return;

        const userdata = {
            ...formdata,
            country_code: `+${countrycode}`,
            country_code_name: country,
            // role: "user"
        }
        let isedit = await edit_profile(userdata, user?.token, dispatch, user, vendor_id, toast);
        if (isedit) {
            setFormdata({ email: '', name: '', phone_number: '', address: '', city: '', state: '', zip_code: '', });
            setErrors({ email: null, name: null, phone_number: null, address: null, city: null, state: null, zip_code: null });
            handleClose();
        }
    };

    useEffect(() => {
        if (userDetails) {
            setFormdata((prev) => ({
                ...prev,
                // username: userDetails?.username ? userDetails?.username : '',
                email: userDetails?.email ? userDetails?.email : '',
                name: userDetails?.name ? userDetails?.name : '',
                // dob: userDetails?.dob ? formatDate(userDetails?.dob) : '',
                phone_number: userDetails?.country_code === null ? '' : userDetails?.phone_number,
                address: userDetails?.address ? userDetails?.address : '',
                city: userDetails?.city ? userDetails?.city : '',
                state: userDetails?.state ? userDetails?.city : '',
                zip_code: userDetails?.zip_code ? userDetails?.zip_code : '',
                picture: userDetails?.profile_pic !== null ? userDetails?.profile_pic : ''
            }));
            setCountry(userDetails?.country_code_name === null ? 'US' : userDetails?.country_code_name);
            setImagePreview(userDetails?.profile_pic !== null ? `${REACT_APP_OTA_URL}${userDetails?.profile_pic}` : '');
        }
    }, [userDetails]);

    useEffect(() => {
        if (formdata?.phone_number === '') return;
        handleChange('phone_number', formdata?.phone_number);
    }, [country]);

    // console.log("userDetails =====>", userDetails, REACT_APP_OTA_URL);

    return (
        <Modal show={show} size='lg'>
            <Modal.Body className='p-4'>
                <div className="modal-content position-relative">
                    <div className='custom-popup-scrollbar'>
                        <div class="modal-header border-0 cross-icon p-0 align-items-center">
                            <div className='d-flex align-items-center'>
                                <h2 className='fw-bold text-color-theme'>Edit Profile</h2>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                            </div>
                        </div>
                        <Form onSubmit={(e) => handleSubmit(e)} noValidate>
                            <div className='container-fluid px-0'>
                                <div className='admin-profile-wrap'>
                                    <div className='admin-profile-img-container position-relative cursor-pointer' onClick={() => imageRef.current.click()}>
                                        <div className='admin-profile-edit-icon-wrap d-flex align-items-center justify-content-end position-absolute top-0 end-0 px-3'>
                                            <i className="bi bi-pencil-square cursor-pointer"></i>
                                        </div>
                                        <div className='d-flex flex-column light-border-circle-wrapper'>
                                            <div className='admin-profile-img-wrap'>
                                                <img src={imagePreview !== '' ? imagePreview : adminProfile} alt='' />
                                            </div>
                                            {errors.picture && <p className='text-danger'>{errors.picture}</p>}
                                        </div>
                                    </div>
                                    <input
                                        type='file'
                                        className='form-control d-none'
                                        accept='.jpeg,.jpg,.png'
                                        onChange={(e) => {
                                            const files = e.target.files[0];
                                            handleUpload(files);
                                            e.target.value = "";
                                        }}
                                        ref={imageRef}
                                    />
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Name </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Enter name'
                                                value={formdata.name}
                                                onChange={(e) => handleChange('name', e.target.value)}
                                            />
                                            {errors.name && <p className='text-danger'>{errors.name}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Email </label>
                                            <input
                                                type='email'
                                                className='form-control'
                                                placeholder='email'
                                                value={formdata.email}
                                                onChange={(e) => handleChange('email', e.target.value)}
                                                disabled={formdata?.email !== '' ? true : false}
                                            />
                                            {errors.email && <p className='text-danger'>{errors.email}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Phone Number </label>
                                            <PhoneNumber
                                                country={country}
                                                setCountry={(val) => setCountry(val)}
                                                phoneNumber={formdata.phone_number}
                                                handleChange={(val) => handleChange('phone_number', val)}
                                            />
                                            {errors.phone_number && <p className='text-danger'>{errors.phone_number}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Address </label>
                                            <textarea
                                                minLength={2}
                                                className='form-control'
                                                placeholder='Enter address'
                                                value={formdata.address}
                                                onChange={(e) => handleChange('address', e.target.value)}
                                            />
                                            {errors.address && <p className='text-danger'>{errors.address}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>State </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='State'
                                                value={formdata.state}
                                                onChange={(e) => handleChange('state', e.target.value)}
                                            />
                                            {errors.state && <p className='text-danger'>{errors.state}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>City </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='City'
                                                value={formdata.city}
                                                onChange={(e) => handleChange('city', e.target.value)}
                                            />
                                            {errors.city && <p className='text-danger'>{errors.city}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Zip Code </label>
                                            <input
                                                type='number'
                                                className='form-control'
                                                placeholder='Zip code'
                                                value={formdata?.zip_code}
                                                onChange={(e) => handleChange('zip_code', e.target.value)}
                                            />
                                            {errors.zip_code && <p className='text-danger'>{errors.zip_code}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex justify-content-end gap-3 py-3'>
                                <button type='button' className='disabled-btn w-auto' onClick={handleClose}>Cancel</button>
                                <button type='submit' className='btn-gradientlg-purple w-auto'>Save Changes</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditProfile