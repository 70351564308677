import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Nav, NavDropdown } from 'react-bootstrap';
import UsersList from '../Vendors/Components/UsersList';
import Dashboard from '../Vendors/Components/Dashboard';
import AdminProfile from '../Vendors/Components/AdminProfile';
import LanguagesList from '../Vendors/Languages/LanguagesList';
import adminProfile from '../assets/admin-profile-img.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { get_profile_details, get_vender_id, logout, user_token_security_logout } from '../reduxStore/User/userActions';
import { useToast } from '@chakra-ui/react';
import { CiHome, CiLogout, CiSettings } from 'react-icons/ci';
import { BsFillJournalBookmarkFill, BsInfoCircle, BsJournalBookmarkFill, BsSendX, BsUiChecksGrid } from 'react-icons/bs';
import { FaRegUser } from 'react-icons/fa';
import Staff from '../Vendors/Agencies/Staff';
import VendorProfile from '../Vendors/Components/VendorProfile';
import ChangePassword from '../components/UserPages/ChangePassword';
import VendorDashboard from '../Vendors/Components/VendorDashboard';
import StaffDashboard from '../Vendors/Components/StaffDashboard';
import UserHome from '../components/DashBoardPages/UserHome';
import MyBooking from '../components/DashBoardPages/MyBooking';
import { SupportOutlined } from '@mui/icons-material';
import Referral from '../components/DashBoardPages/Referral';
import Support from '../components/DashBoardPages/Support';
import EditProfile from '../Modals/EditProfile';
import Agency from '../Vendors/Agencies/Agency';
import AgenciesList from '../Vendors/Agencies/AgenciesList';
import Cancel from '../Modals/Cancel';

const menuItems = [
    { path: '/', title: 'Dashboard', icon: BsUiChecksGrid },
    { path: '/users', title: 'Customers', icon: BsJournalBookmarkFill },
    { path: '/agencies', title: 'Agencies', icon: CiHome },
    { path: '/profile', title: 'Profile', icon: FaRegUser },
    {
        path: '/**',
        title: 'Settings',
        icon: CiSettings,
        children: [{ path: '/languages', title: 'Languages' }],
    },
    { path: '/change-password', title: 'Change Password', icon: CiHome }
];

const agencyitems = [
    { path: '/', title: 'Dashboard', icon: BsUiChecksGrid },
    { path: '/staff', title: 'Staff', icon: BsJournalBookmarkFill },
    { path: '/profile', title: 'Profile', icon: FaRegUser },
    { path: '/change-password', title: 'Change Password', icon: CiHome }
];

const staffItems = [
    { path: '/', title: 'Dashboard', icon: BsUiChecksGrid },
    { path: '/profile', title: 'Profile', icon: FaRegUser },
    { path: '/change-password', title: 'Change Password', icon: CiHome }
];

const userItems = [
    { path: '/dashboard', title: 'Dashboard', icon: BsUiChecksGrid },
    { path: '/dashboard/bookings', title: 'My Booking', icon: BsFillJournalBookmarkFill },
    { path: '/dashboard/referrals', title: 'Referral', icon: BsSendX },
    { path: '/dashboard/support', title: 'Support', icon: BsInfoCircle },
    { path: '/dashboard/change-password', title: 'Change Password', icon: BsInfoCircle }
];

const { REACT_APP_OTA_URL } = process.env;

const Sidebar = ({ user, vendor_id, userDetails, isMobile, adminHeadToggle, setAdminHeadToggle }) => {
    const location = useLocation();
    const isSwitch = JSON.parse(localStorage.getItem("isNavigate"));
    const [activePath, setActivePath] = useState(isSwitch ? location.pathname : user?.role !== 'user' ? '/' : '/dashboard');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const [isEdit, setIsEdit] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    // Handle navigation
    const handleNavigate = (path) => {
        setActivePath(path);
        navigate(path);
        localStorage.setItem("isNavigate", JSON.stringify(true));

        if (isMobile) {
            setAdminHeadToggle(!adminHeadToggle);
        }
    };

    const handleLogout = async () => {
        let user_logout = await user_token_security_logout(vendor_id, user?.token, toast, dispatch);
        if (user_logout) {
            localStorage.removeItem('userData');
            localStorage.clear();
            dispatch(logout());
            if (isAdmin) {
                navigate('/admin');
                setIsCancel(false);
            } else {
                navigate('/');
                setIsCancel(false);
            }
        }
        // localStorage.removeItem('userData');
        // localStorage.clear();
        // dispatch(logout());
        // navigate('/');
    };

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    useEffect(() => {
        if (user && user?.role === "superadmin") {
            setIsAdmin(true);
        }
    }, []);

    // Render menu items dynamically
    const renderMenuItems = (items) =>
        items.map((item, index) => (
            <Nav.Item key={item.path}>
                {item.children ? (
                    // If the item has children, render a NavDropdown
                    <NavDropdown
                        title={
                            <span className="d-flex align-items-center text-white gap-2">
                                <span className="">
                                    {item.icon && <item.icon />}
                                </span>
                                {item.title}
                            </span>
                        }
                        id={`dropdown-${item.path}`}
                        active={activePath === item.path}
                        className="d-flex align-items-start text-white flex-column admin-setting-dropdown-leftside"
                    >
                        {item.children.map((child) => (
                            <NavDropdown.Item
                                key={child.path}
                                onClick={() => handleNavigate(child.path)}
                                active={activePath === child.path}
                                className='text-dark'
                            >
                                {child.title}
                            </NavDropdown.Item>
                        ))}
                    </NavDropdown>
                ) : (
                    // If there are no children, render a simple Nav.Link
                    <Nav.Link
                        onClick={() => handleNavigate(item.path)}
                        active={activePath === item.path}
                        className="d-flex align-items-center text-white gap-2"
                    >
                        <span className="">
                            {item.icon && <item.icon />}
                        </span>
                        {item.title}
                    </Nav.Link>
                )}
                {index === items.length - 1 && (
                    <div key="static-key" className="nav-item d-flex align-items-center text-white gap-2 cursor-pointer mt-3" onClick={() => setIsCancel(true)}>
                        <a href='javascript:void(0);' role="button" className='nav-link text-white d-flex align-items-center gap-2 w-100'>
                            <span className="">
                                <CiLogout />
                            </span>
                            Logout
                        </a>
                    </div>
                )}
            </Nav.Item>
        ));

    // Render dynamic content based on activePath
    const adminContent = () => {
        switch (activePath) {
            case '/':
                return <Dashboard />;
            case '/users':
                return <UsersList />;
            case '/agencies':
                return <AgenciesList />;
            case '/profile':
                return <AdminProfile />;
            case '/languages':
                return <LanguagesList />;
            case '/change-password':
                return <ChangePassword />;
            case '/create-agency':
                return <Agency />;
            case '/edit-agency':
                return <Agency />;
            default:
                return <div>Page Not Found</div>;
        }
    };

    const agencyContent = () => {
        switch (activePath) {
            case '/':
                return <VendorDashboard />;
            case '/staff':
                return <Staff />;
            case '/profile':
                return <VendorProfile />;
            case '/change-password':
                return <ChangePassword />;
            default:
                return <div>Page Not Found</div>;
        }
    };

    const staffContent = () => {
        switch (activePath) {
            case '/':
                return <StaffDashboard />;
            case '/profile':
                return <VendorProfile />;
            case '/change-password':
                return <ChangePassword />;
            default:
                return <div>Page Not Found</div>;
        }
    };

    const userContent = () => {
        switch (activePath) {
            case '/dashboard':
                return <UserHome />;
            case '/dashboard/bookings':
                return <MyBooking />;
            case '/dashboard/referrals':
                return <Referral />;
            case '/dashboard/support':
                return <Support />;
            case '/dashboard/change-password':
                return <ChangePassword />;
            default:
                return <div>Page Not Found</div>;
        }
    };

    useEffect(() => {
        if (user && vendor_id) {
            get_profile_details(user?.token, vendor_id, dispatch, toast);
        }
    }, [vendor_id]);

    useEffect(() => {
        if (location.pathname === '/create-agency' || location.pathname === '/agencies' || location.pathname === '/edit-agency') {
            setActivePath(location.pathname);
            navigate(location.pathname);
        }
    }, [location.pathname]);

    return (
        <div className='w-100'>
            <div className="">
                <div className={`sidebar ps-2 pe-2 pb-5 custom-admin-nav-tabs-wrap  ${adminHeadToggle ? 'open' : ''}`}>
                    <div className="d-flex flex-column align-items-center align-items-sm-start pt-2 text-white min-vh-100">
                        <Nav className="flex-column gap-3 w-100" id="menu">
                            {(user && user?.role === 'user') && <div className='admin-left-sidebar-wrap'>
                                <div className='admin-profile-wrap'>
                                    <div className='admin-profile-edit-icon-wrap d-flex align-items-center justify-content-end px-3'>
                                        <i class="bi bi-pencil-square cursor-pointer" onClick={() => setIsEdit(true)}>
                                        </i>
                                    </div>
                                    <div className='light-border-circle-wrapper'>
                                        <div className='admin-profile-img-wrap'>
                                            <img src={user?.profile_pic !== null ? `${REACT_APP_OTA_URL}${user?.profile_pic}` : adminProfile} alt='' />
                                        </div>
                                    </div>
                                </div>

                                <div className='admin-profile-detail-wrap'>
                                    <div className='admin-profile-name mb-3'>
                                        <h5 className='text-white mb-0'>{user?.name}</h5>
                                    </div>
                                    <div className='admin-user-personal-detail-wrap'>
                                        <ul className='ps-0 p-md d-flex flex-column gap-2'>
                                            <li className='d-flex align-items-start gap-2 text-break'>
                                                <i class="bi bi-envelope"></i>
                                                {user?.email}
                                            </li>
                                            <li className='d-flex align-items-center gap-2 text-break'>
                                                <i class="bi bi-telephone"></i>
                                                {user?.country_code} {user?.phone_number}
                                            </li>
                                            <li className='d-flex align-items-center gap-2 text-break'>
                                                <i class="bi bi-credit-card"></i>
                                                **** **** **** 3321
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>}
                            {renderMenuItems(user?.role === "user" ? userItems : user.role === "superadmin" ? menuItems : user.role === "staff" ? staffItems :
                                agencyitems)}
                        </Nav>
                    </div>
                </div>
                <div className={`content admin-right-sidebar-wrapper ${adminHeadToggle ? 'open' : ''}`}>
                    {user?.role === "user" ? userContent() : user.role === "superadmin" ? adminContent() : user.role === "staff" ? staffContent() : agencyContent()}
                </div>
            </div>
            <EditProfile show={isEdit} handleClose={() => setIsEdit(false)} user={user} vendor_id={vendor_id} userDetails={userDetails} />
            <Cancel
                show={isCancel}
                handleClose={() => setIsCancel(!isCancel)}
                content1={`Are you sure you want to logout?`}
                //content2={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."}
                handleCancel={handleLogout}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id,
        userDetails: state.auth.userDetails,
    }
}
export default connect(mapStateToProps)(Sidebar);
