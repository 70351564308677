import React from 'react'
import { Form } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

const OTALogin = ({ formdata, errors, handleChange, handleSubmit, isShow, setIsShow }) => {
    const location = useLocation();

    return (
        <div className={`${location?.pathname === '/admin' ? '' : ''}`}>
            <Form onSubmit={(e) => handleSubmit(e)} noValidate>
                <div className=''>
                    <div className='form-group mb-3'>
                        <label className='form-label text-white fw-medium'>Email</label>
                        <input
                            type='email'
                            className='form-group form-control'
                            placeholder='Enter your email'
                            value={formdata.email}
                            onChange={(e) => handleChange('email', e.target.value)}
                        />
                        {errors.email && <p className='text-danger'>{errors.email}</p>}
                    </div>
                    <div className='form-group'>
                        <label className='form-label text-white fw-medium'>Password</label>
                        <div className='position-relative pwd-field'>
                            <input
                                maxlength="45"
                                type={isShow ? 'text' : 'password'}
                                className='form-group form-control'
                                placeholder='Enter your password'
                                value={formdata.password}
                                onChange={(e) => handleChange('password', e.target.value)}
                                onKeyDown={(e) => e.key === " " && e.preventDefault()}
                            />
                            {!isShow ? (
                                <span className='pwd-eye-wrap'><i class="bi bi-eye-slash"
                                    color="black"
                                    size={20}
                                    onClick={setIsShow}
                                /></span>
                            ) : (
                                <span className='pwd-eye-wrap'><i class="bi bi-eye"
                                    color="black"
                                    size={20}
                                    onClick={setIsShow}
                                /></span>
                            )}
                        </div>
                        {errors.password && <p className='text-danger'>{errors.password}</p>}
                    </div>
                    {(location?.pathname !== "/admin") && <div class="d-flex justify-content-between align-items-center gap-2 my-3 remember-forgot-text">
                        <div class="form-check">
                            <input
                                className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            <label className="form-check-label text-white" for="flexCheckDefault">
                                Remember me
                            </label>
                        </div>
                        <Link to="/forgot-password" className='text-decoration-underline text-white'>Forgot Password?</Link>
                    </div>}
                    <div className={`login-btn-wrap ${location?.pathname === "/admin" ? 'mt-4' : ''}`}>
                        <button type='submit' className='ylw-theme-button w-100'>Login</button>
                    </div>
                    {(location?.pathname !== "/admin") && <p className='text-center text-white h5 my-3 or-text'>Or</p>}
                </div>
            </Form>
        </div>
    )
};

export default OTALogin;