import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en';

const PhoneNumber = ({ country, setCountry, phoneNumber, handleChange }) => {
    // console.log(getCountries());
    // console.log("countries----->", getCountries().map((item) => getCountryCallingCode(item)));
    const CountrySelect = ({ labels }) => {
        const [searchCountry, setSearchCountry] = useState('');
        const filtercountries = getCountries().filter((country) => labels[country].toLowerCase().includes(searchCountry.toLowerCase()));
        const filteredCodes = getCountries().filter((country) => getCountryCallingCode(country).includes(searchCountry));

        return (
            <Dropdown>
                <Dropdown.Toggle>
                    +{getCountryCallingCode(country !== undefined ? country : 'US')}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <div className="col-md-12 position-relative">
                        <div className='d-flex align-items-center'>
                            <span class="search-icon position-absolute pt-0 ps-3 search-icon-store">
                                <i class="fa fa-search"></i>
                            </span>
                            <input
                                type="text"
                                className="ps-5 border-top w-100 border-bottom py-2"
                                placeholder="Search Country"
                                value={searchCountry}
                                onChange={(e) => setSearchCountry(e.target.value)}
                                style={{ paddingLeft: '40px' }}
                            />
                        </div>
                    </div>
                    {(filtercountries?.length > 0 ? filtercountries : filteredCodes).map((country) =>
                        <Dropdown.Item key={country} onClick={() => setCountry(country)}>
                            {labels[country]} +{getCountryCallingCode(country)}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        )
    };

    return (
        <div className='d-flex signup-phnumber-field-wrap'>
            <CountrySelect labels={en} />
            <input
                type="number"
                placeholder='Phone number'
                value={phoneNumber}
                className='form-control'
                onChange={(e) => handleChange(e.target.value)}
            />
        </div>
    )
}

export default PhoneNumber;