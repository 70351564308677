import React, { useState } from 'react'
import { Button, Form, Tooltip } from 'react-bootstrap'
import PhoneNumber from './PhoneNumber'
import { RWebShare } from 'react-web-share';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, RedditIcon, RedditShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';

const EditProfile = ({ formdata, errors, handleChange, handleSubmit, maxDate, country, setCountry, userDetails }) => {

    const [textCopy, setTextCopy] = useState(false);
    const [isPop, setIsPop] = useState(false);

    const handleCopy = (code) => {
        navigator.clipboard.writeText(code).then(() => {
            setTextCopy(true);
            setTimeout(() => setTextCopy(false), 3000);
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Share this referral code',
                text: `Use this code: ${userDetails?.my_referral_code}`,
                url: window.location.href,
            }).then(() => console.log("Share successful"))
                .catch((error) => console.error("Error sharing:", error));
        } else {
            alert("Web Share API not supported in this browser.");
        }
    };

    return (
        <div>
            <Form onSubmit={(e) => handleSubmit(e)} noValidate>
                <div className='box-with-shadow-bg-wrap'>
                    <div className='container-fluid px-0'>
                        <div className='row'>
                            {/* <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <label className='form-label'>Username: </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='username'
                                        value={formdata.username}
                                        onChange={(e) => handleChange('username', e.target.value)}
                                    />
                                    {errors.username && <p className='text-danger'>{errors.username}</p>}
                                </div>
                            </div> */}
                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <label className='form-label'>Name </label>
                                    <input
                                        type='email'
                                        className='form-control'
                                        placeholder='Enter Name'
                                        value={formdata.name}
                                        onChange={(e) => handleChange('name', e.target.value)}
                                    />
                                    {errors.name && <p className='text-danger'>{errors.name}</p>}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <label className='form-label'>Email </label>
                                    <input
                                        type='email'
                                        className='form-control'
                                        placeholder='email'
                                        value={formdata.email}
                                        onChange={(e) => handleChange('email', e.target.value)}
                                        onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                        disabled={formdata?.email !== '' ? true : false}
                                    />
                                    {errors.email && <p className='text-danger'>{errors.email}</p>}
                                </div>
                            </div>

                            {/* <div className="col-md-6">
                                <div className='form-group mb-3'>
                                    <label className="form-label">DOB</label>
                                    <input
                                        type="date"
                                        name="dob"
                                        placeholder=""
                                        className="form-control"
                                        value={formdata?.dob}
                                        max={maxDate}
                                        onChange={(e) => handleChange('dob', e.target.value)}
                                    />
                                    {errors.dob && (
                                        <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">
                                            {errors.dob}
                                        </p>
                                    )}
                                </div>
                            </div> */}
                            {/* <div className='col-md-6'>
                        <label className='form-label'>Password: </label>
                        <input
                            type={isShow ? 'text' : 'password'}
                            className='form-control'
                            placeholder='******'
                            onChange={(e) => handleChange('password', e.target.value)}
                            onKeyDown={(e) => e.key === " " && e.preventDefault()}
                        />
                        {!isShow ? (
                            <i class="bi bi-eye-slash"
                                color="black"
                                size={20}
                                onClick={() => setIsShow(!isShow)}
                            />
                        ) : (
                            <i class="bi bi-eye"
                                color="black"
                                size={20}
                                onClick={() => setIsShow(!isShow)}
                            />
                        )}
                        {errors.password && <p className='text-danger'>{errors.password}</p>}
                    </div> */}
                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <label className='form-label'>Phone Number </label>
                                    <PhoneNumber
                                        country={country}
                                        setCountry={(val) => setCountry(val)}
                                        phoneNumber={formdata.phone_number}
                                        handleChange={(val) => handleChange('phone_number', val)}
                                    />

                                    {userDetails?.phoneVerify === false && (<div className="row align-items-center pt-3">
                                        <div className="col-auto">
                                            <i className="bi bi-x-circle text-danger fs-4"></i>
                                        </div>
                                        <div className="col-auto">
                                            <button className="btn btn-primary">
                                                Verify
                                            </button>
                                        </div>
                                    </div>)}

                                    {errors.phone_number && <p className='text-danger'>{errors.phone_number}</p>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group mb-3'>
                                    <label className='form-label'>Address </label>
                                    <textarea
                                        minLength={4}
                                        className='form-control'
                                        placeholder='Enter Address'
                                        value={formdata.address}
                                        onChange={(e) => handleChange('address', e.target.value)}
                                    />
                                    {errors.address && <p className='text-danger'>{errors.address}</p>}
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group mb-3'>
                                    <label className='form-label'>State </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='State'
                                        value={formdata.state}
                                        onChange={(e) => handleChange('state', e.target.value)}
                                    />
                                    {errors.state && <p className='text-danger'>{errors.state}</p>}
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group mb-3'>
                                    <label className='form-label'>City </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='City'
                                        value={formdata.city}
                                        onChange={(e) => handleChange('city', e.target.value)}
                                    />
                                    {errors.city && <p className='text-danger'>{errors.city}</p>}
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group mb-3'>
                                    <label className='form-label'>Zip Code </label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        placeholder='Zip code'
                                        value={formdata?.zip_code}
                                        onChange={(e) => handleChange('zip_code', e.target.value)}
                                    />
                                    {errors.zip_code && <p className='text-danger'>{errors.zip_code}</p>}
                                </div>
                            </div>
                            {/* <div className="col-md-6">
                                <div className='form-group mb-3'>
                                    <label className='form-label'>Referral Code: </label>
                                    <div className="d-flex align-items-center gap-2">
                                        <input
                                            type="text"
                                            className="form-control me-2"
                                            placeholder="Referral Code"
                                            value={userDetails?.my_referral_code}
                                            disabled
                                        />
                                        <div className='d-flex gap-2'>

                                            <i className="bi bi-share" onClick={() => setIsPop(!isPop)}></i>
                                            {isPop && <div
                                                className="share-popup"
                                                style={{
                                                    position: "fixed",
                                                    top: "82%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)",
                                                    background: "white",
                                                    padding: "20px",
                                                    borderRadius: "10px",
                                                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                                                    zIndex: 1000,
                                                    width: '25%'
                                                }}
                                            >
                                                <div>
                                                    <h3 className=''>Referral Code</h3>
                                                </div>

                                                <div
                                                    style={{
                                                        display: "grid",
                                                        gridTemplateColumns: "repeat(3, 1fr)",
                                                        gap: "10px",
                                                        justifyItems: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <FacebookShareButton url={userDetails?.my_referral_code} quote="Check out my referral code!">
                                                        <FacebookIcon size={60} round />
                                                    </FacebookShareButton>

                                                    <TwitterShareButton url={userDetails?.my_referral_code} title="Check out my referral code!">
                                                        <TwitterIcon size={60} round />
                                                    </TwitterShareButton>

                                                    <WhatsappShareButton url={userDetails?.my_referral_code} title="Check out my referral code!">
                                                        <WhatsappIcon size={60} round />
                                                    </WhatsappShareButton>

                                                    <RedditShareButton url={userDetails?.my_referral_code} title="Check out my referral code!">
                                                        <RedditIcon size={60} round />
                                                    </RedditShareButton>

                                                    <EmailShareButton url={userDetails?.my_referral_code} title="Check out my referral code!">
                                                        <EmailIcon size={60} round />
                                                    </EmailShareButton>

                                                    <LinkedinShareButton url={userDetails?.my_referral_code} title="Check out my referral code!">
                                                        <LinkedinIcon size={60} round />
                                                    </LinkedinShareButton>
                                                </div>

                                                <button type='button' className='ylw-theme-button mt-2 w-100' onClick={() => setIsPop(!isPop)}>Close</button>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className='col-md-12'>
                                <div className='d-flex justify-content-end'>
                                    <button type='submit' className='btn-gradientlg-purple w-auto'>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default EditProfile