import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { change_password, get_vender_id } from '../../reduxStore/User/userActions';
import { useToast } from '@chakra-ui/react';

const ChangePassword = ({ user, vendor_id }) => {
    const dispatch = useDispatch();
    const toast = useToast();
    const [formdata, setFormdata] = useState({ password: '', newpassword: '', confirmpassword: '' });
    const [errors, setErrors] = useState({ password: '', newpassword: '', confirmpassword: '' });
    const [showPass, setshowPass] = useState(false);
    const [newPass, setnewPass] = useState(false);
    const [confirmPass, setconfirmPass] = useState(false);

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'password':
                setErrors({ ...errors, password: value === '' ? 'Current Password is Required' : '' });
                setFormdata({ ...formdata, password: value.replace(/\s/g, "") });
                break;
            case 'newpassword':
                setErrors({ ...errors, newpassword: value === '' ? 'New Pasword is required' : value?.length < 6 ? 'Password length should be more than 5 characters' : value === formdata?.password ? 'New Password must be different from current password' : '' });
                setFormdata({ ...formdata, newpassword: value.replace(/\s/g, "") });
                break;
            case 'confirmpassword':
                setErrors({ ...errors, confirmpassword: value === '' ? 'Confirm Password is required' : value !== formdata?.newpassword ? 'Confirm Password doesnot match' : '' });
                setFormdata({ ...formdata, confirmpassword: value.replace(/\s/g, "") });
                break;

            default:
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { password, newpassword, confirmpassword } = formdata;
        setErrors({
            password: !password ? "Password is required" : "",
            newpassword: !newpassword ? "New Password is required" : newpassword === password ? "New Password must be different from current password" : "",
            confirmpassword: !confirmpassword ? "Confirm Password is required" : confirmpassword !== newpassword ? 'Confirm Password doesnot match' : "",
        });

        if (password && password !== newpassword && newpassword === confirmpassword) {
            const userdata = { currentPassword: password, newPassword: newpassword };
            let passupdated = await change_password(userdata, user?.token, dispatch, vendor_id, toast);
            //  get_user_profile(dispatch, user);
            if (passupdated) {
                setFormdata({ password: '', newpassword: '', confirmpassword: '' });
                setErrors({ password: '', newpassword: '', confirmpassword: '' });
            }
        }
    };

    return (
        <div className="admin-change-password-wrap">
            <h1 className="text-color-theme mb-3">Change Password</h1>
            <div className='admin-change-password-form box-with-shadow-bg-wrap'>

                <div className="profile-deatils">
                    <form onSubmit={handleSubmit}>

                        <div className='container-fluid px-0'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-group mb-3">
                                        <label className="form-label fw-medium">Current Password</label>
                                        <div className="password position-relative pwd-field">
                                            <input
                                                maxlength="45"
                                                type={showPass ? 'text' : 'password'}
                                                name="password"
                                                className="form-control"
                                                placeholder="Enter your current password"
                                                value={formdata?.password}
                                                onChange={handleChange}
                                                onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                            />
                                            {!showPass ? (<span className='pwd-eye-wrap'><i class="bi bi-eye-slash" onClick={() => setshowPass(!showPass)}></i></span>)
                                                : (<span className='pwd-eye-wrap'><i class="bi bi-eye" onClick={() => setshowPass(!showPass)}></i></span>)}
                                        </div>
                                        {errors.password && <p className="error-msg text-danger">{errors.password}</p>}

                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group mb-3">
                                        <label className="form-label fw-medium">New Password</label>
                                        <div className="password position-relative pwd-field">
                                            <input
                                                type={newPass ? "text" : "password"}
                                                name="newpassword"
                                                maxlength="45"
                                                className="form-control"
                                                placeholder="Enter your new password"
                                                value={formdata?.newpassword}
                                                onChange={handleChange}
                                                onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                            />
                                            {!newPass ? (<span className='pwd-eye-wrap'><i class="bi bi-eye-slash" onClick={() => setnewPass(!newPass)}></i></span>)
                                                : (<span className='pwd-eye-wrap'><i class="bi bi-eye" onClick={() => setnewPass(!newPass)}></i></span>)}
                                        </div>
                                        {errors.newpassword && <p className="error-msg text-danger">{errors.newpassword}</p>}

                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group mb-3">
                                        <label className="form-label fw-medium">Confirm Password</label>
                                        <div className="password position-relative pwd-field">
                                            <input
                                                type={confirmPass ? 'text' : 'password'}
                                                name="confirmpassword"
                                                maxlength="45"
                                                className="form-control"
                                                placeholder="Enter your confirm password"
                                                value={formdata?.confirmpassword}
                                                onChange={handleChange}
                                                onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                            />
                                            {!confirmPass ? (<span className='pwd-eye-wrap'><i class="bi bi-eye-slash" onClick={() => setconfirmPass(!confirmPass)}></i></span>)
                                                : (<span className='pwd-eye-wrap'><i class="bi bi-eye" onClick={() => setconfirmPass(!confirmPass)}></i></span>)}
                                        </div>
                                        {errors.confirmpassword && <p className="error-msg text-danger">{errors.confirmpassword}</p>}

                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='d-flex justify-content-end'>
                                        <button type="submit" className="btn-gradient-purple w-auto">
                                            Change Password
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        userDetails: state.auth.userDetails,
        vendor_id: state.auth.vendor_id
    }
}
export default connect(mapStateToProps)(ChangePassword);