import React, { useEffect, useState } from 'react'
import RoomOne from '../assets/room1.png'
import GreenCheck from '../assets/green-check.png'
import { Link } from 'react-router-dom'
import CurrencyLogo from './CurrencyLogo'
import { connect } from 'react-redux'
import { currency_converter } from '../reduxStore/User/userActions'

const RoomsCard = ({ item, hotelDetail, currency, roomslength, setSelectedRooms }) => {
    const hotelGDS = JSON.parse(localStorage.getItem("selectedHotelGDS"));
    const [convertedPrice, setConvertedPrice] = useState(Number(hotelGDS === "Juniper" ? item?.Price?.Gross : item?.TotalPrice));
    useEffect(() => {
        const fetchConvertedPrice = async () => {
            // if (currency !== 'USD') {
                const data = {
                    amount: Number(hotelGDS === "Juniper" ? item?.Price?.Gross : item?.TotalPrice),
                    from: hotelGDS === "Juniper" ? item?.Price?.Currency : item?.Currency ? item?.Currency : 'USD',
                    to: currency
                };
                const conversion = await currency_converter(data);
                setConvertedPrice(Math.round(conversion?.data));
            // } else {
            //     setConvertedPrice(Math.round(item?.price));
            // }
        };

        fetchConvertedPrice();
    }, [currency]);

    return (
        <div className='realted_inner'>
            <img src={RoomOne} className='img-fluid w-100' />
            <div className='inner-details-cnt'>
                <h3 className='my-3'>
                    {hotelGDS === "Juniper" ? item?.HotelRoom?.Name : item?.RoomName ? item?.RoomName : `Regular Room ${item} - Queen Bed`}
                    {/* Regular Room {item} - Queen Bed */}
                </h3>

                <div className='room-feature'>
                    <ul className='p-0'>
                        {hotelDetail && hotelDetail[0]?.facilities?.map((item) => (
                            <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />{item?.Description}</li>
                        ))}
                    </ul>
                </div>

                {/* <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='room-feature'>
                            <ul className='p-0'>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />2 Adult</li>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />Free Wi-fi</li>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />Coffee/tea maker</li>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />Queen Bed</li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='room-feature'>
                            <ul className='p-0'>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />TV</li>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />2 Adult</li>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />Air Condition</li>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />24 Hour Room Service</li>
                            </ul>
                        </div>
                    </div> */}

                <div className='price-room d-flex align-items-center justify-content-between'>
                    <div className='price-box-bottom-txt'>
                        <p className='d-inline-block size-big me-3 tab-0-content-sp'>
                            <b>
                                <CurrencyLogo currencyCode={currency} /> {convertedPrice}
                            </b>
                            <strike>$41</strike>
                        </p>
                        <p className='d-inline-block p-md'>1 Night(incl.VAT)</p>
                    </div>
                    <div className=''>
                        <p className='text-danger p-md mb-0'>Only 2 Left</p>
                    </div>
                </div>
                <button
                    type='button'
                    // to="/hotel-booking/payment"
                    className={roomslength > 0 ? 'disabled-btn' : 'btn btn-gradient-purple'}
                    onClick={() => {
                        setSelectedRooms([item]);
                    }}
                    disabled={roomslength !== 0}
                >
                    Add Room
                </button>
            </div>
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        currency: state.auth.currency
    }
};

export default connect(mapStateToProps)(RoomsCard);