import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { edit_profile, get_profile_details, get_vender_id } from '../../reduxStore/User/userActions';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import PhoneNumber from '../../shared/PhoneNumber';
import { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import moment from 'moment-timezone';
import EditProfile from '../../shared/EditProfile';

const AdminProfile = ({ user, vendor_id, userDetails }) => {
    const dispatch = useDispatch();
    const toast = useToast();
    const [formdata, setFormdata] = useState({
        username: '',
        email: '',
        name: '',
        dob: '',
        // password: '',
        phone_number: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        // country: '',
    });
    const [country, setCountry] = useState('US');

    const [errors, setErrors] = useState({
        username: '',
        email: '',
        name: '',
        dob: '',
        // password: '',
        phone_number: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
    });
    const exptest = /^(?!.*\.\.)(?!.*-@)(?!.*#)([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})$/i;
    const [isShow, setIsShow] = useState(false);
    let countrycode = getCountryCallingCode(country);
    let checkval = `+${countrycode}` + formdata.phone_number;
    const navigate = useNavigate();
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const maxDate = moment.tz(userTimeZone).format('YYYY-MM-DD');

    const handleChange = (label, value) => {

        switch (label) {
            // case "username":
            //     setFormdata((prev) => ({ ...prev, [label]: value }));
            //     setErrors((prev) => ({ ...prev, [label]: value === "" ? "Username is required" : null }));
            //     break;
            case "email":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Email is required" : !exptest.test(value) ? "Invalid email" : null }));
                break;
            case "name":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Name is required" : null }));
                break;
            // case "dob":
            //     setErrors({ ...errors, dob: value === "" ? "DOB is required" : null });
            //     setFormdata({ ...formdata, [label]: value });
            //     break;
            // case "password":
            //     setFormdata((prev) => ({ ...prev, [label]: value }));
            //     setErrors((prev) => ({ ...prev, [label]: value === "" ? "Password is required" : value.length <= 5 ? "Password should contain more than 5 characters" : null }));
            //     break;
            case "phone_number":
                let checkval = `+${countrycode}` + value;
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: !isValidPhoneNumber(checkval) ? "Invalid Phone number" : null }));
                break;
            case "address":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Address is required" : null }));
                break;
            case "city":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "City is required" : null }));
                break;
            case "state":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "State is required" : null }));
                break;
            case "zip_code":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Zip code is required" : value?.length < 5 ? "Invalid Zip code" : null }));
                break;


            default:
                setFormdata((prev) => ({ ...prev, [label]: value }));
                break;
        }
    };

    const handleValid = () => {
        let err = false;

        const Errors = {
            // username: formdata.username === "" ? "Username is required" : null,
            address: formdata.address === "" ? "Address is required" : null,
            email: formdata.email === "" ? "Email is required" : !exptest.test(formdata.email) ? "Invalid email" : null,
            name: formdata.name === "" ? "Name is required" : null,
            // dob: formdata?.dob === "" ? "DOB is required" : null,
            // password: formdata.password === "" ? "Password is required" : formdata.password.length <= 5 ? "Password should contain more than 5 characters" : null,
            city: formdata?.city === "" ? "City is required" : null,
            state: formdata?.state === "" ? "State is required" : null,
            zip_code: formdata?.zip_code === "" ? "Zip code is required" : formdata?.zip_code?.length < 5 ? "Invalid Zip code" : null,
            phone_number: formdata.phone_number === "" ? "Phone number is required" : !isValidPhoneNumber(checkval) ? "Invalid Phone number" : null,
        };

        let newErrors = Object.entries(Errors);
        console.log("Admin ---->", newErrors);
        newErrors.forEach(([key, value]) => {
            if (value) {
                err = true;
                setErrors((prev) => ({ ...prev, [key]: value }));
            }
        });

        return err;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (handleValid()) return;

        const userdata = {
            ...formdata,
            country_code: `+${countrycode}`,
            country_code_name: country,
            // role: "user"
        }
        await edit_profile(userdata, user?.token, dispatch, user, vendor_id, toast);
    };

    const formatDate = (inputdate) => {
        const newDate = new Date(inputdate);
        return newDate.toISOString().split('T')[0];
    };

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
        if (vendor_id) {
            get_profile_details(user?.token, vendor_id, dispatch, toast);
        }
    }, [vendor_id]);

    useEffect(() => {
        if (userDetails) {
            setFormdata((prev) => ({
                ...prev,
                username: userDetails?.username ? userDetails?.username : '',
                email: userDetails?.email ? userDetails?.email : '',
                name: userDetails?.name ? userDetails?.name : '',
                dob: userDetails?.dob ? formatDate(userDetails?.dob) : '',
                phone_number: userDetails?.country_code === null ? '' : userDetails?.phone_number,
                address: userDetails?.address ? userDetails?.address : '',
                city: userDetails?.city ? userDetails?.city : '',
                state: userDetails?.state ? userDetails?.state : '',
                zip_code: userDetails?.zip_code ? userDetails?.zip_code : ''
            }));
            setCountry(userDetails?.country_code_name === null ? 'US' : userDetails?.country_code_name);
        }
    }, [userDetails]);

    useEffect(() => {
        if (formdata.phone_number === '') return;
        handleChange('phone_number', formdata?.phone_number);
    }, [country]);

    return (
        <div>
            <h1 className='text-color-theme mb-3'>Admin Profile</h1>
            <EditProfile
                formdata={formdata}
                errors={errors}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                maxDate={maxDate}
                country={country}
                setCountry={setCountry}
            />
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        userDetails: state.auth.userDetails,
        vendor_id: state.auth.vendor_id
    }
}
export default connect(mapStateToProps)(AdminProfile);