import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import moment from 'moment-timezone';
import PhoneNumber from '../shared/PhoneNumber';
import { get_vender_id } from '../reduxStore/User/userActions';
import { connect, useDispatch } from 'react-redux';
import { admin_create_agency, get_all_medium_agencies, get_all_small_agencies, get_all_staff_members, staff_create_agency } from '../reduxStore/vendors/vendorActions';
import { useToast } from '@chakra-ui/react';

const AddAgency = ({ show, handleClose, user, vendor_id, isStaff }) => {

    const [formdata, setFormdata] = useState({
        // username: '',
        dob: '',
        name: '',
        email: '',
        // password: '',
        phone_number: '',
        address: '',
        role: '',
        city: '',
        state: '',
        zip_code: '',
        country: '',
        advanceAmount: '',
        receipt: ''

    });
    const [errors, setErrors] = useState({
        // username: '',
        dob: '',
        name: '',
        email: '',
        // password: '',
        phone_number: '',
        address: '',
        role: '',
        city: '',
        state: '',
        zip_code: '',
        country: '',
        advanceAmount: '',
        receipt: ''
    });
    const fileRef = useRef();
    const handleUpload = () => { fileRef.current.click(); };
    const toast = useToast();
    const [country, setCountry] = useState('US');
    const [isShow, setIsShow] = useState(false);
    let countrycode = getCountryCallingCode(country);
    let checkval = `+${countrycode}` + formdata.phone_number;
    const exptest = /^(?!.*\.\.)(?!.*-@)(?!.*#)([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})$/i;
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const maxDate = moment.tz(userTimeZone).format('YYYY-MM-DD');
    const roleoptions = [
        { name: 'Agency Type 1', label: 'smallAgency' },
        { name: 'Agency Type 2', label: 'mediumAgency' }
    ];
    const dispatch = useDispatch();

    const handleChange = (label, value) => {

        switch (label) {
            case "advanceAmount":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Advance amount is required" : null }));
                break;
            // case "username":
            //     setFormdata((prev) => ({ ...prev, [label]: value }));
            //     setErrors((prev) => ({ ...prev, [label]: value === "" ? "Username is required" : null }));
            //     break;
            case "name":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Name is required" : null }));
                break;
            case "email":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Email is required" : !exptest.test(value) ? "Invalid email" : null }));
                break;
            case "dob":
                setErrors({ ...errors, dob: value === "" ? "DOB is required" : null });
                setFormdata({ ...formdata, [label]: value });
                break;
            // case "password":
            //     setFormdata((prev) => ({ ...prev, [label]: value }));
            //     setErrors((prev) => ({ ...prev, [label]: value === "" ? "Password is required" : value.length <= 5 ? "Password should contain more than 5 characters" : null }));
            //     break;
            case "phone_number":
                let checkval = `+${countrycode}` + value;
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: !isValidPhoneNumber(checkval) ? "Invalid Phone number" : null }));
                break;
            case "address":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Address is required" : null }));
                break;
            case "role":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Role is required" : null }));
                break;
            case "city":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "City is required" : null }));
                break;
            case "state":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "State is required" : null }));
                break;
            case "zip_code":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Zip Code is required" : value.length < 5 ? "Invalid ZipCode" : null }));
                break;
            case "country":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Country is required" : null }));
                break;

            default:
                setFormdata((prev) => ({ ...prev, [label]: value }));
                break;
        }
    };

    const handleReceipt = (val) => {
        setFormdata((prev) => ({ ...prev, receipt: val }));
        setErrors((prev) => ({ ...prev, receipt: null }));
    };


    const handleValid = () => {
        let err = false;

        const Errors = {
            advanceAmount: formdata.advanceAmount === "" ? "Advance Amount is required" : null,
            receipt: formdata.receipt === "" ? "Upload the Receipt" : null,
            // username: formdata.username === "" ? "Username is required" : null,
            address: formdata.address === "" ? "Address is required" : null,
            name: formdata.name === "" ? "Name is required" : null,
            email: formdata.email === "" ? "Email is required" : !exptest.test(formdata.email) ? "Invalid email" : null,
            dob: formdata.dob === "" ? "DOB is required" : null,
            // password: formdata.password === "" ? "Password is required" : formdata.password.length <= 5 ? "Password should contain more than 5 characters" : null,
            phone_number: formdata.phone_number === "" ? "Phone number is required" : !isValidPhoneNumber(checkval) ? "Invalid Phone number" : null,
            role: formdata.role === "" ? "Role is required" : null,
            city: formdata.city === "" ? "City is required" : null,
            state: formdata.state === "" ? "State is required" : null,
            zip_code: formdata.zip_code === "" ? "Zip Code is required" : formdata?.zip_code?.length < 5 ? "Invalid ZipCode" : null,
            country: formdata.country === "" ? "Country is required" : null
        };

        let newErrors = Object.entries(Errors);
        // console.log("errrrrrr", newErrors);
        newErrors.forEach(([key, value]) => {
            if (value) {
                err = true;
                setErrors((prev) => ({ ...prev, [key]: value }));
            }
        });

        return err;
    };

    const handleReset = () => {
        setFormdata({ advanceAmount: '', dob: '', receipt: '', name: '', email: '', phone_number: '', address: '', role: '', city: '', state: '', zip_code: '', country: '', });
        setErrors({ advanceAmount: null, dob: null, receipt: null, name: null, email: null, phone_number: null, address: null, role: null, city: null, state: null, zip_code: null, country: null, });
        setCountry('US');
        handleClose();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (handleValid()) return;

        const userdata = {
            ...formdata,
            country_code: `+${countrycode}`,
            country_code_name: country,
            phone_number: Number(formdata.phone_number),
        };

        console.log("Agency Data----->", userdata);
        let create_agency = await admin_create_agency(vendor_id, user?.token, userdata, toast);

        if (create_agency) {
            get_all_small_agencies(user?.token, dispatch, vendor_id);
            get_all_medium_agencies(user?.token, dispatch, vendor_id);
            handleReset();
        }
    };

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    useEffect(() => {
        if (isStaff === true) {
            setFormdata((prev) => ({ ...prev, role: 'staff', advanceAmount: 4, receipt: 'sdsds' }));
        }
    }, [isStaff]);

    return (
        <div className='modal-content'>
            <Modal show={show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className='p-4'>

                    <div className='d-flex align-items-center justify-content-between mb-3 position-relative'>
                        <h2 className='mb-0'>Add {isStaff === true ? 'Staff' : 'Agency'}</h2>
                        <button type="button" class="btn-close fs-6 mb-0" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                    </div>

                    <div>
                        <Form onSubmit={(e) => handleSubmit(e)}>
                            <div className=''>
                                <div className='row'>
                                    {!isStaff && <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Role: </label>
                                            <select
                                                name='role'
                                                type="select"
                                                className="form-select"
                                                placeholder="Select role"
                                                value={formdata.role}
                                                onChange={(e) => handleChange('role', e.target.value)}
                                            >
                                                <option value="" disabled>Select</option>
                                                {roleoptions.map((item, index) => (
                                                    <option key={index} value={item.label}>{item.name}</option>
                                                ))}
                                            </select>
                                            {errors.role && <p className='text-danger'>{errors.role}</p>}
                                        </div>
                                    </div>}
                                    {!isStaff && <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Advance Amount: </label>
                                            <input
                                                type='number'
                                                className='form-control'
                                                placeholder='advance amount'
                                                value={formdata.advanceAmount}
                                                onChange={(e) => handleChange('advanceAmount', e.target.value)}
                                            />
                                            {errors.advanceAmount && <p className='text-danger'>{errors.advanceAmount}</p>}
                                        </div>
                                    </div>}
                                    <div className='col-md-6'>
                                        <div className='d-flex flex-column'>
                                            <label className='form-label'>
                                                Reciept : {formdata?.receipt !== '' ? formdata?.receipt?.name : ''}
                                            </label>
                                            <input
                                                type='file'
                                                className='form-control d-none'
                                                // value={formdata.advanceAmount}
                                                accept='.jpeg,.jpg,.png,.pdf'
                                                onChange={(e) => {
                                                    const files = e.target.files[0];
                                                    handleReceipt(files);
                                                    e.target.value = "";
                                                }}
                                                ref={fileRef}
                                            />
                                        </div>
                                        <Button type='button' className='form-control bg-white text-start' style={{ color: '#696969' }} onClick={handleUpload} >Upload</Button>
                                        {errors.receipt && <p className='text-danger'>{errors.receipt}</p>}
                                    </div>
                                    {/* <div className='col-md-6'>
                                    <label className='form-label'>Username: </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='username'
                                        value={formdata.username}
                                        onChange={(e) => handleChange('username', e.target.value)}
                                    />
                                    {errors.username && <p className='text-danger'>{errors.username}</p>}
                                </div> */}
                                    {!isStaff && <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>DOB: </label>
                                            <input
                                                type='date'
                                                className='form-control'
                                                placeholder=''
                                                defaultValue={formdata?.dob}
                                                max={maxDate}
                                                onChange={(e) => handleChange('dob', e.target.value)}
                                            />
                                            {errors.dob && (
                                                <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">
                                                    {errors.dob}
                                                </p>
                                            )}
                                        </div>
                                    </div>}
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Name: </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='name'
                                                value={formdata.name}
                                                onChange={(e) => handleChange('name', e.target.value)}
                                            />
                                            {errors.name && <p className='text-danger'>{errors.name}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Email: </label>
                                            <input
                                                type='email'
                                                className='form-control'
                                                placeholder='email'
                                                value={formdata.email}
                                                onChange={(e) => handleChange('email', e.target.value)}
                                            />
                                            {errors.email && <p className='text-danger'>{errors.email}</p>}
                                        </div>
                                    </div>
                                    {/* <div className='col-md-10'>
                                    <label className='form-label'>Password: </label>
                                    <input
                                        type={isShow ? 'text' : 'password'}
                                        className='form-control'
                                        placeholder='******'
                                        onChange={(e) => handleChange('password', e.target.value)}
                                        onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                    />
                                    {!isShow ? (
                                        <i class="bi bi-eye-slash"
                                            color="black"
                                            size={20}
                                            onClick={() => setIsShow(!isShow)}
                                        />
                                    ) : (
                                        <i class="bi bi-eye"
                                            color="black"
                                            size={20}
                                            onClick={() => setIsShow(!isShow)}
                                        />
                                    )}
                                    {errors.password && <p className='text-danger'>{errors.password}</p>}
                                </div> */}
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Phone Number: </label>
                                            <PhoneNumber
                                                country={country}
                                                setCountry={(val) => setCountry(val)}
                                                phoneNumber={formdata.phone_number}
                                                handleChange={(val) => handleChange('phone_number', val)}
                                            />
                                            {errors.phone_number && <p className='text-danger'>{errors.phone_number}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>City: </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='city'
                                                value={formdata.city}
                                                onChange={(e) => handleChange('city', e.target.value)}
                                            />
                                            {errors.city && <p className='text-danger'>{errors.city}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>State: </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='state'
                                                value={formdata.state}
                                                onChange={(e) => handleChange('state', e.target.value)}
                                            />
                                            {errors.state && <p className='text-danger'>{errors.state}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Country: </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='country'
                                                value={formdata.country}
                                                onChange={(e) => handleChange('country', e.target.value)}
                                            />
                                            {errors.country && <p className='text-danger'>{errors.country}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Zip Code: </label>
                                            <input
                                                type='number'
                                                className='form-control'
                                                placeholder='zip code'
                                                value={formdata.zip_code}
                                                onChange={(e) => handleChange('zip_code', e.target.value)}
                                            />
                                            {errors.zip_code && <p className='text-danger'>{errors.zip_code}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Address: </label>
                                            <textarea
                                                minLength={4}
                                                className='form-control'
                                                placeholder='address details'
                                                value={formdata.address}
                                                onChange={(e) => handleChange('address', e.target.value)}
                                            />
                                            {errors.address && <p className='text-danger'>{errors.address}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12 d-flex gap-2 justify-content-end mt-3'>
                                    <button type='submit' className='btn-gradientlg-purple w-auto'>Add {isStaff === true ? 'Staff' : 'Agency'}</button>
                                    <button type='button' className='disabled-btn w-auto' onClick={handleReset}>Cancel</button>
                                </div>

                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id
    }
}
export default connect(mapStateToProps)(AddAgency);