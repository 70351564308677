import React, { useEffect, useState } from 'react'
import SelectRoom from '../../assets/ryt-selct-room.png';
import MapImg from '../../assets/map.png';
import UserImg from '../../assets/user.png';
import UserSqImg from '../../assets/person-square .png'
import CalenderImg from '../../assets/calendar.png';
import { useNavigate } from 'react-router-dom';
import HolderInfo from '../../Modals/HolderInfo';
import GuestsInfo from '../../Modals/GuestsInfo';
import { useToast } from '@chakra-ui/react';
const HotelBooking = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const holderDetail = JSON.parse(localStorage.getItem("holderDetail"));
    const guestLists = JSON.parse(localStorage.getItem("guestlists"));
    const bookingData = JSON.parse(localStorage.getItem("BookingData"));
    const searchedData = JSON.parse(localStorage.getItem("searchedData"));
    const hotelDetail = JSON.parse(localStorage.getItem("hotelDetail"));
    const totalrooms = JSON.parse(localStorage.getItem("totalrooms"));
    const [users, setUsers] = useState([]);
    const [isHolder, setIsHolder] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [isGuest, setIsGuest] = useState(false);
    const [currIndex, setCurrIndex] = useState(0);
    const [guestError, setGuestError] = useState(null);

    // console.log(bookingData);

    //const occupancy = bookingData ? bookingData?.HotelList[0]?.RatePlanList[0]?.MaxOccupancy : 3;
    const occupancy = totalrooms;

    useEffect(() => {
        if (holderDetail) {
            setIsAdd(true);
        }

        if (guestLists) {
            setUsers(guestLists);
        }
    }, []);

    const handleRemove = (index) => {
        const allusers = users.filter((item) => item?.guestindex !== index);
        console.log(allusers);
        localStorage.setItem("guestlists", JSON.stringify(allusers));
        setUsers(allusers);
    };
    // console.log(guestLists);

    const handleCheck = () => { 
        let checkpersons = users.length + 1;
        if (checkpersons == occupancy) {
            toast({ title: 'Maximum persons selected', status: "error", duration: 2000, position: "top-right", isClosable: true, });
            return;
        }

        if (checkpersons !== occupancy) {
            setIsGuest(true);
        }
        // if (isAdd === false) return;
        // setIsGuest(true);
    };

    const handleBooking = async () => {
        if (holderDetail === null) {
            toast({ title: 'Please fill the details to proceed!.', status: "error", duration: 2000, position: "top-right", isClosable: true, });
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }

        if (holderDetail !== null && occupancy > 1 && users.length === 0) {
            setGuestError(`${occupancy - 1} more person${(occupancy - 1) > 1 ?'s':''} needed to add!`);
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }

        if (holderDetail !== null && occupancy === 1 && users.length === 0) {
            navigate('/hotel-booking/card');
        }

        if (holderDetail !== null && occupancy > 1 && users.length+1 === occupancy) {
            navigate('/hotel-booking/card');
        }
    };

    const formatDate = (datestring) => { 
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const newDate = new Date(datestring);
        const date = newDate.getDate();
        const month = months[newDate.getMonth()];
        const year = newDate.getUTCFullYear();

        return `${date} ${month} ${year}`;
    };

    // console.log(holderDetail);

    useEffect(() => { 
        if (occupancy > 1 && users?.length + 1 === occupancy) {
            setGuestError(null);
        }
    }, [users]);

    return (
        <div>
            <div className='payment-details-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-7 col-sm-12'>
                            <div className='left-fill-details'>
                                {/* <h2 className='mb-3'>Fill your details</h2>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-sm-12 form-group form-spacing'>
                                        <label class="form-label p-md text-grey-light">First name</label>
                                        <input type="text" className='form-control' placeholder='Enter first name' />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 form-group form-spacing'>
                                        <label class="form-label p-md text-grey-light">Last name</label>
                                        <input type="text" className='form-control' placeholder='Enter last name' />
                                    </div>
                                </div>
                                <div className='form-group form-spacing'>
                                    <label class="form-label p-md text-grey-light">Email</label>
                                    <input type="email" className='form-control' placeholder='Enter your email' />
                                </div>
                                <div className='form-group form-spacing'>
                                    <label class="form-label p-md text-grey-light">Phone</label>
                                    <input type="tel" className='form-control' placeholder='Enter your phone' />
                                </div>

                                <h4>Address</h4>
                                <div className='form-group form-spacing'>
                                    <label class="form-label p-md text-grey-light">House no./Flat no.</label>
                                    <input type="text" className='form-control' placeholder='Enter detail' />
                                </div>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-sm-12 form-group form-spacing'>
                                        <label class="form-label p-md text-grey-light">State</label>
                                        <input type="text" className='form-control' placeholder='Enter state' />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 form-group form-spacing'>
                                        <label class="form-label p-md text-grey-light">City</label>
                                        <input type="text" className='form-control' placeholder='Enter city' />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 form-group form-spacing'>
                                        <label class="form-label p-md text-grey-light">Pin code</label>
                                        <input type="text" className='form-control' placeholder='Enter code' />
                                    </div>
                                </div> */}

                                <div className='travel-payment-wrap border-solid-d'>
                                    <h2 className='mb-3'>Person Details</h2>
                                    <div className='d-flex align-items-center justify-content-between gap-travel-between'>
                                        <div className='d-flex align-items-center align-start-top gap-3'>
                                            <div className='travel-top-img'>
                                                <img src={UserSqImg} className='' />
                                            </div>
                                            <div className=''>
                                                <h3 className='font-family-poppins mb-0'>{holderDetail !== null ? holderDetail?.Name : 'Person 1'}</h3>
                                                <p className='p-md mb-0'>
                                                    {holderDetail !== null ? `Age - ${holderDetail?.Age} years` : '*Adult - Should be above 18 years'}
                                                    {/* *Adult - Should be above 18 years */}
                                                </p>
                                            </div>
                                        </div>
                                        <span className='btn-gradient-purple w-auto p-md cursor-pointer'
                                            onClick={() => {
                                                if (isAdd === true) return;
                                                setIsHolder(true);
                                            }}
                                        >
                                            {isAdd ? "Added" : "Add"}
                                        </span>
                                    </div>

                                    {guestError && <p className='text-danger'>{guestError}</p>}

                                    {occupancy > 1 && occupancy !== users?.length + 1 && <span className={isAdd === false ? "disabled-btn d-inline-block w-auto mt-3" : "ylw-theme-button d-inline-block mt-3 cursor-pointer"}
                                        onClick={() => {
                                            if (isAdd === false) return;
                                            handleCheck();
                                        }}
                                    >
                                        <i class="bi bi-plus"></i> Add Person
                                    </span>}

                                    {users?.map((item, index) => (
                                        <div key={index}>
                                            <hr className='border-dotted' />
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='d-flex align-items-center align-start-top gap-3'>
                                                    <div className='travel-top-img '>
                                                        <img src={UserSqImg} className='' />
                                                    </div>
                                                    <div className=''>
                                                        <h3 className='font-family-poppins mb-0'>{item?.Name} {item?.Surname}</h3>
                                                        <p className='p-md mb-0'>
                                                            {item?.Age ? `Age - ${item?.Age} years` : '*Adult - Should be above 18 years'}
                                                            {/* *Adult - Should be above 18 years */}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='d-flex gap-1'>
                                                    <span className='btn-gradient-purple w-auto p-md cursor-pointer'
                                                    // onClick={() => {
                                                    //     setCurrIndex(index + 2);
                                                    //     setIsGuest(true);
                                                    // }}
                                                    >
                                                        Added
                                                        {/* {(guestLists !== null && guestLists?.find((item) => item?.guestindex === index + 2)) ? 'Added' : 'Add'} */}
                                                    </span>
                                                    <span className='btn-gradient-purple w-auto p-md cursor-pointer' onClick={() => handleRemove(item?.guestindex)}>Remove</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    {/* <hr className='border-dotted' /> */}

                                    {/* <div className='d-flex align-items-center justify-content-between'>
                                        <div className='d-flex align-items-center align-start-top gap-3'>
                                            <div className='travel-top-img '>
                                                <img src={UserSqImg} className='' />
                                            </div>
                                            <div className=''>
                                                <h3 className='font-family-poppins mb-0'>Person 2</h3>
                                                <p className='p-md mb-0'>*Adult - Should be above 18 years</p>
                                            </div>
                                        </div>
                                        <a href="" className='btn-gradient-purple w-auto p-md' data-bs-toggle="modal" data-bs-target="#add-person-second-popup">Add</a>
                                    </div> */}

                                    {/* {[...Array(occupancy)].map((_, index) => (
                                        <div key={index}>
                                            <hr className='border-dotted' />
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='d-flex align-items-center align-start-top gap-3'>
                                                    <div className='travel-top-img '>
                                                        <img src={UserSqImg} className='' />
                                                    </div>
                                                    <div className=''>
                                                        <h3 className='font-family-poppins mb-0'>Person {index + 2}</h3>
                                                        <p className='p-md mb-0'>*Adult - Should be above 18 years</p>
                                                    </div>
                                                </div>
                                                <span className='btn-gradient-purple w-auto p-md cursor-pointer'
                                                    onClick={() => {
                                                        setCurrIndex(index + 2);
                                                        setIsGuest(true);
                                                    }}
                                                >
                                                    {(guestLists !== null && guestLists?.find((item) => item?.guestindex === index + 2)) ? 'Added' : 'Add'}
                                                </span>
                                            </div>
                                        </div>
                                    ))} */}

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-5 col-sm-12'>
                            <div className='ryt-room-select-price'>
                                <img src={SelectRoom} alt='' className='img-fluid w-100 top-selct-img' />
                                <h5 className='hd-spacing-room-selct'>
                                    {hotelDetail ? hotelDetail[0]?.Name : 'Movenpick Hotel And Residences Riyadh'}
                                    {/* Movenpick Hotel And Residences Riyadh */}
                                </h5>
                                <div class="reviews-box d-flex align-items-baseline mb-2 gap-2">
                                    <p className='d-inline-block p-md mb-0'>
                                    {hotelDetail && hotelDetail[0]?.StarRating ? hotelDetail[0]?.StarRating : '4.3'}
                                        {/* 4.3 */}
                                    </p>
                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li><li class="d-inline-block"><i class="fas fa-star"></i></li>
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                    </ul>
                                    <p className='d-inline-block p-md mb-0'>(144 reviews)</p>
                                </div>
                                <p className='p-lg mb-2 font-size-15 font-weight-500 d-flex align-items-center gap-2'>
                                    <img src={MapImg} className='d-inline-block' alt='' />
                                    {searchedData ? searchedData?.CityName : 'Diplomatic Enclave Chanakyapuri'}
                                </p>

                                <p className='p-lg mb-2 font-size-15 font-weight-500 d-flex align-items-center gap-2'>
                                    <img src={UserImg} className='d-inline-block ' alt='' />
                                    2 Persons
                                </p>

                                <p className='p-lgmb-2 font-size-15 font-weight-500 d-flex align-items-center gap-2'><img src={CalenderImg} className='d-inline-block' alt='' />
                                    {searchedData?.CheckInDate ? formatDate(searchedData?.CheckInDate) : '23 Oct 2024'}
                                    <span className='arrow-right-icon-wrap'>
                                        <i className="fas fa-arrow-right mx-2"></i>
                                    </span>
                                    {searchedData?.CheckOutDate ? formatDate(searchedData?.CheckOutDate) : '24 Oct 2024'}
                                </p>

                                <div className='price-box-room '>
                                    <h5 className='text-purple p-large mb-0'>Price</h5>
                                    <div className='price-line-dotted align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0'>Regular Room - Queen Bed</p>
                                            <p className='p-sm mb-0'>1 Room</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                        </div>
                                    </div>

                                    <div className='price-line-dotted align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0'>Regular Room - Queen Bed</p>
                                            <p className='p-sm mb-0'>1 Room</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                        </div>
                                    </div>

                                    <div className='price-line-dotted price-line-plane align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0'>Regular Room - Queen Bed</p>
                                            <p className='p-sm mb-0'>1 Room</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                        </div>
                                    </div>

                                    <div className='price-line-dotted align-items-center mb-3 border-0 last-font-line'>
                                        <div className=''>
                                            <p className='size-big mb-0 font-weight-600 text-color-theme'>Total(incl.VAT)</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme size-big font-weight-600'>$137</p>
                                        </div>
                                    </div>

                                    <button type="submit" className='btn-gradient-purple text-white'
                                        onClick={() => {
                                            // navigate('/hotel-booking/card');
                                            handleBooking();
                                        }}
                                    >
                                        Book Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ====== Add-Person-One-Popup-Start ======== */}
            <div class="add-travel-modal modal fade" id="add-person-one-popup" tabindex="-1" aria-labelledby="add-person-one-popupLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header border-0 cross-icon p-0 align-items-center">
                            <div className='container-fluid travel-detail-header d-flex align-items-center'>
                                <h2 class="modal-title mb-0 lh-1" id="slidermodalLabel">Add Person 1 Details</h2>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                        <div class="modal-body border-0 modal-slider-view p-0">
                            <form className='left-fill-details'>
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'>First Name</label>
                                                <input type="text" className='form-control' placeholder='Enter your first name' />
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'>Last Name</label>
                                                <input type="text" className='form-control' placeholder='Enter your first name' />
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'>Gender</label>
                                                <select className='text-grey-light w-100 form-select form-control'>
                                                    <option selected>Selct Gender</option>
                                                    <option>Female</option>
                                                    <option>Male</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'>Age</label>
                                                <select className='text-grey-light w-100 form-select form-control'>
                                                    <option selected>Select age</option>
                                                    <option>Female</option>
                                                    <option>Male</option>
                                                </select>
                                            </div>
                                        </div>


                                        <div className='col-md-4'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'>Phone Number</label>
                                                <select className='text-grey-light w-100 form-select form-control'>
                                                    <option selected>+91</option>
                                                    <option>Female</option>
                                                    <option>Male</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'></label>
                                                <input type="number" className='form-control' placeholder='Enter your phone number' />
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'>Email</label>
                                                <input type="email" className='form-control' placeholder='Enter your email' />
                                            </div>
                                        </div>

                                        <div className='col-md-4'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'>Document</label>
                                                <select className='text-grey-light w-100 form-select form-control'>
                                                    <option selected>Select</option>
                                                    <option>Female</option>
                                                    <option>Male</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'></label>
                                                <input type="number" className='form-control' placeholder='Document number' />
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'>Address</label>
                                                <input type="text" className='form-control' placeholder='House/street no' />
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'>City</label>
                                                <input type="text" className='form-control' placeholder='Enter your city' />
                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'>Country</label>
                                                <input type="text" className='form-control' placeholder='Enter your country' />
                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'>Postal Code</label>
                                                <input type="number" className='form-control' placeholder='Enter code' />
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'>Nationality</label>
                                                <input type="text" className='form-control' placeholder='Enter your nationality' />
                                            </div>
                                        </div>


                                        {/* <div className='col-md-12'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'>Date of  Birth</label>
                                                <div className='d-grid d-grid-calender w-100'>
                                                    <select className='text-grey-light w-100 form-select form-control'>
                                                        <option selected>DD</option>
                                                        <option>DD</option>
                                                        <option>DD</option>
                                                    </select>
                                                    <select className='text-grey-light w-100 form-select form-control'>
                                                        <option selected>MM</option>
                                                        <option>MM</option>
                                                        <option>MM</option>
                                                    </select>
                                                    <select className='text-grey-light w-100 form-select form-control'>
                                                        <option selected>YYYY</option>
                                                        <option>YYYY</option>
                                                        <option>YYYY</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>



                                    <button className='btn-gradient-purple' type='submit'>Add Person</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* ====== End ======== */}


            {/* ====== Add-Person-Second-Popup-Start ======== */}
            <div class="add-travel-modal modal fade" id="add-person-second-popup" tabindex="-1" aria-labelledby="add-person-second-popupLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header border-0 cross-icon p-0 align-items-center">
                            <div className='container-fluid travel-detail-header d-flex align-items-center'>
                                <h2 class="modal-title mb-0 lh-1" id="slidermodalLabel">Add Person 2 Details</h2>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                        <div class="modal-body border-0 modal-slider-view p-0">
                            <form className='left-fill-details'>
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'>First Name</label>
                                                <input type="text" className='form-control' placeholder='Enter your first name' />
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'>Last Name</label>
                                                <input type="text" className='form-control' placeholder='Enter your first name' />
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'>Gender</label>
                                                <select className='text-grey-light w-100 form-select form-control'>
                                                    <option selected>Selct Gender</option>
                                                    <option>Female</option>
                                                    <option>Male</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'>Age</label>
                                                <select className='text-grey-light w-100 form-select form-control'>
                                                    <option selected>Select age</option>
                                                    <option>Female</option>
                                                    <option>Male</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'>Email</label>
                                                <input type="email" className='form-control' placeholder='Enter your email' />
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <div className='form-group form-spacing'>
                                                <label className='form-label p-lg text-grey-light'>Nationality</label>
                                                <input type="text" className='form-control' placeholder='Enter your nationality' />
                                            </div>
                                        </div>
                                    </div>
                                    <button className='btn-gradient-purple' type='submit'>Add Person</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* ====== End ======== */}
            <HolderInfo show={isHolder} handleClose={() => setIsHolder(false)} ISAdd={() => setIsAdd(true)} />
            <GuestsInfo show={isGuest} handleClose={() => setIsGuest(false)} currIndex={users.length + 2} setUsers={setUsers} />
        </div >
    )
}

export default HotelBooking