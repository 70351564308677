import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { get_vender_id } from '../../reduxStore/User/userActions';
import { get_all_staff_members } from '../../reduxStore/vendors/vendorActions';
import AddStaff from '../../Modals/AddStaff';

const Staff = ({ user, staff_members, vendor_id, total_staff }) => {
    const [isShow, setIsShow] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    useEffect(() => {
        if (vendor_id) {
            get_all_staff_members(user?.token, dispatch, vendor_id);
        }
    }, [vendor_id]);

    // console.log(staff_members);

    const getCountryName = (code) => {
        const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
        const countryName = regionNames.of(code);
        return countryName;
    };

    return (
        <div>
            <div className='container-fluid px-0 mb-4'>
                <div className='table-header-wrapper'>
                    <h5 className='text-white mb-0'>Staff Members</h5>
                    <button className='btn-gradientlg-purple w-auto' onClick={() => setIsShow(true)}>+ Add New</button>
                </div>
            </div>
            <div className='upcoming-list-table-wrapper'>
                <Table>
                    <thead>
                       <th>Sr. No.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Address</th>
                        <th>Phone No.</th>
                        <th scope="col">Country</th>
                    </thead>
                    <tbody>
                        {staff_members.map((item, index) => (
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{item?.name}</td>
                                <td>{item?.email}</td>
                                <td>{item?.address}</td>
                                <td>({item?.country_code}) {item?.phone_number}</td>
                                <td>{item?.country_code_name ? getCountryName(item?.country_code_name) : 'N/A'}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <AddStaff show={isShow} handleClose={() => setIsShow(false)} isStaff={true} />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id,
        staff_members: state.vendor.staff_members,
        total_staff: state.vendor.total_staff,
    }
}
export default connect(mapStateToProps)(Staff);