import React, { useEffect, useState } from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { get_vender_id, logout, user_token_security_logout } from '../../reduxStore/User/userActions';
import { useToast } from '@chakra-ui/react';
import { BsUiChecksGrid, BsJournalBookmarkFill } from "react-icons/bs";
import adminProfile from '../../assets/admin-profile-img.svg';
import { FaRegUser } from "react-icons/fa";
import { CiSettings, CiHome, CiLogout } from "react-icons/ci";

const VendorSideBar = ({ user, vendor_id }) => {
    const [open, setOpen] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const toast = useToast();

    const handleLogout = async () => {
        let user_logout = await user_token_security_logout(vendor_id, user?.token, toast, dispatch);
        if (user_logout) {
            localStorage.removeItem('userData');
            localStorage.clear();
            dispatch(logout());
            navigate('/');
        }
    };

    const items = [
        {
            name: 'Dashboard',
            value: '/',
            icon: BsUiChecksGrid,
            subitem: []
        },
        {
            name: 'Customers',
            value: '/users',
            icon: BsJournalBookmarkFill,
            subitem: []
        },
        {
            name: 'Agencies',
            value: '/agencies',
            icon: CiHome,
            subitem: []
        },
        {
            name: 'Profile',
            value: '/profile',
            icon: FaRegUser,
            subitem: []
        },
        {
            name: 'Settings',
            value: '',
            icon: CiSettings,
            subitem: [
                { name: 'Languages', value: '/languages', icon: '' },
            ]
        }
    ];

    const agencyitems = [
        { name: 'Dashboard', value: '/', icon: '', subitem: [] },
        { name: 'Staff', value: '/staff', subitem: [] },
        { name: 'Profile', value: '/profile', icon: '', subitem: [] },
        { name: 'Change Password', value: '/change-password', subitem: [] }
    ];

    const staffItems = [
        { name: 'Dashboard', value: '/', icon: '', subitem: [] },
        { name: 'Profile', value: '/profile', icon: '', subitem: [] },
        { name: 'Change Password', value: '/change-password', subitem: [] }
    ];

    const [menuItems, setMenuItems] = useState(user.role === "superadmin" ? items : user.role === "staff" ? staffItems : agencyitems);

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    return (
        <div className='admin-left-sidebar-wrap'>
            <Sidebar>

                {/* <div className='admin-profile-wrap'>
                    <div className='light-border-circle-wrapper'>
                        <div className='admin-profile-img-wrap'>
                            <img src={adminProfile} alt='' />
                        </div>
                    </div>
                </div>

                <div className='admin-profile-detail-wrap'>
                    <div className='admin-profile-name mb-3'>
                        <h5 className='text-white mb-0'>Manpreet Singh</h5>
                    </div>
                    <div className='admin-user-personal-detail-wrap'>
                        <ul className='ps-0 p-md d-flex flex-column gap-2'>
                            <li className='d-flex align-items-center gap-2'>
                                <i class="bi bi-envelope"></i>
                                manpreet...1995@gmail.com
                            </li>
                            <li className='d-flex align-items-center gap-2'>
                                <i class="bi bi-telephone"></i>
                                +91 1234567890
                            </li>
                            <li className='d-flex align-items-center gap-2'>
                                <i class="bi bi-credit-card"></i>
                                **** **** **** 3321
                            </li>
                        </ul>
                    </div>
                </div> */}

                <Menu className="custom-admin-nav-tabs-wrap">
                    {menuItems.map((item) => (
                        item.subitem.length === 0 ? (
                            <MenuItem
                                key={item.name}
                                className={item?.value === location.pathname ? "is-active" : "not-active"}
                                component={<Link to={item?.value} />}
                            >
                                {item.icon && <item.icon />} {/* Render icon if it exists */}
                                {item.name}
                            </MenuItem>
                        ) : (
                            <SubMenu
                                key={item.name}
                                label={
                                    <>
                                        {item.icon && <item.icon />} {/* Render icon in SubMenu label */}
                                        {item.name}
                                    </>
                                }
                                open={
                                    item.subitem.some((subItem) => subItem.value === location.pathname) || open === item.value
                                }
                                className={item?.value === location.pathname ? "is-active" : "not-active"}
                                onOpenChange={(isOpen) => setOpen(isOpen ? item.value : null)}
                            >
                                {item.subitem.map((subItem) => (
                                    <MenuItem
                                        key={subItem.name}
                                        className={subItem?.value === location.pathname ? "is-active" : "not-active"}
                                        component={<Link to={subItem?.value} />}
                                    >
                                        {subItem.icon && <subItem.icon />} {/* Render icon if it exists */}
                                        {subItem.name}
                                    </MenuItem>
                                ))}
                            </SubMenu>
                        )
                    ))}
                    <MenuItem onClick={handleLogout}><CiLogout /> Logout</MenuItem>
                </Menu>
            </Sidebar>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id,
    }
}
export default connect(mapStateToProps)(VendorSideBar);