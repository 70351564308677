import './App.css';
import { Navigate, useLocation, useParams, useRoutes } from 'react-router-dom';
import Header from './components/Header';
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";
import Results from './components/Results';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import AuthPages from './auth/AuthPages';
import Login from './auth/Login';
import Signup from './auth/Signup';
import { connect, useDispatch, useSelector } from 'react-redux';
import ForgotPassword from './auth/ForgotPassword';
import HotelDetail from './components/Details/HotelDetail';
import EnterEmailorPhone from './auth/EnterEmailorPhone';
import VerifyOtp from './auth/VerifyOtp';
import VendorSideBar from './Vendors/Components/VendorSideBar';
import UsersList from './Vendors/Components/UsersList';
import Dashboard from './Vendors/Components/Dashboard';
import HotelBooking from './components/Hotels/HotelBooking';
import AdminProfile from './Vendors/Components/AdminProfile';
import CardDetails from './components/Hotels/CardDetails';
import BookingConfirm from './components/Hotels/BookingConfirm';
import VendorLogin from './auth/VendorLogin';
import BookingFilter from './components/BookingFilter';
import HotelsResult from './components/SearchResult/HotelsResult';
import LocalTourResult from './components/SearchResult/LocalTourResult';
import FlightResult from './components/SearchResult/FlightResult';
import CarResult from './components/SearchResult/CarResult';
import ResetPassword from './auth/ResetPassword';
import UserProfile from './components/UserPages/UserProfile';
import ChangePassword from './components/UserPages/ChangePassword';
import TourDetail from './components/Tours/TourDetail';
import TourPayment from './components/Tours/TourPayment';
import AddTraveller from './components/Tours/AddTraveller';
import VendorProfile from './Vendors/Components/VendorProfile';
import RoundFlight from './components/SearchResult/RoundFlight';
import { useEffect, useState } from 'react';
import Staff from './Vendors/Agencies/Staff';
import VendorDashboard from './Vendors/Components/VendorDashboard';
import StaffDashboard from './Vendors/Components/StaffDashboard';
// import LangHome from './Vendors/Languages/LangHome';
import LanguagesList from './Vendors/Languages/LanguagesList';
import { useTranslation } from 'react-i18next';
import timeZonesData from '../src/locales/timeZones.json';
import LoadingSpinner from './LoadingSpinner';
import SendEmail from './auth/SendEmail';
import ContactDetails from './components/Cars/ContactDetails';
import FlightPayment from './components/Flights/FlightPayment';
import { get_default_language_currency } from './reduxStore/User/userActions';
// import { ProSidebarProvider } from 'react-pro-sidebar';
import SideBar from './shared/SideBar';
import Verified from './auth/Verified';
import UserHome from './components/DashBoardPages/UserHome';
import MyBooking from './components/DashBoardPages/MyBooking';
import Referral from './components/DashBoardPages/Referral';
import Support from './components/DashBoardPages/Support';
import Agency from './Vendors/Agencies/Agency';
import AgenciesList from './Vendors/Agencies/AgenciesList';

function App({ user, defaultData }) {
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const isSwitch = JSON.parse(localStorage.getItem("isSwitch"));
  const [width, setWidth] = useState(window.innerWidth);
  const [adminHeadToggle, setAdminHeadToggle] = useState(false);
  const location = useLocation();
  const { i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   let lang_type = JSON.parse(localStorage.getItem("LangType"));

  //   if (isSwitch) {
  //     if (lang_type !== "en") {
  //       i18n.changeLanguage('ar');
  //     } else {
  //       localStorage.setItem("LangType", JSON.stringify('en'));
  //       i18n.changeLanguage('en');
  //     }
  //   }

  // }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };
  const isMobile = width <= 917;

  const VendorSideBar = () => {
    return (
      // <ProSidebarProvider>
      //   <VendorSideBar />
      //  </ProSidebarProvider>
      <h1>hhshd-dfdfdfd</h1>
    )
  };

  const BeforeLoginRoutes = () => useRoutes([
    { path: '/', element: <HomePage /> },
    // { path: '/auth', element: <AuthPages isMobile={isMobile} /> },
    { path: '/login', element: <AuthPages isMobile={isMobile} /> },
    { path: '/admin', element: <VendorLogin /> },
    { path: '/staff', element: <VendorLogin /> },
    { path: '/signup', element: <AuthPages isMobile={isMobile} /> },
    { path: '/reset-password/*', element: <ResetPassword /> },
    { path: '/forgot-password', element: <ForgotPassword /> },
    { path: '/verified', element: <Verified /> },
    { path: '/password-reset-email', element: <SendEmail /> },
    { path: '/flight-payment', element: <FlightPayment /> },
    // { path: '/user-verify', element: <EnterPhoneNo /> },
    // { path: '/otp-verify', element: <VerifyOtp /> },
    // { path: '/admin-verify', element: <EnterPhoneNo /> },
    // { path: '/admin-otp-verify', element: <VerifyOtp /> },
    { path: '/detail/:id', element: <HotelDetail /> },
    { path: '/tour-detail/:id', element: <HotelDetail /> },
    { path: '/tour/card', element: <CardDetails /> },
    { path: '/hotel-booking/payment', element: <HotelBooking /> },
    { path: '/hotel-booking/card', element: <CardDetails /> },
    { path: '/cars/card-detail', element: <CardDetails /> },
    { path: '/hotel-booking/confirm', element: <BookingConfirm /> },
    { path: '/search-results', element: <Results /> },
    { path: '/hotel-results', element: <HotelsResult /> },
    { path: '/local-tours-results', element: <LocalTourResult /> },
    // { path: '/tour-detail', element: <TourDetail /> },
    { path: '/tour-payment', element: <TourPayment /> },
    { path: '/add-traveller', element: <AddTraveller /> },
    { path: '/flight-results', element: <FlightResult /> },
    { path: '/round-trip-results', element: <RoundFlight /> },
    { path: '/car-results', element: <CarResult /> },
    { path: '/contact-details', element: <ContactDetails /> },
    { path: '*', element: <Navigate to="/" replace /> }
  ]);

  const AfterLoginRoutes = () => useRoutes([
    { path: '/', element: <HomePage /> },
    { path: '/profile', element: <UserProfile /> },
    // { path: '/change-password', element: <ChangePassword /> },
    { path: '/detail/:id', element: <HotelDetail /> },
    { path: '/tour/card', element: <CardDetails /> },
    { path: '/tour-detail/:id', element: <HotelDetail /> },
    { path: '/hotel-booking/payment', element: <HotelBooking /> },
    { path: '/hotel-booking/card', element: <CardDetails /> },
    { path: '/cars/card-detail', element: <CardDetails /> },
    { path: '/hotel-booking/confirm', element: <BookingConfirm /> },
    { path: '/search-results', element: <Results /> },
    { path: '/hotel-results', element: <HotelsResult /> },
    { path: '/local-tours-results', element: <LocalTourResult /> },
    // { path: '/tour-detail', element: <TourDetail /> },
    { path: '/tour-payment', element: <TourPayment /> },
    { path: '/add-traveller', element: <AddTraveller /> },
    { path: '/flight-results', element: <FlightResult /> },
    { path: '/round-trip-results', element: <RoundFlight /> },
    { path: '/car-results', element: <CarResult /> },
    { path: '/contact-details', element: <ContactDetails /> },
    // { path: '/dashboard', element: <SideBar /> },
    { path: '/dashboard', element: <UserHome /> },
    { path: '/dashboard/bookings', element: <MyBooking /> },
    { path: '/dashboard/referrals', element: <Referral /> },
    { path: '/dashboard/support', element: <Support /> },
    { path: '/dashboard/change-password', element: <ChangePassword /> },
    // { path: '/dashboard/:pages', element: <UserSideBar /> },
    { path: '*', element: <Navigate to="/" replace /> }
  ]);

  const AdminRoutes = () => useRoutes([
    { path: '/', element: <Dashboard /> },
    { path: '/users', element: <UsersList /> },
    { path: '/agencies', element: <AgenciesList /> },
    { path: '/profile', element: <AdminProfile /> },
    { path: '/languages', element: <LanguagesList /> },
    { path: '/change-password', element: <ChangePassword /> },
    { path: '/create-agency', element: <Agency /> },
    { path: '/edit-agency', element: <Agency /> },
    { path: '*', element: <Navigate to="/" replace /> }
  ]);

  const VendorAgenciesRoutes = () => useRoutes([
    { path: '/', element: <VendorDashboard /> },
    { path: '/staff', element: <Staff /> },
    { path: '/profile', element: <VendorProfile /> },
    { path: '/change-password', element: <ChangePassword /> },
    { path: '*', element: <Navigate to="/" replace /> }
  ]);

  const StaffRoutes = () => useRoutes([
    { path: '/', element: <StaffDashboard /> },
    { path: '/profile', element: <VendorProfile /> },
    { path: '/change-password', element: <ChangePassword /> },
    { path: '*', element: <Navigate to="/" replace /> }
  ]);

  const paths = [
    "/signup",
    "/login",
    "/detail",
    `/detail/${id}`,
    "/tour-detail",
    "/user-verify",
    "/otp-verify",
    "/admin",
    "/admin-verify",
    "/forgot-password",
    "/reset-password",
    "/profile",
    "/change-password",
    "/staff",
    "/hotel-booking/card",
    "/password-reset-email",
    "/tour-payment",
    "/add-traveller",
    "/tour/card",
    "/contact-details",
    "/cars/card-detail",
    "/flight-payment"
  ];

  const filterpaths = [
    '/',
    '/hotel-results',
    '/local-tours-results',
    '/flight-results',
    '/round-trip-results',
    '/car-results'
  ];

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.addEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {

    if (!user) {
      document.body.className = "";
    }

    if (user && user?.role === "superadmin") {
      document.body.className = "admin_pannel";
    }

    if (user && user?.role === "user" && location.pathname.includes('/dashboard')) {
      // Set the body class
      document.body.className = "admin_pannel";

      // Get the header-container element
      const headerContainer = document.querySelector('.header-container');

      // Modify its classes
      if (headerContainer) {
        headerContainer.classList.remove('container');
        headerContainer.classList.add('container-fluid');
      }
    }

    if (user && user?.role === "user" && !location.pathname.includes('/dashboard')) {
      document.body.className = "";
    }

    // if (user && user?.role === "user" && location.pathname.includes('/dashboard') && adminHeadToggle === true) {
    //   document.body.className = "";
    // }

    // if (user && user?.role === "user" && !location.pathname.includes('/dashboard') && (adminHeadToggle === true || adminHeadToggle === false)) {
    //   document.body.className = "";
    // }
  }, [user, location.pathname]);

  useEffect(() => {
    if (user?.role === "superadmin") {
      const headerContainer = document.querySelector(".header-container");
      if (headerContainer) {
        headerContainer.classList.add("container-xxl");
        headerContainer.classList.remove("container");
      }
    }
  }, [user]);



  useEffect(() => {
    const handleData = async () => {
      let currlang = await get_default_language_currency(user?.token, dispatch);
      if (currlang) {
        localStorage.setItem("DefaultLang", JSON.stringify(currlang?.defaultLanguage?.language === "Arabic" ? "ar" : "en"));

        if (isSwitch) return;

        const checktime = timeZonesData[currentTimeZone];
        i18n.changeLanguage(
          checktime === undefined && currlang?.defaultLanguage?.language === "English" ? 'en' :
            checktime === undefined && currlang?.defaultLanguage?.language === "Arabic" ? 'ar' :
              checktime?.languageCode
        );
        localStorage.setItem(
          "LangType",
          JSON.stringify(checktime === undefined ? (currlang?.defaultLanguage?.language === "Arabic" ? 'ar' : 'en') : checktime?.languageCode)
        );
      }
    };
    handleData();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <>
      <LoadingSpinner />
      {(location?.pathname !== "/admin") && <Header isMobile={isMobile} adminHeadToggle={adminHeadToggle} setAdminHeadToggle={setAdminHeadToggle} />}
      {((!user || user.role === "user") && filterpaths.includes(location.pathname)) && <BookingFilter />}
      {user && user.role === "superadmin" ?
        <>
          <div className="d-flex px-0" style={{ backgroundColor: "#F6E4FF" }}>
            {/* <VendorSideBar /> */}
            <SideBar isMobile={isMobile} adminHeadToggle={adminHeadToggle} setAdminHeadToggle={setAdminHeadToggle} />
            <div className="d-none home-page w-100 admin-right-sidebar-wrapper">
              <div className='container-fluid'>
                <AdminRoutes />
              </div>
            </div>
          </div>
        </>
        : user && (user.role === "smallAgency" || user.role === "mediumAgency") ?
          <>
            <div className="d-flex">
              <SideBar />
              <div className="d-none home-page w-100">
                <div className='container-fluid'>
                  <VendorAgenciesRoutes />
                </div>
              </div>
            </div>
          </>
          : user && user.role === "staff" ?
            <>
              <div className="d-flex">
                <SideBar />
                <div className="d-none home-page w-100">
                  <div className='container-fluid'>
                    <StaffRoutes />
                  </div>
                </div>
              </div>
            </>
            : user && user.role === "user" ?
              <>
                {location.pathname.includes('dashboard') &&
                  <div className="d-flex px-0" style={{ backgroundColor: "#F6E4FF" }}>
                    <SideBar isMobile={isMobile} adminHeadToggle={adminHeadToggle} setAdminHeadToggle={setAdminHeadToggle} />
                  </div>}
                <div className=''>
                  <div className='container-fluid'>
                    {!location.pathname.includes('dashboard') && <AfterLoginRoutes />}
                  </div>
                </div>
              </>

              : <BeforeLoginRoutes />
      }
      {((location?.pathname !== "/admin") && (!user || user?.role === "user") && (!location.pathname.includes('/dashboard'))) && <Footer />}
    </>
  );
}



const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    defaultData: state.auth.defaultData
  }
}
export default connect(mapStateToProps)(App);
