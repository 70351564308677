import React from 'react'
import GrayFlightIcon from './../../assets/gray-flight-icon.svg';
import GrayLocalTourIcon from './../../assets/gray-local-tour-icon.svg';
import GrayCarIcon from './../../assets/gray-car-icon.svg';
import GreyHotelIcon from './../../assets/grey-hotel-icon.svg';


const MyBooking = () => {
    return (
        <div>
            <h1>My Booking</h1>
            <div className='table-header-wrapper'>
                <h5 className='text-white mb-0'>Booking Listing</h5>
                <a href='#' className='text-white text-decoration-underline'>View All</a>
            </div>
            {/* =====End===== */}


            {/* ====table-start=== */}
            <div className='upcoming-list-table-wrapper'>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Type</th>
                            <th scope="col">Name</th>
                            <th scope="col">Reviews</th>
                            <th scope="col">Order Date</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>
                                <div className='d-flex align-items-center gap-1'>
                                    <img src={GrayFlightIcon} alt='GrayFlightIcon' />
                                    Flight
                                </div>
                            </td>
                            <td>
                                <div className='table-col-name-td'>
                                    Manpreet Singh
                                </div>
                            </td>
                            <td>
                                <div className='add-review-disable'>
                                    + Add Reviews
                                </div>
                            </td>
                            <td>10 Nov 2024</td>
                            <td>
                                <div className='table-amount-col-data'>
                                    $1899.00
                                </div>
                            </td>
                            <td>
                                <div className='status-label'>
                                    <span className='processing-label'>Processing</span>
                                </div>
                            </td>
                            <td>
                                <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                    <a href='#' className='eye-icon-wrap text-decoration-none'>
                                        <i class="bi bi-eye"></i>
                                    </a>
                                    <a href='#' className='download-icon-wrap text-decoration-none'>
                                        <i class="bi bi-download"></i>
                                    </a>
                                    <a href='#' className='cross-icon-wrap text-decoration-none'>
                                        <i class="bi bi-x"></i>
                                    </a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <th scope="row">2</th>
                            <td>
                                <div className='d-flex align-items-center gap-1'>
                                    <img src={GrayLocalTourIcon} alt='GrayLocalTourIcon' />
                                    Local Tour
                                </div>
                            </td>
                            <td>
                                <div className='table-col-name-td'>
                                    Vikas Kumar
                                </div>
                            </td>
                            <td>
                                <div className='add-review-disable'>
                                    + Add Reviews
                                </div>
                            </td>
                            <td>09 Nov 2024</td>
                            <td>
                                <div className='table-amount-col-data'>
                                    $3063.53
                                </div>
                            </td>
                            <td>
                                <div className='status-label'>
                                    <span className='processing-label'>Processing</span>
                                </div>
                            </td>
                            <td>
                                <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                    <a href='#' className='eye-icon-wrap text-decoration-none'>
                                        <i class="bi bi-eye"></i>
                                    </a>
                                    <a href='#' className='download-icon-wrap text-decoration-none'>
                                        <i class="bi bi-download"></i>
                                    </a>
                                    <a href='#' className='cross-icon-wrap text-decoration-none'>
                                        <i class="bi bi-x"></i>
                                    </a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <th scope="row">3</th>
                            <td>
                                <div className='d-flex align-items-center gap-1'>
                                    <img src={GrayCarIcon} alt='GrayLocalTourIcon' />
                                    Car Rentals
                                </div>
                            </td>
                            <td>
                                <div className='table-col-name-td'>
                                    Sahil Kumar
                                </div>
                            </td>
                            <td>
                                <div className='add-review-disable'>
                                    + Add Reviews
                                </div>
                            </td>
                            <td>07 Nov 2024</td>
                            <td>
                                <div className='table-amount-col-data'>
                                    $399.05
                                </div>
                            </td>
                            <td>
                                <div className='status-label'>
                                    <span className='confirmed-label'>Confirmed</span>
                                </div>
                            </td>
                            <td>
                                <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                    <a href='#' className='eye-icon-wrap text-decoration-none'>
                                        <i class="bi bi-eye"></i>
                                    </a>
                                    <a href='#' className='download-icon-wrap text-decoration-none'>
                                        <i class="bi bi-download"></i>
                                    </a>
                                    <a href='#' className='cross-icon-wrap text-decoration-none'>
                                        <i class="bi bi-x"></i>
                                    </a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <th scope="row">4</th>
                            <td>
                                <div className='d-flex align-items-center gap-1'>
                                    <img src={GreyHotelIcon} alt='GrayLocalTourIcon' />
                                    Hotel
                                </div>
                            </td>
                            <td>
                                <div className='table-col-name-td'>
                                    Vikram
                                </div>
                            </td>
                            <td>
                                <div className='add-review-disable'>
                                    + Add Reviews
                                </div>
                            </td>
                            <td>02 Nov 2024</td>
                            <td>
                                <div className='table-amount-col-data'>
                                    $269.80
                                </div>
                            </td>
                            <td>
                                <div className='status-label'>
                                    <span className='confirmed-label'>Confirmed</span>
                                </div>
                            </td>
                            <td>
                                <div className='action-icon-wrap d-flex align-items-center gap-2'>
                                    <a href='#' className='eye-icon-wrap text-decoration-none'>
                                        <i class="bi bi-eye"></i>
                                    </a>
                                    <a href='#' className='download-icon-wrap text-decoration-none'>
                                        <i class="bi bi-download"></i>
                                    </a>
                                    <a href='#' className='cross-icon-wrap text-decoration-none'>
                                        <i class="bi bi-x"></i>
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {/* ====End=== */}

            <p className='p-md mb-0 mt-5 text-center text-grey-light'>Copyright©2024 Arab Alkhalej   |   All right reserved </p>
        </div>
    )
}

export default MyBooking;