import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { start_loading, stop_loading } from '../reduxStore/loader/loaderActions';
import axios from 'axios';
import { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import PhoneNumber from '../shared/PhoneNumber';

const HolderInfo = ({ show, handleClose, ISAdd }) => {
    const holderDetail = JSON.parse(localStorage.getItem("holderDetail"));
    const [holderData, setHolderData] = useState({
        Name: '',
        Surname: '',
        Gender: '',
        Age: '',
        Email: '',
        phone_number: '',
        DocumentType: '',
        DocumentNo: '',
        Address: '',
        City: '',
        PostalCode: '',
        Country: '',
        Nationality: ''
    });
    const [errors, setErrors] = useState({
        Name: '',
        Surname: '',
        Gender: '',
        Age: '',
        Email: '',
        phone_number: '',
        DocumentType: '',
        DocumentNo: '',
        Address: '',
        City: '',
        PostalCode: '',
        Country: '',
        Nationality: ''
    });
    const exptest = /^(?!.*\.\.)(?!.*-@)(?!.*#)([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})$/i;
    const [country, setCountry] = useState('US');
    let countrycode = getCountryCallingCode(country);
    let checkval = `+${countrycode}` + holderData?.phone_number;
    const dispatch = useDispatch();
    const [lists, setLists] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            dispatch(start_loading());
            try {
                const data = await axios.get('https://restcountries.com/v3.1/all');
                if (!data) return;

                const formatedData = data?.data?.map((item) => ({
                    nationality: item?.cca2,
                    flag: item?.flag,
                    countryname: item?.name?.common
                }));
                setLists(formatedData);
            } catch (error) {
                console.log("Error in Nation Lists", error);
            } finally {
                dispatch(stop_loading());
            }
        };
        fetchData();
    }, [show]);

    const handleChange = (value, label) => {
        const validations = {
            Name: value === "" ? "First name is required" : null,
            Surname: value === "" ? "Last name is required" : null,
            Gender: value === "" ? "Gender is required" : null,
            Age: value === "" ? "Age is required" : Number(value) < 18 ? "Age must be greater than 18" : null,
            Email: value === "" ? "Email is required" : !exptest.test(value) ? "Invalid email" : null,
            phone_number: value === "" ? "Phone number is required" : !isValidPhoneNumber(checkval) ? "Invalid Phone number" : null,
            DocumentType: value === "" ? "Document type is required" : null,
            DocumentNo: value === "" ? "Document number is required" : null,
            Address: value === "" ? "Address is required" : null,
            City: value === "" ? "City is required" : null,
            PostalCode: value === "" ? "Postal code is required" : null,
            Country: value === "" ? "Country is required" : null,
            Nationality: value === "" ? "Nationality is required" : null,
        };

        setHolderData((prev) => ({ ...prev, [label]: value }));
        setErrors((prev) => ({ ...prev, [label]: validations[label] || null }));
    };

    const handleRename = (obj, oldkey, newkey) => {
        if (obj.hasOwnProperty(oldkey)) {
            obj[newkey] = obj[oldkey];
            delete obj[oldkey];
        }
        return obj;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const validations = {
            Name: holderData?.Name === "" ? "First name is required" : null,
            Surname: holderData?.Surname === "" ? "Last name is required" : null,
            Gender: holderData?.Gender === "" ? "Gender is required" : null,
            Age: holderData?.Age === "" ? "Age is required" : Number(holderData?.Age) < 18 ? "Age must be greater than 18" : null,
            Email: holderData?.Email === "" ? "Email is required" : !exptest.test(holderData?.Email) ? "Invalid email" : null,
            phone_number: holderData?.phone_number === "" ? "Phone number is required" : isValidPhoneNumber(checkval) ? "Invalid Phone number" : null,
            DocumentType: holderData?.DocumentType === "" ? "Document type is required" : null,
            DocumentNo: holderData?.DocumentNo === "" ? "Document number is required" : null,
            Address: holderData?.Address === "" ? "Address is required" : null,
            City: holderData?.City === "" ? "City is required" : null,
            PostalCode: holderData?.PostalCode === "" ? "Postal code is required" : null,
            Country: holderData?.Country === "" ? "Country is required" : null,
            Nationality: holderData?.Nationality === "" ? "Nationality is required" : null,
        };
        setErrors(validations);

        const filterErr = Object.values(validations).filter((item) => item !== null);
        if (filterErr?.length !== 0) return;

        const data = handleRename(holderData, "phone_number", "PhoneNumber");

        localStorage.setItem("holderDetail", JSON.stringify(data));
        ISAdd();
        //console.log(data);
        handleClose();
    };

   // console.log(holderDetail);


    useEffect(() => {
        if (holderDetail === null) return;

        setHolderData(prev => ({
            ...prev,
            Name: holderDetail?.Name,
            Surname: holderDetail?.Surname,
            Gender: holderDetail?.Gender,
            Age: holderDetail?.Age,
            Email: holderDetail?.Email,
            phone_number: holderDetail?.PhoneNumber,
            DocumentType: holderDetail?.DocumentType,
            DocumentNo: holderDetail?.DocumentNo,
            Address: holderDetail?.Address,
            City: holderDetail?.City,
            PostalCode: holderDetail?.PostalCode,
            Country: holderDetail?.Country,
            Nationality: holderDetail?.Nationality
        }));
    }, []);

    return (
        <Modal show={show}>
            {/* <Modal.Header>
                <Modal.Title>
                    <div class="modal-header border-0 cross-icon p-0 align-items-center">
                        <div className='container-fluid travel-detail-header d-flex align-items-center'>
                            <h4 class="modal-title mb-0 lh-1" id="slidermodalLabel">Add Person 1 Details</h4>
                            <button type="button" class="btn-close" onClick={handleClose}></button>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                <div class="modal-content">
                    <div class="modal-header border-0 cross-icon p-0 align-items-center">
                        <h2>Add Person 1 Details</h2>
                        <button type="button" class="btn-close" onClick={handleClose}></button>
                    </div>
                    <div class="modal-body border-0 modal-slider-view p-0">
                        <form className='left-fill-details' onSubmit={(e) => handleSubmit(e)}>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'>First Name</label>
                                            <input type="text" className='form-control' placeholder='Enter your first name' value={holderData?.Name} onChange={(e) => handleChange(e.target.value, 'Name')} />
                                            {errors.Name && <p className='text-danger'>{errors.Name}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'>Last Name</label>
                                            <input type="text" className='form-control' placeholder='Enter your first name' value={holderData?.Surname} onChange={(e) => handleChange(e.target.value, 'Surname')} />
                                            {errors.Surname && <p className='text-danger'>{errors.Surname}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'>Gender</label>
                                            <select className='text-grey-light w-100 form-select form-control' value={holderData?.Gender} onChange={(e) => handleChange(e.target.value, 'Gender')}>
                                                <option value="" disabled>Select Gender</option>
                                                <option value="Female">Female</option>
                                                <option value="Male">Male</option>
                                            </select>
                                            {errors.Gender && <p className='text-danger'>{errors.Gender}</p>}
                                        </div>
                                    </div>

                                    <div className='col-md-12'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'>Age</label>
                                            <input type="text" className='form-control' placeholder='Age' value={holderData?.Age} onChange={(e) => handleChange(e.target.value, 'Age')} />
                                            {errors.Age && <p className='text-danger'>{errors.Age}</p>}
                                            {/* <select className='text-grey-light w-100 form-select form-control' value={holderData?.Age} onChange={(e) => handleChange(e.target.value, 'Age')}>
                                        <option selected>Select age</option>
                                        <option>Female</option>
                                        <option>Male</option>
                                    </select> */}
                                        </div>
                                    </div>


                                    {/* <div className='col-md-4'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>Phone Number</label>
                                    <select className='text-grey-light w-100 form-select form-control'>
                                        <option selected>+91</option>
                                        <option>Female</option>
                                        <option>Male</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'></label>
                                    <input type="number" className='form-control' placeholder='Enter your phone number' />
                                </div>
                            </div> */}

                                    <div className='col-md-12'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'>Phone Number</label>
                                            <PhoneNumber
                                                country={country}
                                                setCountry={(val) => setCountry(val)}
                                                phoneNumber={holderData.phone_number}
                                                handleChange={(val) => handleChange(val, 'phone_number')}
                                            />
                                            {errors.phone_number && <p className='text-danger'>{errors.phone_number}</p>}
                                        </div>
                                    </div>

                                    <div className='col-md-12'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'>Email</label>
                                            <input type="email" className='form-control' placeholder='Enter your email' value={holderData?.Email} onChange={(e) => handleChange(e.target.value, 'Email')} />
                                            {errors?.Email && <p className='text-danger'>{errors?.Email}</p>}
                                        </div>
                                    </div>

                                    <div className='col-md-4'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'>Document</label>
                                            <select className='text-grey-light w-100 form-select form-control' value={holderData?.DocumentType} onChange={(e) => handleChange(e.target.value, 'DocumentType')}>
                                                <option value="" disabled>Select</option>
                                                <option value="Passport">Passport</option>
                                                <option value="Driving license">Driving license</option>
                                            </select>
                                            {errors?.DocumentType && <p className='text-danger'>{errors?.DocumentType}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-8'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'></label>
                                            <input type="number" className='form-control' placeholder='Document number' value={holderData?.DocumentNo} onChange={(e) => handleChange(e.target.value, 'DocumentNo')} />
                                            {errors?.DocumentNo && <p className='text-danger'>{errors?.DocumentNo}</p>}
                                        </div>
                                    </div>

                                    <div className='col-md-12'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'>Address</label>
                                            <input type="text" className='form-control' placeholder='House/street no' value={holderData?.Address} onChange={(e) => handleChange(e.target.value, 'Address')} />
                                            {errors?.Address && <p className='text-danger'>{errors?.Address}</p>}
                                        </div>
                                    </div>

                                    <div className='col-md-12'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'>City</label>
                                            <input type="text" className='form-control' placeholder='Enter your city' value={holderData?.City} onChange={(e) => handleChange(e.target.value, 'City')} />
                                            {errors?.City && <p className='text-danger'>{errors?.City}</p>}
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'>Country</label>
                                            <input type="text" className='form-control' placeholder='Enter your country' value={holderData?.Country} onChange={(e) => handleChange(e.target.value, 'Country')} />
                                            {errors?.Country && <p className='text-danger'>{errors?.Country}</p>}
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'>Postal Code</label>
                                            <input type="number" className='form-control' placeholder='Enter code' value={holderData?.PostalCode} onChange={(e) => handleChange(e.target.value, 'PostalCode')} />
                                            {errors?.PostalCode && <p className='text-danger'>{errors?.PostalCode}</p>}
                                        </div>
                                    </div>

                                    <div className='col-md-12'>
                                        <div className='form-group form-spacing'>
                                            <label className='form-label p-lg text-grey-light'>Nationality</label>
                                            <select className='text-grey-light w-100 form-select form-control' value={holderData?.Nationality} onChange={(e) => handleChange(e.target.value, 'Nationality')}>
                                                <option value="" disabled>Select Nationality</option>
                                                {lists.map((item, index) => (
                                                    <option key={index} value={item?.nationality}>
                                                        {item?.countryname} ({item?.nationality})
                                                    </option>
                                                ))}
                                            </select>
                                            {errors?.Nationality && <p className='text-danger'>{errors?.Nationality}</p>}
                                            {/* <input type="text" className='form-control' placeholder='Enter your nationality' /> */}
                                        </div>
                                    </div>


                                    {/* <div className='col-md-12'>
                                <div className='form-group form-spacing'>
                                    <label className='form-label p-lg text-grey-light'>Date of  Birth</label>
                                    <div className='d-grid d-grid-calender w-100'>
                                        <select className='text-grey-light w-100 form-select form-control'>
                                            <option selected>DD</option>
                                            <option>DD</option>
                                            <option>DD</option>
                                        </select>
                                        <select className='text-grey-light w-100 form-select form-control'>
                                            <option selected>MM</option>
                                            <option>MM</option>
                                            <option>MM</option>
                                        </select>
                                        <select className='text-grey-light w-100 form-select form-control'>
                                            <option selected>YYYY</option>
                                            <option>YYYY</option>
                                            <option>YYYY</option>
                                        </select>
                                    </div>
                                </div>
                            </div> */}
                                </div>
                                <button className='btn-gradient-purple' type='submit'>Add Person</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default HolderInfo