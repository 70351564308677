
const Amenities = [
    { Description: "Air conditioning", ImagePath: require('../assets/air-condition-btm.png')},
    { Description: "Television", ImagePath: require('../assets/technology.png') },
    { Description: "Daily housekeeping", ImagePath: null },
    { Description: "Phone", ImagePath: null },
    { Description: "Free local calls", ImagePath: null },
    { Description: "Private bathroom", ImagePath: null },
    { Description: "Hair dryer", ImagePath: null },
    { Description: "Desk", ImagePath: null },
    { Description: "Premium bedding", ImagePath: null },
    { Description: "Iron/ironing board (on request)", ImagePath: require('../assets/laundry.png') },
    { Description: "Shower only", ImagePath: null },
    { Description: "Hair dryer (on request)", ImagePath: null },
    { Description: "Digital TV service", ImagePath: null },
    { Description: "Free WiFi", ImagePath: require('../assets/wifi.png') },
    { Description: "Down comforter", ImagePath: null },
    { Description: "Egyptian-cotton sheets", ImagePath: null },
    { Description: "Pillowtop mattress", ImagePath: null },
    { Description: "Rainfall showerhead", ImagePath: null },
    { Description: "Hydromassage showerhead", ImagePath: null },
    { Description: "LED TV", ImagePath: null },
    { Description: "Non-Smoking", ImagePath: require('../assets/smoke.png') },
    { Description: "Heating", ImagePath: null },
    { Description: "Towels provided", ImagePath: null },
    { Description: "Linens provided", ImagePath: null },
    { Description: "Free WiFi (limited) device count2", ImagePath: require('../assets/wifibtm.png') },
    { Description: "Netflix", ImagePath: require('../assets/netflix.png') },
    { Description: "Wheelchair accessible", ImagePath: require('../assets/wheelchair.png') },
    { Description: "Refrigerator", ImagePath: require('../assets/refrigerator.png') },
    { Description: "Coffee/tea maker", ImagePath: require('../assets/moka-pot.png') },
    { Description: "Smart TV", ImagePath: require('../assets/smart-tv.png') },
    { Description: "Minibar", ImagePath: require('../assets/minibar.png') }
  ];

export default Amenities;