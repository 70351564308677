import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { start_loading, stop_loading } from '../reduxStore/loader/loaderActions';

const SelectNation = ({ show, handleClose, setNation }) => {
    const [lists, setLists] = useState([]);
    const [nationData, setNationData] = useState([]);
    const [searchVal, setSearchVal] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            dispatch(start_loading());
            try {
                const data = await axios.get('https://restcountries.com/v3.1/all');
                if (!data) return;

                const formatedData = data?.data?.map((item) => ({
                    nationality: item?.cca2,
                    flag: item?.flag,
                    countryname: item?.name?.common
                }));
                setLists(formatedData);
                setNationData(formatedData);
            } catch (error) {
                console.log("Error in Nation Lists", error);
            } finally {
                dispatch(stop_loading());
            }
        };
        fetchData();
    }, [show]);

    const handleChange = async (value) => {
        setSearchVal(value);
        const data = await axios.get('https://restcountries.com/v3.1/all');
        const filterdata = data?.data?.filter((item) => item?.name?.common.toLowerCase().includes(value.toLowerCase()));
        const formatedData = filterdata?.map((item) => ({
            nationality: item?.cca2,
            flag: item?.flag,
            countryname: item?.name?.common
        }));
        setLists(formatedData);

        // if (value === '') {
        //     setLists(nationData);
        // } else {
        //     const filterlist = lists?.filter((item) => item?.countryname.toLowerCase().includes(value.toLowerCase()));
        //     setLists(filterlist);
        // }
    };

    const handleSelect = (data) => {
        if (!data) return;

        setNation(data?.nationality);
        setSearchVal('');
        handleClose();
    };

    return (
        <Modal className='select-nationality-popup-wrap' show={show} isCentered>
            <Modal.Body className="p-4">
                <div class="modal-content">
                    <div class="modal-header border-0 cross-icon p-0 align-items-center">
                        <h4>Select your Nationality</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                    </div>
                </div>
                <div className="destination-box first-box item-one">
                    <input
                        type="text"
                        className="form-control"
                        value={searchVal}
                        placeholder="Search Country"
                        onChange={(e) => { handleChange(e.target.value); }}
                    />

                    {lists?.length > 0 && (
                        <ul
                            className="dropdown-options select-nationality-option"
                            style={{
                                position: "absolute",
                                top: "100%",
                                left: 0,
                                width: "100%",
                                maxHeight: "250px",
                                overflowY: "auto",
                                backgroundColor: "#fff",
                                border: "1px solid #ccc",
                                listStyle: "none",
                                margin: 0,
                                padding: "5px 0",
                                zIndex: 1,
                                color: "white"
                            }}
                        >
                            {lists?.map((option, index) => (
                                <li
                                    key={index}
                                    onClick={() => handleSelect(option)}
                                    style={{
                                        padding: "10px",
                                        cursor: "pointer",
                                        backgroundColor: "#fff",
                                        color: "#333333",
                                    }}
                                    onMouseEnter={(e) => (e.target.style.backgroundColor = "#fff")}
                                    onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
                                >
                                    {option?.flag}  {option?.countryname}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SelectNation