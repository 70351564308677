import { GET_CITIES_LISTS, GET_HOTEL_DETAILS, GET_HOTELS_LISTS, GET_SEARCHED_GRAPH, GET_SEARCHED_DATA, GET_TOP_HOTELS, GET_MODIFIED_DATA } from "./bookingTypes";


const initialState = {
    tophotels: [],
    searchedhotels: [],
    cities: [],
    hotelDetail: null,
    graphData: [],
    searchedData: null,
    modifyData: null
};

export const bookingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TOP_HOTELS:
            return {
                ...state,
                tophotels: action.payload,
            };
        case GET_HOTELS_LISTS:
            return {
                ...state,
                searchedhotels: action.payload,
            };
        case GET_CITIES_LISTS:
            return {
                ...state,
                cities: action.payload,
            };
        case GET_HOTEL_DETAILS:
            return {
                ...state,
                hotelDetail: action.payload,
            };
        case GET_SEARCHED_GRAPH:
            return {
                ...state,
                graphData: action.payload,
            };
        case GET_SEARCHED_DATA:
            return {
                ...state,
                searchedData: action.payload,
            };
        case GET_MODIFIED_DATA:
            return {
                ...state,
                modifyData: action.payload,
            };

        default:
            return state;
    }
};