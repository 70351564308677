import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { get_vender_id, handle_user_social_login, login_user } from '../reduxStore/User/userActions';
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import OTALogin from '../shared/OTALogin';
import GoogleImg from '../assets/GoogleIcon.png';
import FacebookImg from '../assets/communication.png';
import OTPImg from '../assets/otp.png';
import AppleImg from '../assets/apple-logo.png';
import GoogleIcon from '../assets/google-icon.svg';
import FbIcon from '../assets/fb-icon.svg';
import AppleIcon from '../assets/apple-icon.svg';
import { getRedirectResult, onAuthStateChanged, signInWithPopup, signInWithRedirect } from '@firebase/auth';
import { appleProvider, auth, facebookProvider, googleProvider } from '../firebase/firebase';
import OTPLogin from './OTPLogin';

const Login = ({ user, vendor_id, isMobile }) => {
  const [formdata, setFormdata] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });
  const exptest = /^(?!.*\.\.)(?!.*-@)(?!.*#)([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})$/i;
  const [isShow, setIsShow] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const handleChange = (label, value) => {
    switch (label) {
      case "email":
        setFormdata((prev) => ({ ...prev, [label]: value }));
        setErrors((prev) => ({ ...prev, [label]: value === "" ? "Email is required" : !exptest.test(value) ? "Invalid email" : null }));
        break;
      case "password":
        setFormdata((prev) => ({ ...prev, [label]: value }));
        setErrors((prev) => ({ ...prev, [label]: value === "" ? "Password is required" : value.length <= 5 ? "Password should contain more than 5characters" : null }));
        break;

      default:
        setFormdata((prev) => ({ ...prev, [label]: value }));
        break;
    }
  };

  const handleValid = () => {
    let err = false;

    const Errors = {
      email: formdata.email === "" ? "Email is required" : !exptest.test(formdata.email) ? "Invalid email" : null,
      password: formdata.password === "" ? "Password is required" : formdata.password.length <= 5 ? "Password should contain more than 5 characters" : null,
    };

    let newErrors = Object.entries(Errors);
    newErrors.forEach(([key, value]) => {
      if (value) {
        err = true;
        setErrors((prev) => ({ ...prev, [key]: value }));
      }
    });

    return err;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (handleValid()) return;

    const userdata = { username: formdata.email.toLocaleLowerCase(), password: formdata.password, type: 'user' };
    // console.log("formdata submit --->", formdata);
    await login_user(userdata, dispatch, toast, navigate, vendor_id);
  };

  useEffect(() => {
    get_vender_id("superadmin", dispatch);
  }, []);

  const handleLogin = async (label) => {
    try {
      switch (label) {
        case 'google':
          let result = await signInWithPopup(auth, googleProvider);
          //console.log("Google result ====>", result);
          const userdata = {
            google_id: result?.user?.providerData[0]?.uid,
            name: result?.user?.providerData[0]?.displayName,
            email: result?.user?.providerData[0]?.email,
          };
          await handle_user_social_login(vendor_id, userdata, toast, navigate, dispatch);
          break;

        case 'facebook':
          let result2 = await signInWithPopup(auth, facebookProvider);
          //console.log("Facebook result ====>", result2);
          const userdata2 = {
            facebook_id: result2?.user?.providerData[0]?.uid,
            name: result2?.user?.providerData[0]?.displayName,
            email: result2?.user?.providerData[0]?.email,
          };
          await handle_user_social_login(vendor_id, userdata2, toast, navigate, dispatch);
          break;

        case 'apple':
          let result3 = await signInWithPopup(auth, appleProvider);
          console.log("Apple result ====>", result3);
          const userdata3 = {
            apple_id: result3?.user?.providerData[0]?.uid,
            name: result3?.user?.providerData[0]?.displayName,
            email: result3?.user?.providerData[0]?.email,
          };
          await handle_user_social_login(vendor_id, userdata3, toast, navigate, dispatch);
          break;
        default:
          break;
      }

      // let result = await signInWithPopup(auth, googleProvider);
      // //console.log("Google result ====>", result);
      // const userdata = {
      //   google_id: result?.user?.providerData[0]?.uid,
      //   name: result?.user?.providerData[0]?.displayName,
      //   email: result?.user?.providerData[0]?.email,
      // };
      // await handle_user_social_login(vendor_id, userdata, toast, navigate, dispatch);
    } catch (error) {
      console.log("Social Login Error ====>", error);
    }
  };

  // useEffect(() => {
  //   const fetchResult = async () => {
  //     try {
  //       const result = await getRedirectResult(auth);
  //       console.log("google ----->", result);
  //     } catch (error) {
  //       console.log("Error in reddirect ---->", error);
  //     }
  //   };

  //   // fetchResult();
  //   window.addEventListener("load", fetchResult);
  // }, []);

  // console.log(isMobile);

  // const handleRedirectResult = async () => {
  //   try {
  //     let result = await getRedirectResult(auth);
  //     if (result) {
  //       console.log("Mobile Data ------>", result);
  //     } else {
  //       console.log("No Result Found ----->");
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // };

  // useEffect(() => {
  //   handleRedirectResult();
  // }, []);

  // useEffect(() => {
  //   console.log("rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
  //   const unsubscribe = onAuthStateChanged(auth, async (user) => {
  //     if (user) {
  //       console.log("User logged in after redirect:", user);
  //       const userdata = {
  //         google_id: user?.providerData[0]?.uid,
  //         name: user?.providerData[0]?.displayName,
  //         email: user?.providerData[0]?.email,
  //       };

  //       await handle_user_social_login(vendor_id, userdata, toast, navigate, dispatch);
  //     }
  //   });
  //   return () => unsubscribe();
  // }, []);

  return (
    <div className=''>
      <div className='container'>
        <div className='row justify-content-end'>
          <div className='col-xl-5 col-lg-6 col-md-8'>
            <div className='login-form-wrap'>
              <h1 className='text-center text-white mb-0'>Welcome to Login</h1>

              <p className='text-center text-white h5 mt-3 or-text fw-normal'>Sign in with</p>

              <div class="d-flex justify-content-center align-items-center gap-5 my-4 social-login-icons-wrap">
                <div className="d-inline-block text-white h4 mb-0 cursor-pointer" onClick={() => handleLogin('google')}>
                  <img src={GoogleIcon} alt='GoogleIcon' className='' />
                </div>
                <div className="d-inline-block text-white h4 mb-0 cursor-pointer" onClick={() => handleLogin('facebook')}>
                  <img src={FbIcon} alt='GoogleIcon' className='' />
                </div>
                <div className="d-inline-block text-white h4 mb-0 cursor-pointer" onClick={() => handleLogin('apple')}>
                  <i class="bi bi-apple"></i>
                </div>
                {/* <Link className="d-inline-block" to="/user-verify">
                  <img src={OTPImg} alt='img not found' style={{ height: '30px', width: '40px' }} />
                </Link> */}
              </div>

              <p className='text-center text-white h5 mb-3 or-text'>Or</p>

              <OTALogin
                formdata={formdata}
                errors={errors}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                isShow={isShow}
                setIsShow={() => setIsShow(!isShow)}
              />

              {/* <div class="d-flex flex-column justify-content-start gap-2">
                <span className='text-white'>Login with OTP</span>
                <Link className="nav-link white-border-btn text-white w-100" href="javascript:void(0)" to="/user-verify">
                  <img src="/images/Download.png" class="me-2 d-inline-block"></img>
                  Mobile Number
                </Link>
              </div> */}

              <OTPLogin />

            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    vendor_id: state.auth.vendor_id
  }
}
export default connect(mapStateToProps)(Login);